import moment from 'moment';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isValidDate(dateString: string, format: string) {
  const date = moment(dateString, format, true);

  if (!date.isValid()) {
    return false;
  }

  return date.isSameOrAfter(moment().subtract(150, 'years'));
}
