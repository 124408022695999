import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import type { FinancialResponsibilityFormProps } from './props';
import type { FinancialResponsibilityChoice, Rider, Payer } from '../../../../generated/graphql';
import { FinancialResponsibility } from './FinancialResponsibility';
import { useStyles } from '../../rideFormStyles';
import moment from '@onwardcare/common/lib/moment-timezone';

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const FinancialResponsibilityForm: React.FC<FinancialResponsibilityFormProps> = (
  { 
    control,
    errors,
    account,
    profile,
    getAccountFinancialResponsibility,
    watchTransportType,
    accountFinancialResponsibilityChoices,
    errorPayerType,
    errorCostCenter,
    singleCostCenterStyle,
    setValue,
    promptForPayerDemographics,
    ridersList,
    defaultRider,
    defaultRide,
    watchRide,
    riderID,
  }) => {
  type RiderOrPayer = Rider | Payer;

  const classes = useStyles();

  const handleSelectPayerType = (event: any, onChange: (...event: any[]) => void) => {
    onChange(event.target.value);

    getAccountFinancialResponsibility({
      variables: { accountId: account?.id, payerType: event.target.value as string },
    });
    setValue('ride.costCenter', null);
  }

  const getPayerInfo = (): RiderOrPayer | undefined => {
    const findRider = ridersList?.find(r => r.id === watchRide.riderId);

    return defaultRide?.payer || findRider || defaultRider || undefined;
  }

  const updatePayerInfo = (payerInfo: RiderOrPayer | undefined) => {
    setValue('ride.patient.firstName', payerInfo?.firstName);
    setValue('ride.patient.lastName', payerInfo?.lastName);
    setValue('ride.patient.phone', payerInfo?.phone);
    setValue('ride.patient.email', payerInfo?.email);
  }

  useEffect(() => {
    if (promptForPayerDemographics && watchRide.riderId) {
      const payerInfo = getPayerInfo();

      updatePayerInfo(payerInfo);
    } else {
      updatePayerInfo(undefined);
    }
  }, [
    defaultRide?.payer,
    setValue,
    defaultRider?.firstName,
    defaultRider?.lastName,
    watchRide.payerType,
    promptForPayerDemographics,
    defaultRider?.email,
    defaultRider?.phone,
    ridersList,
    watchRide.riderId
  ]);

  return (
    <>
      <FormControl
        className={classes.formControl}
        error={errorPayerType}
      >
        <InputLabel id="select-financial-responsibility">
          Financial responsibility
        </InputLabel>

        <Controller
          control={control}
          name="ride.payerType"
          rules={{ required: true }}
          render={({ onChange, ...props }) => (
            <Select
              labelId="financial-responsibility-side"
              id="payerType"
              value={props.value}
              onChange={(event) => handleSelectPayerType(event, onChange)}
            >
              {profile?.availablePayerTypes
                ?.filter(({ promptForPayerDemographics }) =>
                  !watchTransportType.match('ambulance') ||
                  !promptForPayerDemographics,
                ).map(({ name }) => (
                  <MenuItem key={name} value={name}>
                    {capitalizeFirstLetter(name)}
                  </MenuItem>
                ))}
            </Select>
          )}
        />

        {errors.ride?.payerType && (
          <FormHelperText error>
            Financial responsibility is required
          </FormHelperText>
        )}
      </FormControl>

      {(accountFinancialResponsibilityChoices &&
        accountFinancialResponsibilityChoices.length) ? (
          <FormControl
            className={classes.formControl}
            error={errorCostCenter}
            style={{ display: singleCostCenterStyle }}
          >
            <InputLabel id="select-cost-center">
              Cost center
            </InputLabel>

            <Controller
              control={control}
              name="ride.costCenter"
              rules={{ required: true }}
              render={({ onChange, ...props }) => (
                <Select
                  labelId="cost-center"
                  id="costCenter"
                  value={props.value}
                  onChange={onChange}
                >
                  {accountFinancialResponsibilityChoices
                    .filter((responsibility: FinancialResponsibilityChoice) =>
                      !watchTransportType.match('ambulance') ||
                      !responsibility.promptForPayerDemographics,
                    ).map((responsibility: FinancialResponsibilityChoice) => (
                      <MenuItem
                        key={responsibility.costCenter}
                        value={responsibility.costCenter || ''}
                      >
                        {responsibility.costCenter}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />

            {errors.ride?.costCenter && (
              <FormHelperText error>
                Cost center is required
              </FormHelperText>
            )}
          </FormControl>
        ) : null}
      {promptForPayerDemographics && (
        <FinancialResponsibility
          control={control}
          account={account}
          errors={errors}
          profile={profile}
        />
      )}
    </>
  );
};

export default FinancialResponsibilityForm;