import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import Map from 'components/Map';
import ZeroStateText from 'components/ZeroStateText';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  left: {
    padding: theme.spacing(2),
  },
  leftGrid: {
    overflow: 'scroll',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  form: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(3, 1, 3, 1),
    width: '95%',
  },
  container: {
    height: '100vh',
  },
}));

function RideDetailsForm({
  createError,
  onSubmit,
  account,
  ride,
  isSubmitting,
}) {
  const classes = useStyles();
  const history = useHistory();

  // default times should be in existing timezone if one exists
  const defaultValues = {
    notes: ride.notes,
    deliverySubstitution: ride.deliverySubstitution,
    paymentMethodId: ride.paymentMethod?.id,
  };

  const paymentMethodOptions = [
    ...account.paymentMethods,
    ...ride.rider.paymentMethods,
  ].map(pm => ({ id: pm.id, name: pm.name }));

  if (account.invoiced) {
    paymentMethodOptions.push({ id: 'invoice', name: 'Invoice this ride' });
  }

  const { handleSubmit, control, register } = useForm({
    defaultValues,
  });

  const submit = data => {
    onSubmit(data);
  };

  const warningMessage = "You can only edit notes for this ride. To make any other changes, please contact Onward at 1-800-700-4797.";

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      flexGrow={1}
    >
      <Grid container wrap="nowrap" className={classes.container}>
        <Grid
          item
          xs={12}
          sm={7}
          md={6}
          lg={4}
          key="createForm"
          className={classes.leftGrid}
        >
          <Paper className={classes.left}>
            <Button
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            <Typography component="h1" variant="h5" gutterBottom>
              Update Details
            </Typography>
            {!createError && (
              <ZeroStateText severity="info">{warningMessage}</ZeroStateText>
            )}
            {createError && (
              <Alert severity="error">
                There was an unexpected error updating your ride. Please contact
                Onward at 1-800-700-4797.
              </Alert>
            )}
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(submit)}
            >
              <TextField
                id="notes"
                InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                className={classes.formControl}
                name="notes"
                label={
                  <LabelWithTooltip
                    labelText={
                      ride.rideType === 'delivery'
                        ? 'Delivery Details'
                        : 'Ride Notes'
                    }
                    tooltipText={
                      ride.rideType === 'delivery'
                        ? 'Please describe the items you would like and any other details about your delivery.'
                        : 'Please leave any additional notes about this ride for your driver.'
                    }
                  />
                }
                multiline
                rows={4}
                inputRef={register()}
              />

              {ride.rideType === 'delivery' && (
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormControlLabel
                    label={
                      <LabelWithTooltip
                        labelText="Allow Substitutions"
                        tooltipText="After unsuccessfully trying to contact you should your driver make substitutions?"
                      />
                    }
                    control={
                      <Controller
                        name="deliverySubstitution"
                        control={control}
                        render={props => (
                          <Switch
                            onChange={e =>
                              props.onChange(e.target.checked ? 'yes' : 'no')
                            }
                            checked={props.value}
                          />
                        )}
                      />
                    }
                  />
                </FormControl>
              )}
              {paymentMethodOptions.length > 1 && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="select-payment-method-label">
                    Payment Method
                  </InputLabel>

                  <Controller
                    control={control}
                    name="paymentMethodId"
                    rules={{ required: true }}
                    as={
                      <Select
                        labelId="select-payment-method-label"
                        id="paymentMethodSelect"
                      >
                        {paymentMethodOptions.map(pm => (
                          <MenuItem key={pm.id} value={pm.id}>
                            {pm.name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </FormControl>
              )}

              <Button
                key="submitButton"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Update Details
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item key="map" xs={false} sm={5} md={6} lg={8}>
          <Map
            startLocation={ride.startLocation}
            endLocation={ride.endLocation}
            startTime={ride.requestedStartTime}
            endTime={ride.requestedEndTime}
            tzName={ride.tzName}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default RideDetailsForm;
