import React from 'react';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { personName } from '@onwardcare/common/lib/NameUtils';

const RiderSelect = ({ riders, onSelect }) => {
  const handleChange = (event, value) => {
    onSelect(value);
  };

  return (
    <Autocomplete
      id="rider-select"
      size="small"
      options={riders}
      getOptionLabel={rider => personName(rider)}
      style={{ width: 220 }}
      onChange={handleChange}
      renderInput={params => (
        <TextField {...params} label="Select Rider" variant="outlined" />
      )}
    />
  );
};

export default RiderSelect;
