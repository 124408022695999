import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {},
  section: {
    marginTop: '1em',
    justifyItems: 'center',
  },
  bufferTimeTextField: {
    width: '100%',
  },
  resultLabel: {
    alignSelf: 'center',
  },
  result: {
    fontWeight: 'bold',
    fontSize: '2em',
    textAlign: 'right',
  }
}));
