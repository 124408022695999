import { useEffect } from 'react';
import env from 'constants/Env';

export const useZendesk = profileId => {
  useEffect(() => {
    const userId = profileId;
    function fetchToken(callback_fetch = () => {}) {
      let options = {
        method: 'POST',
        headers: {},
      };
      if (userId !== '') {
        fetch(`${env.apiUrl}/api/custodians/${userId}/zendesk/tokens`, options)
          .then(response => response)
          .then(response => response.text())
          .then(text => {
            callback_fetch(text);
            zE('messenger:set', 'cookies', true);
          })
          .catch(e => console.log('ERROR', e));
      } else {
        return 'invalid userId';
      }
    }

    function loginUser() {
      zE('messenger', 'loginUser', function (callback) {
        fetchToken(function (token) {
          callback(token);
        });
      });
    }

    loginUser();
    return () => {
      // will hide the zendesk chat
      zE('messenger:set', 'cookies', false);
    };
  }, [profileId]);
};
