import { CreateRide } from 'pages/CreateRide';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import Profile from 'pages/Profile';
import { AccountSettings } from 'pages/AccountSettings';
import Magic from 'pages/Magic';
import RideDetails from 'pages/RideDetails';
import RidersPage from 'pages/RidersPage';
import { RiderProfile } from 'pages/RiderProfile';
import CreateRider from 'pages/CreateRider';
import { Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import RideHistory from 'pages/RideHistory';
import EditRide from 'pages/EditRide';
import EditRideDetails from 'pages/EditRideDetails';
import Welcome from 'pages/Welcome';
import Start from 'pages/Start';
import PublicOnlyRoute from './PublicOnlyRoute';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';
import history from './history';
import { useAuth } from '../contexts/auth-context';
import React from 'react';
import { useApolloClient } from '@apollo/client';
import Launcher from 'pages/Launcher';
import LaunchSession from 'pages/LaunchSession';
import LaunchFhir from 'pages/LaunchFhir';
import ChooseRider from 'pages/ChooseRider';
import { AccountPage } from '../pages/AccountPage';
import { SelectRider } from '../pages/SelectRider';
import { FeedbackPage } from '../pages/FeedbackPage';
import { StripeAddPage } from '../pages/StripeAddPage';

export default function RootRouter() {
  const auth = useAuth();
  const apolloClient = useApolloClient();

  //@ts-ignore
  !auth.isLoggedIn && zE('messenger:set', 'cookies', false);

  React.useEffect(() => {
    // using global events that dispatched from RN rider app
    // watch webview element inside the rider app
    const listener = (event: any) => {
      if (event?.detail?.eventName === 'QUIT') {
        auth.logout(apolloClient);
      }

      if (event?.detail?.eventName === 'REDIRECT') {
        const data = event?.detail?.data;
        history.push(`/rides/${data}?openReview=true`);
      }
    };

    const elem = document?.getElementById('root');

    if (elem) {
      elem.addEventListener('eventFromRN', listener);
    }

    return () => {
      if (elem) {
        elem.removeEventListener('eventFromRN', listener);
      }
    };
  });

  return (
    <Router history={history}>
      <Switch>
        <PublicOnlyRoute exact path="/login">
          <Login />
        </PublicOnlyRoute>
        <Route
          exact
          path="/driverApp/rideDetails"
          component={() => {
            const search = window.location.search;
            window.location.replace('onwarddriver://rideDetails' + search);
            return null;
          }}
        />
        <Route
          exact
          path="/driverApp/updatePassword"
          component={() => {
            window.location.replace('onwarddriver://updatePassword');
            return null;
          }}
        />
        <Route
          exact
          path="/driverApp/settings/payment"
          component={() => {
            window.location.replace('onwarddriver://settings/payment');
            return null;
          }}
        />
        <PublicOnlyRoute exact path="/welcome">
          <Welcome />
        </PublicOnlyRoute>
        <PublicOnlyRoute exact path="/start">
          <Start />
        </PublicOnlyRoute>
        <PublicOnlyRoute exact path="/resetPassword">
          <ForgotPassword />
        </PublicOnlyRoute>
        <Route exact path="/updatePassword">
          <UpdatePassword />
        </Route>
        <PrivateRoute exact path="/">
          <Dashboard />
        </PrivateRoute>
        <Route
          exact
          path="/rides/:slug/paymentMethods/new"
          component={StripeAddPage}
        />
        <PrivateRoute exact path="/rides/new">
          <CreateRide />
        </PrivateRoute>
        <PrivateRoute path="/rides/new/:riderId">
          <CreateRide />
        </PrivateRoute>
        <PrivateRoute path="/rides/:rideId/copy">
          <CreateRide />
        </PrivateRoute>
        <PrivateRoute path="/rides/:rideId/edit">
          <EditRide />
        </PrivateRoute>
        <PrivateRoute path="/rides/:rideId/editDetails">
          <EditRideDetails />
        </PrivateRoute>
        <PrivateRoute path="/rides/:rideId">
          <RideDetails />
        </PrivateRoute>
        <PrivateRoute exact path="/riders">
          <RidersPage />
        </PrivateRoute>
        <PrivateRoute path="/riders/new">
          <CreateRider />
        </PrivateRoute>
        <PrivateRoute path="/riders/select">
          <SelectRider />
        </PrivateRoute>
        <PrivateRoute path="/riders/:riderId">
          <RiderProfile />
        </PrivateRoute>
        <PrivateRoute path="/rideHistory">
          <RideHistory />
        </PrivateRoute>

        <PrivateRoute exact path="/profile">
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path="/account">
          <AccountSettings />
        </PrivateRoute>
        <PrivateRoute exact path="/magic">
          <Magic />
        </PrivateRoute>

        <Route exact path="/launch" component={Launcher} />
        <Route exact path="/launch/session" component={LaunchSession} />
        <PrivateRoute exact path="/launch/fhir">
          <LaunchFhir />
        </PrivateRoute>
        <PrivateRoute exact path="/launch/rider">
          <ChooseRider />
        </PrivateRoute>
        <Route exact path="/signup" component={AccountPage} />
        <Route exact path="/feedback/:slug" component={FeedbackPage} />
      </Switch>
    </Router>
  );
}
