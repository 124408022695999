import React from 'react';
import ENV from 'constants/Env';
import { personInitials, personName } from '@onwardcare/common/lib/NameUtils';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const PersonImage = ({ person, size = 'medium' }) => {
  const classes = useStyles();
  const fullName = personName(person);

  if (person.profilePhoto && person.profilePhoto.length > 0) {
    return (
      <Avatar
        className={classes[size]}
        alt={fullName}
        src={ENV.apiUrl + person.profilePhoto}
      />
    );
  } else {
    return (
      <Avatar className={classes[size]} alt={fullName}>
        {personInitials(person)}
      </Avatar>
    );
  }
};

export default PersonImage;
