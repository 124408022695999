import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  Upload: any;
};

export type Account = {
  allowTipping: Scalars['Boolean'];
  billingEmail?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  creditCents?: Maybe<Scalars['Int']>;
  firstAvailableBookingEnabled: Scalars['Boolean'];
  gridViewEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  invoiced: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  payerEmailRequired: Scalars['Boolean'];
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  riderCount?: Maybe<Scalars['Int']>;
};

export type AccountCancellationReason = {
  accountId: Scalars['String'];
  cancellationReason: Scalars['String'];
  displayOrder: Scalars['Int'];
  id: Scalars['ID'];
};

/** Autogenerated return type of AddCustodian */
export type AddCustodianPayload = {
  custodian?: Maybe<Custodian>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddCustodianRider */
export type AddCustodianRiderPayload = {
  error?: Maybe<Scalars['String']>;
  rider?: Maybe<Rider>;
};

/** Autogenerated return type of AddRiderProfilePhoto */
export type AddRiderProfilePhotoPayload = {
  error?: Maybe<Scalars['String']>;
  rider?: Maybe<Rider>;
};

/** Bedside nurse contact info for Ambulance rides */
export type BedsideNurse = {
  fullName: Scalars['String'];
  phone: Scalars['String'];
};

export type BestTimeBid = {
  bidStartTime: Scalars['ISO8601DateTime'];
  driver: Driver;
  id: Scalars['ID'];
  ride: Ride;
};

/** Autogenerated return type of CancelRide */
export type CancelRidePayload = {
  error?: Maybe<Scalars['String']>;
  ride?: Maybe<Ride>;
};

export type CarePartner = {
  displayName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  notifPrefs?: Maybe<Array<Scalars['String']>>;
  phone: Scalars['String'];
  relationshipNotes?: Maybe<Scalars['String']>;
  relationshipType: CarePartnerRelationship;
  twilioPhone?: Maybe<Scalars['String']>;
};

export enum CarePartnerRelationship {
  AdultChild = 'ADULT_CHILD',
  FacilityContact = 'FACILITY_CONTACT',
  Father = 'FATHER',
  Mother = 'MOTHER',
  Other = 'OTHER',
  Roommate = 'ROOMMATE',
  Sibling = 'SIBLING',
  Spouse = 'SPOUSE',
}

/** Autogenerated return type of ChangeDriverRide */
export type ChangeDriverRidePayload = {
  error?: Maybe<Scalars['String']>;
  ride?: Maybe<Ride>;
};

export type CollectionMetadata = {
  currentPage: Scalars['Int'];
  limitValue: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Bedside nurse contact info for Ambulance rides */
export type CreateBedsideNurseInput = {
  fullName: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateCarePartnerInput = {
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  notifPrefs?: Maybe<Array<Scalars['String']>>;
  phone: Scalars['String'];
  relationshipNotes?: Maybe<Scalars['String']>;
  relationshipType: CarePartnerRelationship;
};

export type CreateCustodianInput = {
  clinicalCredential?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateLocationInput = {
  address: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  zipcode?: Maybe<Scalars['String']>;
};

export type CreateMncsInput = {
  slug: Scalars['String'];
  value: Scalars['String'];
};

export type CreatePatientInput = {
  address?: Maybe<CreateLocationInput>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateRecurringInput = {
  dow?: Maybe<Array<Scalars['Int']>>;
  fromDate?: Maybe<Scalars['String']>;
  interval: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  repeat: Scalars['String'];
  untilDate?: Maybe<Scalars['ISO8601Date']>;
};

export type CreateRideInput = {
  appointmentTime?: Maybe<Scalars['ISO8601DateTime']>;
  arrivalBufferMinutes?: Maybe<Scalars['Int']>;
  contactPrecautionsRequired?: Maybe<Scalars['Boolean']>;
  costCenter?: Maybe<Scalars['String']>;
  deliverySubstitution?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  dropoffRoomNumber?: Maybe<Scalars['String']>;
  endLocation?: Maybe<CreateLocationInput>;
  firstAvailable?: Maybe<Scalars['Boolean']>;
  heightInches?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  oxygenRequired?: Maybe<Scalars['Boolean']>;
  partnerId?: Maybe<Scalars['String']>;
  passengerCount?: Maybe<Scalars['Int']>;
  patient?: Maybe<CreatePatientInput>;
  payerType?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  pickupRoomNumber?: Maybe<Scalars['String']>;
  requestedEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  requestedStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  riderId?: Maybe<Scalars['String']>;
  riderWillUseProvidersWheelchair?: Maybe<Scalars['Boolean']>;
  rideType: Scalars['String'];
  stairsCount?: Maybe<Scalars['Int']>;
  startLocation: CreateLocationInput;
  transportReasonComment?: Maybe<Scalars['String']>;
  transportReasonId?: Maybe<Scalars['ID']>;
  transportType?: Maybe<Scalars['String']>;
  vetrideRequestId?: Maybe<Scalars['Int']>;
  weightLbs?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateRide */
export type CreateRidePayload = {
  error?: Maybe<Scalars['String']>;
  ride?: Maybe<Ride>;
  rideErrors?: Maybe<Array<FieldError>>;
};

export type CreateRiderInput = {
  address?: Maybe<CreateLocationInput>;
  canMakeChanges?: Maybe<Scalars['Boolean']>;
  carePartner?: Maybe<CreateCarePartnerInput>;
  cognitiveImpairment?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender?: Maybe<PersonGender>;
  hearingImpairment?: Maybe<Scalars['String']>;
  heightInches?: Maybe<Scalars['Int']>;
  languageId?: Maybe<Scalars['ID']>;
  lastName: Scalars['String'];
  mobilityAssistance?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notifPrefs?: Maybe<Array<Scalars['String']>>;
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['Upload']>;
  visionImpairment?: Maybe<Scalars['String']>;
  weightLbs?: Maybe<Scalars['Int']>;
};

export type Custodian = {
  admin: Scalars['Boolean'];
  availablePayerTypes?: Maybe<Array<PayerOption>>;
  availableTransportReasons?: Maybe<Array<TransportReason>>;
  availableTransportTypes?: Maybe<Array<Transport>>;
  canAccessBi: Scalars['Boolean'];
  canInvite: Scalars['Boolean'];
  clinicalCredential?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastGeoLatitude?: Maybe<Scalars['Float']>;
  lastGeoLongitude?: Maybe<Scalars['Float']>;
  lastName?: Maybe<Scalars['String']>;
  notifPrefs: Array<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  promptForCancellationReason: Scalars['Boolean'];
  promptForFinancialResponsibility: Scalars['Boolean'];
  promptForRoomNumbers: Scalars['Boolean'];
  pushToken?: Maybe<Scalars['String']>;
};

export type CustodianCollection = {
  collection: Array<Custodian>;
  metadata: CollectionMetadata;
};

/** Autogenerated return type of DeclineBestTimeBids */
export type DeclineBestTimeBidsPayload = {
  error?: Maybe<Scalars['String']>;
  ride?: Maybe<Ride>;
};

export type Driver = {
  admin: Scalars['Boolean'];
  allowClaim: Scalars['Boolean'];
  availableTransportTypes?: Maybe<Array<Scalars['String']>>;
  bio?: Maybe<Scalars['String']>;
  currentVehicle?: Maybe<Vehicle>;
  email?: Maybe<Scalars['String']>;
  ethnicityStatus?: Maybe<PersonEthnicity>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  genderStatus?: Maybe<PersonGender>;
  geoId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalNotes?: Maybe<Scalars['String']>;
  isHispanic: Scalars['Boolean'];
  isPlaceholder: Scalars['Boolean'];
  languages?: Maybe<Array<UserLanguage>>;
  lastName?: Maybe<Scalars['String']>;
  mobilityAssistance?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  stripeDashboardUrl?: Maybe<Scalars['String']>;
  transportPartner?: Maybe<TransportPartner>;
  transportPartnerId?: Maybe<Scalars['String']>;
  twilioPhone?: Maybe<Scalars['String']>;
  tzName: Scalars['String'];
  veteranStatus?: Maybe<PersonVeteran>;
};

export type FieldError = {
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type FiltersInput = {
  field: Scalars['String'];
  value?: Maybe<Array<Scalars['String']>>;
};

export type FinancialResponsibilityChoice = {
  costCenter?: Maybe<Scalars['String']>;
  promptForPayerDemographics: Scalars['Boolean'];
};

export type Language = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
  sortOrder: Scalars['Int'];
};

export enum LanguageFluency {
  Conversational = 'CONVERSATIONAL',
  Fluent = 'FLUENT',
  NativeSpeaker = 'NATIVE_SPEAKER',
  Some = 'SOME',
}

export type Location = {
  address?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type Mncs = {
  mncsAnswers?: Maybe<Array<MncsAnswer>>;
};

export type MncsAnswer = {
  fieldType: MncsPromptField;
  group1?: Maybe<Scalars['String']>;
  group2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type MncsPrompt = {
  fieldType: MncsPromptField;
  group1?: Maybe<Scalars['String']>;
  group2: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  sortOrder?: Maybe<Scalars['Int']>;
};

export enum MncsPromptField {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  String = 'STRING',
}

/** The mutation root */
export type Mutation = {
  /** Create a custodian and associate it with the custodian's account */
  addCustodian?: Maybe<AddCustodianPayload>;
  /**
   * Create a rider and associate it with the custodian's account. If a rider for
   * an EHR already exists, the existing rider will be returned to avoid duplication.
   */
  addCustodianRider?: Maybe<AddCustodianRiderPayload>;
  /** Update a rider photo */
  addRiderPhoto?: Maybe<AddRiderProfilePhotoPayload>;
  /** Cancel a ride */
  cancelRide?: Maybe<CancelRidePayload>;
  /** Change a driver on a ride */
  changeDriverRide?: Maybe<ChangeDriverRidePayload>;
  /** Create a ride */
  createRide?: Maybe<CreateRidePayload>;
  /** Decline Best Time Bids for the given Ride */
  declineBestTimeBids?: Maybe<DeclineBestTimeBidsPayload>;
  /** Removes a payment method for an Account */
  removeAccountPaymentMethod?: Maybe<RemoveAccountPaymentMethodPayload>;
  /** Removes rider's Care Partner */
  removeCarePartner?: Maybe<RemoveCarePartnerPayload>;
  /** Removes a payment method from a Rider */
  removeRiderPaymentMethod?: Maybe<RemoveRiderPaymentMethodPayload>;
  /** Request custodian/rider to leave feedback for the given ride */
  requestRideFeedback?: Maybe<RequestRideFeedbackPayload>;
  /** Sends an email to the custodian with a reset link */
  resetCustodianPassword?: Maybe<ResetCustodianPasswordPayload>;
  /** Update a Ride with post ride details */
  reviewRide?: Maybe<ReviewRidePayload>;
  /** Update an Account */
  updateAccount?: Maybe<UpdateAccountPayload>;
  /** Update a payment method */
  updateAccountPaymentMethod?: Maybe<UpdateAccountPaymentMethodPayload>;
  /** Update a Custodian */
  updateCustodian?: Maybe<UpdateCustodianPayload>;
  /** Updates the favorite status of a driver */
  updateFavoriteDriver?: Maybe<UpdateFavoriteDriverPayload>;
  /** Update a ride */
  updateRide?: Maybe<UpdateRidePayload>;
  /** Update a Rider */
  updateRider?: Maybe<UpdateRiderPayload>;
  /** Update a rider's payment method */
  updateRiderPaymentMethod?: Maybe<UpdateRiderPaymentMethodPayload>;
};

/** The mutation root */
export type MutationAddCustodianArgs = {
  custodian: CreateCustodianInput;
};

/** The mutation root */
export type MutationAddCustodianRiderArgs = {
  ehrSessionId?: Maybe<Scalars['Int']>;
  rider: CreateRiderInput;
};

/** The mutation root */
export type MutationAddRiderPhotoArgs = {
  photo: Scalars['Upload'];
  riderId: Scalars['String'];
};

/** The mutation root */
export type MutationCancelRideArgs = {
  batchCancel?: Maybe<Scalars['Boolean']>;
  cancellationReasonId?: Maybe<Scalars['Int']>;
  rideId: Scalars['Int'];
};

/** The mutation root */
export type MutationChangeDriverRideArgs = {
  driverId: Scalars['String'];
  rideId: Scalars['String'];
};

/** The mutation root */
export type MutationCreateRideArgs = {
  bedsideNurse?: Maybe<CreateBedsideNurseInput>;
  isReturnTrip?: Maybe<Scalars['Boolean']>;
  mncsOptions?: Maybe<Array<CreateMncsInput>>;
  recurringOptions?: Maybe<CreateRecurringInput>;
  ride: CreateRideInput;
};

/** The mutation root */
export type MutationDeclineBestTimeBidsArgs = {
  rideId: Scalars['ID'];
};

/** The mutation root */
export type MutationRemoveAccountPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};

/** The mutation root */
export type MutationRemoveCarePartnerArgs = {
  riderId: Scalars['ID'];
};

/** The mutation root */
export type MutationRemoveRiderPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
  riderId: Scalars['String'];
};

/** The mutation root */
export type MutationRequestRideFeedbackArgs = {
  requestRideFeedback: RequestRideFeedbackInput;
};

/** The mutation root */
export type MutationResetCustodianPasswordArgs = {
  custodianId: Scalars['String'];
};

/** The mutation root */
export type MutationReviewRideArgs = {
  ride: ReviewRideInput;
  rideId: Scalars['String'];
};

/** The mutation root */
export type MutationUpdateAccountArgs = {
  account: UpdateAccountInput;
};

/** The mutation root */
export type MutationUpdateAccountPaymentMethodArgs = {
  paymentMethod: UpdatePaymentMethodInput;
  paymentMethodId: Scalars['String'];
};

/** The mutation root */
export type MutationUpdateCustodianArgs = {
  custodian: UpdateCustodianInput;
  custodianId?: Maybe<Scalars['String']>;
};

/** The mutation root */
export type MutationUpdateFavoriteDriverArgs = {
  driverId: Scalars['String'];
  favorite: Scalars['Boolean'];
  riderId: Scalars['String'];
};

/** The mutation root */
export type MutationUpdateRideArgs = {
  bedsideNurse?: Maybe<CreateBedsideNurseInput>;
  mncsOptions?: Maybe<Array<CreateMncsInput>>;
  ride: UpdateRideInput;
  rideId: Scalars['Int'];
};

/** The mutation root */
export type MutationUpdateRiderArgs = {
  rider: CreateRiderInput;
  riderId: Scalars['String'];
};

/** The mutation root */
export type MutationUpdateRiderPaymentMethodArgs = {
  paymentMethod: UpdatePaymentMethodInput;
  paymentMethodId: Scalars['String'];
  riderId: Scalars['String'];
};

export type NewRide = {
  arrivalBufferMinutes?: Maybe<Scalars['Int']>;
  endLocation?: Maybe<Location>;
  firstAvailable: Scalars['Boolean'];
  longDistancePayout: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  requestedStartTime: Scalars['ISO8601DateTime'];
  rideType: Scalars['String'];
  startLocation: Location;
  status: Scalars['String'];
  transportType?: Maybe<Scalars['String']>;
  tzName: Scalars['String'];
};

export type Patient = {
  address?: Maybe<Location>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type Payer = {
  address?: Maybe<Location>;
  currentPaymentMethod?: Maybe<PaymentMethod>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type PayerOption = {
  name: Scalars['String'];
  promptForPayerDemographics: Scalars['Boolean'];
};

export type PaymentMethod = {
  cardBrand?: Maybe<Scalars['String']>;
  cardLast4?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
};

export enum PersonEthnicity {
  Asian = 'ASIAN',
  Black = 'BLACK',
  Hawaiian = 'HAWAIIAN',
  Hispanic = 'HISPANIC',
  TwoOrMore = 'TWO_OR_MORE',
  Unspecified = 'UNSPECIFIED',
  White = 'WHITE',
}

export enum PersonGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
}

export enum PersonVeteran {
  NoAnswer = 'NO_ANSWER',
  NotApplicable = 'NOT_APPLICABLE',
  OtherProtected = 'OTHER_PROTECTED',
  RecentlySeparated = 'RECENTLY_SEPARATED',
  Special = 'SPECIAL',
  Vietnam = 'VIETNAM',
}

/** The query root */
export type Query = {
  account?: Maybe<Account>;
  accountCustodians?: Maybe<Array<Custodian>>;
  accountCustodiansCollection: CustodianCollection;
  accountFinancialResponsibilityChoices?: Maybe<
    Array<FinancialResponsibilityChoice>
  >;
  accountLocations?: Maybe<Array<Location>>;
  batchRides?: Maybe<Array<Ride>>;
  cancellationReasons?: Maybe<Array<AccountCancellationReason>>;
  currentRide?: Maybe<Ride>;
  custodians?: Maybe<Array<Custodian>>;
  driver?: Maybe<Driver>;
  driversByLocation?: Maybe<Array<Driver>>;
  favoriteDrivers?: Maybe<Array<Driver>>;
  featureFlags?: Maybe<Array<Scalars['String']>>;
  languages: Array<Language>;
  profile?: Maybe<Custodian>;
  recentDrivers?: Maybe<Array<Driver>>;
  recentLocations?: Maybe<Array<Location>>;
  ride?: Maybe<Ride>;
  rideEstimate?: Maybe<RideEstimate>;
  rideHistoryPaginated?: Maybe<RideHistory>;
  rideMncsPrompts?: Maybe<Array<MncsPrompt>>;
  rideNotesPlaceholderText?: Maybe<Scalars['String']>;
  rider?: Maybe<Rider>;
  riders?: Maybe<Array<Rider>>;
  ridersCollection: RiderCollection;
  ridersSimilar?: Maybe<Array<Rider>>;
  rides?: Maybe<Array<Ride>>;
  ridesCollection: RideCollection;
  ridesLastModifiedAt: Scalars['Int'];
  rideTransportTypeOptions: TransportTypeOptions;
  stripeKey?: Maybe<Scalars['String']>;
  stripeSetupIntent?: Maybe<StripeSetupIntentOutput>;
  upcomingRides?: Maybe<Array<Ride>>;
  vetrideRequest?: Maybe<VetrideRequest>;
};

/** The query root */
export type QueryAccountCustodiansCollectionArgs = {
  filters?: Maybe<Array<FiltersInput>>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryAccountFinancialResponsibilityChoicesArgs = {
  accountId?: Maybe<Scalars['String']>;
  payerType: Scalars['String'];
};

/** The query root */
export type QueryAccountLocationsArgs = {
  query?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryBatchRidesArgs = {
  rideId: Scalars['Int'];
};

/** The query root */
export type QueryCustodiansArgs = {
  query: Scalars['String'];
};

/** The query root */
export type QueryDriverArgs = {
  driverId: Scalars['String'];
};

/** The query root */
export type QueryDriversByLocationArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** The query root */
export type QueryFavoriteDriversArgs = {
  riderId?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryRecentDriversArgs = {
  riderId?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryRecentLocationsArgs = {
  query?: Maybe<Scalars['String']>;
  riderId?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryRideArgs = {
  rideId: Scalars['Int'];
};

/** The query root */
export type QueryRideEstimateArgs = {
  ride?: Maybe<CreateRideInput>;
};

/** The query root */
export type QueryRideHistoryPaginatedArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** The query root */
export type QueryRideNotesPlaceholderTextArgs = {
  transportType: Scalars['String'];
};

/** The query root */
export type QueryRiderArgs = {
  riderId?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryRidersArgs = {
  query?: Maybe<Scalars['String']>;
  riderId?: Maybe<Scalars['Int']>;
};

/** The query root */
export type QueryRidersCollectionArgs = {
  filters?: Maybe<Array<FiltersInput>>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryRidersSimilarArgs = {
  dob?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

/** The query root */
export type QueryRidesCollectionArgs = {
  filters?: Maybe<Array<FiltersInput>>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  scope?: Maybe<RidesCollectionScope>;
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
};

/** The query root */
export type QueryRidesLastModifiedAtArgs = {
  scope?: Maybe<RidesCollectionScope>;
};

/** The query root */
export type QueryRideTransportTypeOptionsArgs = {
  transportType: Scalars['String'];
};

/** The query root */
export type QueryStripeSetupIntentArgs = {
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
};

/** The query root */
export type QueryVetrideRequestArgs = {
  vetrideRequestId: Scalars['Int'];
};

/** Autogenerated return type of RemoveAccountPaymentMethod */
export type RemoveAccountPaymentMethodPayload = {
  account?: Maybe<Account>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RemoveCarePartner */
export type RemoveCarePartnerPayload = {
  error?: Maybe<Scalars['String']>;
  riderId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of RemoveRiderPaymentMethod */
export type RemoveRiderPaymentMethodPayload = {
  error?: Maybe<Scalars['String']>;
  rider?: Maybe<Rider>;
};

/** Attributes for requesting a ride feedback */
export type RequestRideFeedbackInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rideId: Scalars['String'];
  updateRiderProfile?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RequestRideFeedback */
export type RequestRideFeedbackPayload = {
  error?: Maybe<Scalars['String']>;
  ride?: Maybe<Ride>;
};

/** Autogenerated return type of ResetCustodianPassword */
export type ResetCustodianPasswordPayload = {
  error?: Maybe<Scalars['String']>;
};

export type ReviewOption = {
  name: Scalars['String'];
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type ReviewRideInput = {
  driverFeedback?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['Int']>;
  tipCents?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of ReviewRide */
export type ReviewRidePayload = {
  completedRideCount?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ride?: Maybe<Ride>;
};

export type Ride = {
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  actualCostCents?: Maybe<Scalars['Int']>;
  actualDistanceMiles?: Maybe<Scalars['Int']>;
  actualDurationMinutes?: Maybe<Scalars['Int']>;
  actualEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  actualPayoutCents?: Maybe<Scalars['Int']>;
  actualStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  appointmentTime?: Maybe<Scalars['ISO8601DateTime']>;
  arrivalBufferMinutes?: Maybe<Scalars['Int']>;
  bariatric?: Maybe<Scalars['Boolean']>;
  batchId?: Maybe<Scalars['Int']>;
  bedsideNurse?: Maybe<BedsideNurse>;
  bestTimeBid?: Maybe<BestTimeBid>;
  bestTimeBidsDeclineBefore?: Maybe<Scalars['ISO8601DateTime']>;
  bestTimeOptions?: Maybe<Array<Scalars['ISO8601DateTime']>>;
  biddingWindowEndsAt?: Maybe<Scalars['ISO8601DateTime']>;
  bidRequestedStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  cancellationReason?: Maybe<Scalars['String']>;
  contactPrecautionsRequired?: Maybe<Scalars['Boolean']>;
  costCenter?: Maybe<Scalars['String']>;
  creditAppliedCents?: Maybe<Scalars['Int']>;
  custodian?: Maybe<Custodian>;
  custodianDriverTwilioNumber?: Maybe<Scalars['String']>;
  custodianReviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryCents?: Maybe<Scalars['Int']>;
  deliveryFeeCents?: Maybe<Scalars['Int']>;
  deliverySubstitution?: Maybe<Scalars['String']>;
  driver?: Maybe<Driver>;
  driverReviewedAt?: Maybe<Scalars['ISO8601DateTime']>;
  driverReviewOptions?: Maybe<Array<ReviewOption>>;
  driverReviewUntil?: Maybe<Scalars['ISO8601DateTime']>;
  dropoffRoomNumber?: Maybe<Scalars['String']>;
  endLocation?: Maybe<Location>;
  enrouteAt?: Maybe<Scalars['ISO8601DateTime']>;
  estimatedCostCents?: Maybe<Scalars['Int']>;
  estimatedDistanceMiles?: Maybe<Scalars['Int']>;
  estimatedDurationMinutes?: Maybe<Scalars['Int']>;
  estimatedEndTime?: Maybe<Scalars['String']>;
  estimatedPayoutCents?: Maybe<Scalars['Int']>;
  firstAvailable: Scalars['Boolean'];
  heightInches?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  instantPayoutCents?: Maybe<Scalars['Int']>;
  longDistancePayout: Scalars['Boolean'];
  medicalNecessityCertificationStatement?: Maybe<Mncs>;
  notes?: Maybe<Scalars['String']>;
  originalRequestedStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  oxygenRequired?: Maybe<Scalars['Boolean']>;
  paidAt?: Maybe<Scalars['ISO8601DateTime']>;
  parkingCents?: Maybe<Scalars['Int']>;
  passengerCount?: Maybe<Scalars['Int']>;
  patient?: Maybe<Patient>;
  payer?: Maybe<Payer>;
  payerType?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  pickupRoomNumber?: Maybe<Scalars['String']>;
  receiptPhotos?: Maybe<Array<Scalars['String']>>;
  requestedEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  requestedStartTime: Scalars['ISO8601DateTime'];
  rideCharges?: Maybe<Array<RideCharge>>;
  rideCompletionStatus?: Maybe<Scalars['String']>;
  rideFeedback?: Maybe<RideFeedback>;
  rideFeedbackRequestPath?: Maybe<Scalars['String']>;
  rideNewPaymentMethodPath?: Maybe<Scalars['String']>;
  ridePayments?: Maybe<Array<RidePayment>>;
  rider?: Maybe<Rider>;
  riderDriverTwilioNumber?: Maybe<Scalars['String']>;
  riderWillUseProvidersWheelchair?: Maybe<Scalars['Boolean']>;
  rideType: Scalars['String'];
  stairsAboveNorm?: Maybe<Scalars['Boolean']>;
  stairsCount?: Maybe<Scalars['Int']>;
  stairsLabel?: Maybe<Scalars['String']>;
  startLocation: Location;
  status: Scalars['String'];
  tipCents?: Maybe<Scalars['Int']>;
  tollsCents?: Maybe<Scalars['Int']>;
  totalBonusCents?: Maybe<Scalars['Int']>;
  transportReason?: Maybe<TransportReason>;
  transportReasonComment?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  tzName: Scalars['String'];
  vehicle?: Maybe<Vehicle>;
  weightLbs?: Maybe<Scalars['Int']>;
};

export type RideCharge = {
  airportCents?: Maybe<Scalars['Int']>;
  amountCents: Scalars['Int'];
  cancelledCents?: Maybe<Scalars['Int']>;
  creditCents?: Maybe<Scalars['Int']>;
  deliveryCents?: Maybe<Scalars['Int']>;
  deliveryFeeCents?: Maybe<Scalars['Int']>;
  holidayCents?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastMinuteCents?: Maybe<Scalars['Int']>;
  mileageCents?: Maybe<Scalars['Int']>;
  oneOffCents?: Maybe<Scalars['Int']>;
  parkingCents?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  timeCents?: Maybe<Scalars['Int']>;
  timeOfDayCents?: Maybe<Scalars['Int']>;
  tipCents?: Maybe<Scalars['Int']>;
  tollsCents?: Maybe<Scalars['Int']>;
};

export type RideCollection = {
  collection: Array<Ride>;
  metadata: CollectionMetadata;
};

export type RideEstimate = {
  estimatedCostFormatted: Scalars['String'];
  estimatedDurationMinutesNoRound?: Maybe<Scalars['String']>;
  estimatedEndTime?: Maybe<Scalars['String']>;
};

export type RideFeedback = {
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  driverFeedback?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['Int']>;
};

export type RideHistory = {
  hasMore: Scalars['Boolean'];
  id: Scalars['ID'];
  rides: Array<Ride>;
};

export type RidePayment = {
  airportCents?: Maybe<Scalars['Int']>;
  amountCents: Scalars['Int'];
  cancelledCents?: Maybe<Scalars['Int']>;
  deliveryCents?: Maybe<Scalars['Int']>;
  holidayCents?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  instantPayoutCents?: Maybe<Scalars['Int']>;
  juiceCents?: Maybe<Scalars['Int']>;
  lastMinuteCents?: Maybe<Scalars['Int']>;
  mileageCents?: Maybe<Scalars['Int']>;
  oneOffCents?: Maybe<Scalars['Int']>;
  parkingCents?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  timeCents?: Maybe<Scalars['Int']>;
  timeOfDayCents?: Maybe<Scalars['Int']>;
  tipCents?: Maybe<Scalars['Int']>;
  tollsCents?: Maybe<Scalars['Int']>;
  transferCents?: Maybe<Scalars['Int']>;
};

export type Rider = {
  address?: Maybe<Location>;
  canMakeChanges?: Maybe<Scalars['Boolean']>;
  carePartner?: Maybe<CarePartner>;
  cognitiveImpairment?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<PersonGender>;
  hearingImpairment?: Maybe<Scalars['String']>;
  heightInches?: Maybe<Scalars['Int']>;
  historyRidesCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  languageId?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  lastPaymentInvoiced?: Maybe<Scalars['Boolean']>;
  lastPaymentMethodId?: Maybe<Scalars['String']>;
  mobilityAssistance?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notifPrefs?: Maybe<Array<Scalars['String']>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  phone?: Maybe<Scalars['String']>;
  privateDriverNotes?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  upcomingRidesCount?: Maybe<Scalars['Int']>;
  visionImpairment?: Maybe<Scalars['String']>;
  weightLbs?: Maybe<Scalars['Int']>;
};

export type RiderCollection = {
  collection: Array<Rider>;
  metadata: CollectionMetadata;
};

export enum RidesCollectionScope {
  History = 'HISTORY',
  Upcoming = 'UPCOMING',
}

export type StripeSetupIntentOutput = {
  customer: Scalars['ID'];
  ephemeralKey?: Maybe<Scalars['String']>;
  publishableKey: Scalars['String'];
  setupIntent: Scalars['String'];
};

export type Transport = {
  fullName: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type TransportPartner = {
  availableTransportTypes?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  drivers?: Maybe<Array<Driver>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stripeDashboardUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Reason for transport */
export type TransportReason = {
  categoryName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  requireAppointmentTime: Scalars['Boolean'];
  requireComment: Scalars['Boolean'];
};

export type TransportTypeOptions = {
  contactPrecautionsRequired: Scalars['Boolean'];
  oxygenRequired: Scalars['Boolean'];
  promptForBedsideNurseContact: Scalars['Boolean'];
  promptForMncs: Scalars['Boolean'];
};

export type UpdateAccountInput = {
  billingEmail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAccount */
export type UpdateAccountPayload = {
  account?: Maybe<Account>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateAccountPaymentMethod */
export type UpdateAccountPaymentMethodPayload = {
  error?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type UpdateCustodianInput = {
  clinicalCredential?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notifPrefs?: Maybe<Array<Scalars['String']>>;
  partnerId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  referralSource?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCustodian */
export type UpdateCustodianPayload = {
  custodian?: Maybe<Custodian>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateFavoriteDriver */
export type UpdateFavoriteDriverPayload = {
  error?: Maybe<Scalars['String']>;
};

export type UpdatePaymentMethodInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateRideInput = {
  appointmentTime?: Maybe<Scalars['ISO8601DateTime']>;
  arrivalBufferMinutes?: Maybe<Scalars['Int']>;
  contactPrecautionsRequired?: Maybe<Scalars['Boolean']>;
  deliverySubstitution?: Maybe<Scalars['String']>;
  dropoffRoomNumber?: Maybe<Scalars['String']>;
  endLocation?: Maybe<CreateLocationInput>;
  heightInches?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  oxygenRequired?: Maybe<Scalars['Boolean']>;
  passengerCount?: Maybe<Scalars['Int']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  pickupRoomNumber?: Maybe<Scalars['String']>;
  requestedEndTime?: Maybe<Scalars['ISO8601DateTime']>;
  requestedStartTime?: Maybe<Scalars['ISO8601DateTime']>;
  riderId?: Maybe<Scalars['String']>;
  riderWillUseProvidersWheelchair?: Maybe<Scalars['Boolean']>;
  rideType?: Maybe<Scalars['String']>;
  stairsCount?: Maybe<Scalars['Int']>;
  startLocation?: Maybe<CreateLocationInput>;
  transportReasonComment?: Maybe<Scalars['String']>;
  transportReasonId?: Maybe<Scalars['ID']>;
  transportType?: Maybe<Scalars['String']>;
  weightLbs?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateRide */
export type UpdateRidePayload = {
  error?: Maybe<Scalars['String']>;
  ride?: Maybe<Ride>;
};

/** Autogenerated return type of UpdateRider */
export type UpdateRiderPayload = {
  error?: Maybe<Scalars['String']>;
  rider?: Maybe<Rider>;
};

/** Autogenerated return type of UpdateRiderPaymentMethod */
export type UpdateRiderPaymentMethodPayload = {
  error?: Maybe<Scalars['String']>;
  rider?: Maybe<Rider>;
};

export type UserLanguage = {
  displayName: Scalars['String'];
  fluency: LanguageFluency;
  id: Scalars['ID'];
  isPrimary: Scalars['Boolean'];
};

export type Vehicle = {
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  licensePlate?: Maybe<Scalars['String']>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type VetrideRequest = {
  newRide?: Maybe<NewRide>;
  rider?: Maybe<Rider>;
};

export type AccountFieldsFragment = {
  id: string;
  name?: string | null | undefined;
  creditCents?: number | null | undefined;
  riderCount?: number | null | undefined;
  billingEmail?: string | null | undefined;
  invoiced: boolean;
  firstAvailableBookingEnabled: boolean;
  gridViewEnabled: boolean;
  payerEmailRequired: boolean;
  channel?: string | null | undefined;
  paymentMethods?:
    | Array<{
        id: string;
        name?: string | null | undefined;
        cardLast4?: string | null | undefined;
        cardBrand?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type CustodianFieldsFragment = {
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  email: string;
  phone?: string | null | undefined;
  notifPrefs: Array<string>;
  admin: boolean;
  canAccessBi: boolean;
  promptForCancellationReason: boolean;
  clinicalCredential?: string | null | undefined;
  pushToken?: string | null | undefined;
  availableTransportTypes?:
    | Array<{ fullName: string; id: number; name: string; shortName: string }>
    | null
    | undefined;
  availableTransportReasons?:
    | Array<{
        categoryName: string;
        id: string;
        name: string;
        requireAppointmentTime: boolean;
        requireComment: boolean;
      }>
    | null
    | undefined;
};

export type DashboardRideFieldsFragment = {
  id: string;
  requestedStartTime: any;
  requestedEndTime?: any | null | undefined;
  actualStartTime?: any | null | undefined;
  actualEndTime?: any | null | undefined;
  estimatedDistanceMiles?: number | null | undefined;
  estimatedDurationMinutes?: number | null | undefined;
  estimatedCostCents?: number | null | undefined;
  actualCostCents?: number | null | undefined;
  rideType: string;
  status: string;
  tzName: string;
  driver?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        profilePhoto?: string | null | undefined;
      }
    | null
    | undefined;
  rider?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        profilePhoto?: string | null | undefined;
      }
    | null
    | undefined;
  startLocation: {
    id: string;
    name?: string | null | undefined;
    address?: string | null | undefined;
  };
  endLocation?:
    | {
        id: string;
        name?: string | null | undefined;
        address?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DriverFieldsFragment = {
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  profilePhoto?: string | null | undefined;
  twilioPhone?: string | null | undefined;
  bio?: string | null | undefined;
  isPlaceholder: boolean;
  transportPartner?:
    | {
        availableTransportTypes?: string | null | undefined;
        createdAt: any;
        id: string;
        name?: string | null | undefined;
        stripeDashboardUrl?: string | null | undefined;
        updatedAt?: any | null | undefined;
      }
    | null
    | undefined;
  currentVehicle?:
    | {
        id: string;
        color?: string | null | undefined;
        year?: string | null | undefined;
        make?: string | null | undefined;
        model?: string | null | undefined;
        licensePlate?: string | null | undefined;
        vehicleType?: string | null | undefined;
      }
    | null
    | undefined;
};

export type LocationFieldsFragment = {
  id: string;
  name?: string | null | undefined;
  address?: string | null | undefined;
  longitude?: number | null | undefined;
  latitude?: number | null | undefined;
  zipcode?: string | null | undefined;
  instructions?: string | null | undefined;
};

export type NewRideFieldsFragment = {
  requestedStartTime: any;
  notes?: string | null | undefined;
  rideType: string;
  status: string;
  transportType?: string | null | undefined;
  tzName: string;
  firstAvailable: boolean;
  arrivalBufferMinutes?: number | null | undefined;
  startLocation: {
    id: string;
    name?: string | null | undefined;
    address?: string | null | undefined;
    longitude?: number | null | undefined;
    latitude?: number | null | undefined;
    zipcode?: string | null | undefined;
    instructions?: string | null | undefined;
  };
  endLocation?:
    | {
        id: string;
        name?: string | null | undefined;
        address?: string | null | undefined;
        longitude?: number | null | undefined;
        latitude?: number | null | undefined;
        zipcode?: string | null | undefined;
        instructions?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PaymentMethodFieldsFragment = {
  id: string;
  name?: string | null | undefined;
  cardLast4?: string | null | undefined;
  cardBrand?: string | null | undefined;
};

export type RideChargeFieldsFragment = {
  id: string;
  amountCents: number;
  airportCents?: number | null | undefined;
  cancelledCents?: number | null | undefined;
  creditCents?: number | null | undefined;
  deliveryCents?: number | null | undefined;
  deliveryFeeCents?: number | null | undefined;
  holidayCents?: number | null | undefined;
  lastMinuteCents?: number | null | undefined;
  mileageCents?: number | null | undefined;
  oneOffCents?: number | null | undefined;
  parkingCents?: number | null | undefined;
  timeCents?: number | null | undefined;
  timeOfDayCents?: number | null | undefined;
  tipCents?: number | null | undefined;
  tollsCents?: number | null | undefined;
  status: string;
};

export type RideFieldsFragment = {
  id: string;
  requestedStartTime: any;
  requestedEndTime?: any | null | undefined;
  actualStartTime?: any | null | undefined;
  actualEndTime?: any | null | undefined;
  actualDistanceMiles?: number | null | undefined;
  estimatedDistanceMiles?: number | null | undefined;
  estimatedDurationMinutes?: number | null | undefined;
  estimatedCostCents?: number | null | undefined;
  actualCostCents?: number | null | undefined;
  notes?: string | null | undefined;
  passengerCount?: number | null | undefined;
  rideType: string;
  status: string;
  deliverySubstitution?: string | null | undefined;
  receiptPhotos?: Array<string> | null | undefined;
  custodianReviewedAt?: any | null | undefined;
  stairsCount?: number | null | undefined;
  stairsLabel?: string | null | undefined;
  transportType?: string | null | undefined;
  bariatric?: boolean | null | undefined;
  tzName: string;
  heightInches?: number | null | undefined;
  weightLbs?: number | null | undefined;
  dropoffRoomNumber?: string | null | undefined;
  pickupRoomNumber?: string | null | undefined;
  riderWillUseProvidersWheelchair?: boolean | null | undefined;
  payerType?: string | null | undefined;
  costCenter?: string | null | undefined;
  oxygenRequired?: boolean | null | undefined;
  contactPrecautionsRequired?: boolean | null | undefined;
  estimatedEndTime?: string | null | undefined;
  firstAvailable: boolean;
  rideFeedbackRequestPath?: string | null | undefined;
  rideNewPaymentMethodPath?: string | null | undefined;
  transportReasonComment?: string | null | undefined;
  appointmentTime?: any | null | undefined;
  biddingWindowEndsAt?: any | null | undefined;
  bidRequestedStartTime?: any | null | undefined;
  originalRequestedStartTime?: any | null | undefined;
  bestTimeBidsDeclineBefore?: any | null | undefined;
  arrivalBufferMinutes?: number | null | undefined;
  batchId?: number | null | undefined;
  rideFeedback?:
    | {
        createdAt?: any | null | undefined;
        driverFeedback?: string | null | undefined;
        stars?: number | null | undefined;
      }
    | null
    | undefined;
  medicalNecessityCertificationStatement?:
    | {
        mncsAnswers?:
          | Array<{
              fieldType: MncsPromptField;
              group1?: string | null | undefined;
              group2?: string | null | undefined;
              name?: string | null | undefined;
              notes?: string | null | undefined;
              slug: string;
              sortOrder?: number | null | undefined;
              value?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
  bedsideNurse?: { fullName: string; phone: string } | null | undefined;
  payer?:
    | {
        id: string;
        firstName: string;
        lastName: string;
        phone: string;
        email?: string | null | undefined;
        address?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        currentPaymentMethod?:
          | {
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  paymentMethod?:
    | {
        id: string;
        name?: string | null | undefined;
        cardLast4?: string | null | undefined;
        cardBrand?: string | null | undefined;
      }
    | null
    | undefined;
  driver?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        profilePhoto?: string | null | undefined;
        twilioPhone?: string | null | undefined;
        bio?: string | null | undefined;
        isPlaceholder: boolean;
        transportPartner?:
          | {
              availableTransportTypes?: string | null | undefined;
              createdAt: any;
              id: string;
              name?: string | null | undefined;
              stripeDashboardUrl?: string | null | undefined;
              updatedAt?: any | null | undefined;
            }
          | null
          | undefined;
        currentVehicle?:
          | {
              id: string;
              color?: string | null | undefined;
              year?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              licensePlate?: string | null | undefined;
              vehicleType?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  rider?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        phone?: string | null | undefined;
        email?: string | null | undefined;
        notifPrefs?: Array<string> | null | undefined;
        notes?: string | null | undefined;
        canMakeChanges?: boolean | null | undefined;
        mobilityAssistance?: string | null | undefined;
        visionImpairment?: string | null | undefined;
        hearingImpairment?: string | null | undefined;
        cognitiveImpairment?: string | null | undefined;
        profilePhoto?: string | null | undefined;
        dob?: string | null | undefined;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        gender?: PersonGender | null | undefined;
        languageId?: string | null | undefined;
        address?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
        carePartner?:
          | {
              email?: string | null | undefined;
              firstName: string;
              lastName: string;
              notifPrefs?: Array<string> | null | undefined;
              phone: string;
              relationshipNotes?: string | null | undefined;
              relationshipType: CarePartnerRelationship;
              id: string;
              twilioPhone?: string | null | undefined;
              displayName: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  custodian?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
        phone?: string | null | undefined;
        notifPrefs: Array<string>;
        admin: boolean;
        canAccessBi: boolean;
        promptForCancellationReason: boolean;
        clinicalCredential?: string | null | undefined;
        pushToken?: string | null | undefined;
        availableTransportTypes?:
          | Array<{
              fullName: string;
              id: number;
              name: string;
              shortName: string;
            }>
          | null
          | undefined;
        availableTransportReasons?:
          | Array<{
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
  startLocation: {
    id: string;
    name?: string | null | undefined;
    address?: string | null | undefined;
    longitude?: number | null | undefined;
    latitude?: number | null | undefined;
    zipcode?: string | null | undefined;
    instructions?: string | null | undefined;
  };
  endLocation?:
    | {
        id: string;
        name?: string | null | undefined;
        address?: string | null | undefined;
        longitude?: number | null | undefined;
        latitude?: number | null | undefined;
        zipcode?: string | null | undefined;
        instructions?: string | null | undefined;
      }
    | null
    | undefined;
  transportReason?:
    | {
        categoryName: string;
        id: string;
        name: string;
        requireAppointmentTime: boolean;
        requireComment: boolean;
      }
    | null
    | undefined;
};

export type RiderFieldsFragment = {
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  phone?: string | null | undefined;
  email?: string | null | undefined;
  notifPrefs?: Array<string> | null | undefined;
  notes?: string | null | undefined;
  canMakeChanges?: boolean | null | undefined;
  mobilityAssistance?: string | null | undefined;
  visionImpairment?: string | null | undefined;
  hearingImpairment?: string | null | undefined;
  cognitiveImpairment?: string | null | undefined;
  profilePhoto?: string | null | undefined;
  dob?: string | null | undefined;
  heightInches?: number | null | undefined;
  weightLbs?: number | null | undefined;
  gender?: PersonGender | null | undefined;
  languageId?: string | null | undefined;
  address?:
    | {
        id: string;
        name?: string | null | undefined;
        address?: string | null | undefined;
        longitude?: number | null | undefined;
        latitude?: number | null | undefined;
        zipcode?: string | null | undefined;
        instructions?: string | null | undefined;
      }
    | null
    | undefined;
  paymentMethods?:
    | Array<{
        id: string;
        name?: string | null | undefined;
        cardLast4?: string | null | undefined;
        cardBrand?: string | null | undefined;
      }>
    | null
    | undefined;
  carePartner?:
    | {
        email?: string | null | undefined;
        firstName: string;
        lastName: string;
        notifPrefs?: Array<string> | null | undefined;
        phone: string;
        relationshipNotes?: string | null | undefined;
        relationshipType: CarePartnerRelationship;
        id: string;
        twilioPhone?: string | null | undefined;
        displayName: string;
      }
    | null
    | undefined;
};

export type TransportPartnerFieldsFragment = {
  availableTransportTypes?: string | null | undefined;
  createdAt: any;
  id: string;
  name?: string | null | undefined;
  stripeDashboardUrl?: string | null | undefined;
  updatedAt?: any | null | undefined;
};

export type TransportReasonFieldsFragment = {
  categoryName: string;
  id: string;
  name: string;
  requireAppointmentTime: boolean;
  requireComment: boolean;
};

export type VehicleFieldsFragment = {
  id: string;
  color?: string | null | undefined;
  year?: string | null | undefined;
  make?: string | null | undefined;
  model?: string | null | undefined;
  licensePlate?: string | null | undefined;
  vehicleType?: string | null | undefined;
};

export type AddCustodianMutationVariables = Exact<{
  custodian: CreateCustodianInput;
}>;

export type AddCustodianMutation = {
  addCustodian?:
    | {
        error?: string | null | undefined;
        custodian?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              email: string;
              phone?: string | null | undefined;
              notifPrefs: Array<string>;
              admin: boolean;
              canAccessBi: boolean;
              promptForCancellationReason: boolean;
              clinicalCredential?: string | null | undefined;
              pushToken?: string | null | undefined;
              availableTransportTypes?:
                | Array<{
                    fullName: string;
                    id: number;
                    name: string;
                    shortName: string;
                  }>
                | null
                | undefined;
              availableTransportReasons?:
                | Array<{
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AddCustodianRiderMutationMutationVariables = Exact<{
  rider: CreateRiderInput;
  ehrSessionId?: Maybe<Scalars['Int']>;
}>;

export type AddCustodianRiderMutationMutation = {
  addCustodianRider?:
    | {
        error?: string | null | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CancelRideMutationMutationVariables = Exact<{
  rideId: Scalars['Int'];
  cancellationReasonId?: Maybe<Scalars['Int']>;
  batchCancel?: Maybe<Scalars['Boolean']>;
}>;

export type CancelRideMutationMutation = {
  cancelRide?:
    | {
        error?: string | null | undefined;
        ride?: { id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type CreateRideMutationMutationVariables = Exact<{
  ride: CreateRideInput;
  recurringOptions?: Maybe<CreateRecurringInput>;
  isReturnTrip: Scalars['Boolean'];
  mncsOptions?: Maybe<Array<CreateMncsInput> | CreateMncsInput>;
  bedsideNurse?: Maybe<CreateBedsideNurseInput>;
}>;

export type CreateRideMutationMutation = {
  createRide?:
    | {
        error?: string | null | undefined;
        ride?:
          | {
              id: string;
              requestedStartTime: any;
              requestedEndTime?: any | null | undefined;
              actualStartTime?: any | null | undefined;
              actualEndTime?: any | null | undefined;
              actualDistanceMiles?: number | null | undefined;
              estimatedDistanceMiles?: number | null | undefined;
              estimatedDurationMinutes?: number | null | undefined;
              estimatedCostCents?: number | null | undefined;
              actualCostCents?: number | null | undefined;
              notes?: string | null | undefined;
              passengerCount?: number | null | undefined;
              rideType: string;
              status: string;
              deliverySubstitution?: string | null | undefined;
              receiptPhotos?: Array<string> | null | undefined;
              custodianReviewedAt?: any | null | undefined;
              stairsCount?: number | null | undefined;
              stairsLabel?: string | null | undefined;
              transportType?: string | null | undefined;
              bariatric?: boolean | null | undefined;
              tzName: string;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              dropoffRoomNumber?: string | null | undefined;
              pickupRoomNumber?: string | null | undefined;
              riderWillUseProvidersWheelchair?: boolean | null | undefined;
              payerType?: string | null | undefined;
              costCenter?: string | null | undefined;
              oxygenRequired?: boolean | null | undefined;
              contactPrecautionsRequired?: boolean | null | undefined;
              estimatedEndTime?: string | null | undefined;
              firstAvailable: boolean;
              rideFeedbackRequestPath?: string | null | undefined;
              rideNewPaymentMethodPath?: string | null | undefined;
              transportReasonComment?: string | null | undefined;
              appointmentTime?: any | null | undefined;
              biddingWindowEndsAt?: any | null | undefined;
              bidRequestedStartTime?: any | null | undefined;
              originalRequestedStartTime?: any | null | undefined;
              bestTimeBidsDeclineBefore?: any | null | undefined;
              arrivalBufferMinutes?: number | null | undefined;
              batchId?: number | null | undefined;
              rideFeedback?:
                | {
                    createdAt?: any | null | undefined;
                    driverFeedback?: string | null | undefined;
                    stars?: number | null | undefined;
                  }
                | null
                | undefined;
              medicalNecessityCertificationStatement?:
                | {
                    mncsAnswers?:
                      | Array<{
                          fieldType: MncsPromptField;
                          group1?: string | null | undefined;
                          group2?: string | null | undefined;
                          name?: string | null | undefined;
                          notes?: string | null | undefined;
                          slug: string;
                          sortOrder?: number | null | undefined;
                          value?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              bedsideNurse?:
                | { fullName: string; phone: string }
                | null
                | undefined;
              payer?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    phone: string;
                    email?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    currentPaymentMethod?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              paymentMethod?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }
                | null
                | undefined;
              driver?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    twilioPhone?: string | null | undefined;
                    bio?: string | null | undefined;
                    isPlaceholder: boolean;
                    transportPartner?:
                      | {
                          availableTransportTypes?: string | null | undefined;
                          createdAt: any;
                          id: string;
                          name?: string | null | undefined;
                          stripeDashboardUrl?: string | null | undefined;
                          updatedAt?: any | null | undefined;
                        }
                      | null
                      | undefined;
                    currentVehicle?:
                      | {
                          id: string;
                          color?: string | null | undefined;
                          year?: string | null | undefined;
                          make?: string | null | undefined;
                          model?: string | null | undefined;
                          licensePlate?: string | null | undefined;
                          vehicleType?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              rider?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    phone?: string | null | undefined;
                    email?: string | null | undefined;
                    notifPrefs?: Array<string> | null | undefined;
                    notes?: string | null | undefined;
                    canMakeChanges?: boolean | null | undefined;
                    mobilityAssistance?: string | null | undefined;
                    visionImpairment?: string | null | undefined;
                    hearingImpairment?: string | null | undefined;
                    cognitiveImpairment?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    dob?: string | null | undefined;
                    heightInches?: number | null | undefined;
                    weightLbs?: number | null | undefined;
                    gender?: PersonGender | null | undefined;
                    languageId?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    paymentMethods?:
                      | Array<{
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                    carePartner?:
                      | {
                          email?: string | null | undefined;
                          firstName: string;
                          lastName: string;
                          notifPrefs?: Array<string> | null | undefined;
                          phone: string;
                          relationshipNotes?: string | null | undefined;
                          relationshipType: CarePartnerRelationship;
                          id: string;
                          twilioPhone?: string | null | undefined;
                          displayName: string;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              custodian?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email: string;
                    phone?: string | null | undefined;
                    notifPrefs: Array<string>;
                    admin: boolean;
                    canAccessBi: boolean;
                    promptForCancellationReason: boolean;
                    clinicalCredential?: string | null | undefined;
                    pushToken?: string | null | undefined;
                    availableTransportTypes?:
                      | Array<{
                          fullName: string;
                          id: number;
                          name: string;
                          shortName: string;
                        }>
                      | null
                      | undefined;
                    availableTransportReasons?:
                      | Array<{
                          categoryName: string;
                          id: string;
                          name: string;
                          requireAppointmentTime: boolean;
                          requireComment: boolean;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              startLocation: {
                id: string;
                name?: string | null | undefined;
                address?: string | null | undefined;
                longitude?: number | null | undefined;
                latitude?: number | null | undefined;
                zipcode?: string | null | undefined;
                instructions?: string | null | undefined;
              };
              endLocation?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              transportReason?:
                | {
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        rideErrors?:
          | Array<{ field: string; messages: Array<string> }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeclineBestTimeBidsMutationMutationVariables = Exact<{
  rideId: Scalars['ID'];
}>;

export type DeclineBestTimeBidsMutationMutation = {
  declineBestTimeBids?:
    | {
        error?: string | null | undefined;
        ride?: { id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type RemoveAccountPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;

export type RemoveAccountPaymentMethodMutation = {
  removeAccountPaymentMethod?:
    | {
        error?: string | null | undefined;
        account?:
          | {
              id: string;
              name?: string | null | undefined;
              creditCents?: number | null | undefined;
              riderCount?: number | null | undefined;
              billingEmail?: string | null | undefined;
              invoiced: boolean;
              firstAvailableBookingEnabled: boolean;
              gridViewEnabled: boolean;
              payerEmailRequired: boolean;
              channel?: string | null | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RemoveCarePartnerMutationVariables = Exact<{
  riderId: Scalars['ID'];
}>;

export type RemoveCarePartnerMutation = {
  removeCarePartner?:
    | { riderId?: string | null | undefined; error?: string | null | undefined }
    | null
    | undefined;
};

export type RemoveRiderPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
  riderId: Scalars['String'];
}>;

export type RemoveRiderPaymentMethodMutation = {
  removeRiderPaymentMethod?:
    | {
        error?: string | null | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RequestRideFeedbackMutationMutationVariables = Exact<{
  RequestRideFeedback: RequestRideFeedbackInput;
}>;

export type RequestRideFeedbackMutationMutation = {
  requestRideFeedback?:
    | {
        error?: string | null | undefined;
        ride?:
          | {
              id: string;
              requestedStartTime: any;
              requestedEndTime?: any | null | undefined;
              actualStartTime?: any | null | undefined;
              actualEndTime?: any | null | undefined;
              actualDistanceMiles?: number | null | undefined;
              estimatedDistanceMiles?: number | null | undefined;
              estimatedDurationMinutes?: number | null | undefined;
              estimatedCostCents?: number | null | undefined;
              actualCostCents?: number | null | undefined;
              notes?: string | null | undefined;
              passengerCount?: number | null | undefined;
              rideType: string;
              status: string;
              deliverySubstitution?: string | null | undefined;
              receiptPhotos?: Array<string> | null | undefined;
              custodianReviewedAt?: any | null | undefined;
              stairsCount?: number | null | undefined;
              stairsLabel?: string | null | undefined;
              transportType?: string | null | undefined;
              bariatric?: boolean | null | undefined;
              tzName: string;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              dropoffRoomNumber?: string | null | undefined;
              pickupRoomNumber?: string | null | undefined;
              riderWillUseProvidersWheelchair?: boolean | null | undefined;
              payerType?: string | null | undefined;
              costCenter?: string | null | undefined;
              oxygenRequired?: boolean | null | undefined;
              contactPrecautionsRequired?: boolean | null | undefined;
              estimatedEndTime?: string | null | undefined;
              firstAvailable: boolean;
              rideFeedbackRequestPath?: string | null | undefined;
              rideNewPaymentMethodPath?: string | null | undefined;
              transportReasonComment?: string | null | undefined;
              appointmentTime?: any | null | undefined;
              biddingWindowEndsAt?: any | null | undefined;
              bidRequestedStartTime?: any | null | undefined;
              originalRequestedStartTime?: any | null | undefined;
              bestTimeBidsDeclineBefore?: any | null | undefined;
              arrivalBufferMinutes?: number | null | undefined;
              batchId?: number | null | undefined;
              rideFeedback?:
                | {
                    createdAt?: any | null | undefined;
                    driverFeedback?: string | null | undefined;
                    stars?: number | null | undefined;
                  }
                | null
                | undefined;
              medicalNecessityCertificationStatement?:
                | {
                    mncsAnswers?:
                      | Array<{
                          fieldType: MncsPromptField;
                          group1?: string | null | undefined;
                          group2?: string | null | undefined;
                          name?: string | null | undefined;
                          notes?: string | null | undefined;
                          slug: string;
                          sortOrder?: number | null | undefined;
                          value?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              bedsideNurse?:
                | { fullName: string; phone: string }
                | null
                | undefined;
              payer?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    phone: string;
                    email?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    currentPaymentMethod?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              paymentMethod?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }
                | null
                | undefined;
              driver?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    twilioPhone?: string | null | undefined;
                    bio?: string | null | undefined;
                    isPlaceholder: boolean;
                    transportPartner?:
                      | {
                          availableTransportTypes?: string | null | undefined;
                          createdAt: any;
                          id: string;
                          name?: string | null | undefined;
                          stripeDashboardUrl?: string | null | undefined;
                          updatedAt?: any | null | undefined;
                        }
                      | null
                      | undefined;
                    currentVehicle?:
                      | {
                          id: string;
                          color?: string | null | undefined;
                          year?: string | null | undefined;
                          make?: string | null | undefined;
                          model?: string | null | undefined;
                          licensePlate?: string | null | undefined;
                          vehicleType?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              rider?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    phone?: string | null | undefined;
                    email?: string | null | undefined;
                    notifPrefs?: Array<string> | null | undefined;
                    notes?: string | null | undefined;
                    canMakeChanges?: boolean | null | undefined;
                    mobilityAssistance?: string | null | undefined;
                    visionImpairment?: string | null | undefined;
                    hearingImpairment?: string | null | undefined;
                    cognitiveImpairment?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    dob?: string | null | undefined;
                    heightInches?: number | null | undefined;
                    weightLbs?: number | null | undefined;
                    gender?: PersonGender | null | undefined;
                    languageId?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    paymentMethods?:
                      | Array<{
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                    carePartner?:
                      | {
                          email?: string | null | undefined;
                          firstName: string;
                          lastName: string;
                          notifPrefs?: Array<string> | null | undefined;
                          phone: string;
                          relationshipNotes?: string | null | undefined;
                          relationshipType: CarePartnerRelationship;
                          id: string;
                          twilioPhone?: string | null | undefined;
                          displayName: string;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              custodian?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email: string;
                    phone?: string | null | undefined;
                    notifPrefs: Array<string>;
                    admin: boolean;
                    canAccessBi: boolean;
                    promptForCancellationReason: boolean;
                    clinicalCredential?: string | null | undefined;
                    pushToken?: string | null | undefined;
                    availableTransportTypes?:
                      | Array<{
                          fullName: string;
                          id: number;
                          name: string;
                          shortName: string;
                        }>
                      | null
                      | undefined;
                    availableTransportReasons?:
                      | Array<{
                          categoryName: string;
                          id: string;
                          name: string;
                          requireAppointmentTime: boolean;
                          requireComment: boolean;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              startLocation: {
                id: string;
                name?: string | null | undefined;
                address?: string | null | undefined;
                longitude?: number | null | undefined;
                latitude?: number | null | undefined;
                zipcode?: string | null | undefined;
                instructions?: string | null | undefined;
              };
              endLocation?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              transportReason?:
                | {
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ResetCustodianPasswordMutationVariables = Exact<{
  custodianId: Scalars['String'];
}>;

export type ResetCustodianPasswordMutation = {
  resetCustodianPassword?:
    | { error?: string | null | undefined }
    | null
    | undefined;
};

export type ReviewRideMutationMutationVariables = Exact<{
  rideId: Scalars['String'];
  ride: ReviewRideInput;
}>;

export type ReviewRideMutationMutation = {
  reviewRide?:
    | {
        completedRideCount?: number | null | undefined;
        error?: string | null | undefined;
        ride?:
          | {
              id: string;
              requestedStartTime: any;
              requestedEndTime?: any | null | undefined;
              actualStartTime?: any | null | undefined;
              actualEndTime?: any | null | undefined;
              actualDistanceMiles?: number | null | undefined;
              estimatedDistanceMiles?: number | null | undefined;
              estimatedDurationMinutes?: number | null | undefined;
              estimatedCostCents?: number | null | undefined;
              actualCostCents?: number | null | undefined;
              notes?: string | null | undefined;
              passengerCount?: number | null | undefined;
              rideType: string;
              status: string;
              deliverySubstitution?: string | null | undefined;
              receiptPhotos?: Array<string> | null | undefined;
              custodianReviewedAt?: any | null | undefined;
              stairsCount?: number | null | undefined;
              stairsLabel?: string | null | undefined;
              transportType?: string | null | undefined;
              bariatric?: boolean | null | undefined;
              tzName: string;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              dropoffRoomNumber?: string | null | undefined;
              pickupRoomNumber?: string | null | undefined;
              riderWillUseProvidersWheelchair?: boolean | null | undefined;
              payerType?: string | null | undefined;
              costCenter?: string | null | undefined;
              oxygenRequired?: boolean | null | undefined;
              contactPrecautionsRequired?: boolean | null | undefined;
              estimatedEndTime?: string | null | undefined;
              firstAvailable: boolean;
              rideFeedbackRequestPath?: string | null | undefined;
              rideNewPaymentMethodPath?: string | null | undefined;
              transportReasonComment?: string | null | undefined;
              appointmentTime?: any | null | undefined;
              biddingWindowEndsAt?: any | null | undefined;
              bidRequestedStartTime?: any | null | undefined;
              originalRequestedStartTime?: any | null | undefined;
              bestTimeBidsDeclineBefore?: any | null | undefined;
              arrivalBufferMinutes?: number | null | undefined;
              batchId?: number | null | undefined;
              rideFeedback?:
                | {
                    createdAt?: any | null | undefined;
                    driverFeedback?: string | null | undefined;
                    stars?: number | null | undefined;
                  }
                | null
                | undefined;
              medicalNecessityCertificationStatement?:
                | {
                    mncsAnswers?:
                      | Array<{
                          fieldType: MncsPromptField;
                          group1?: string | null | undefined;
                          group2?: string | null | undefined;
                          name?: string | null | undefined;
                          notes?: string | null | undefined;
                          slug: string;
                          sortOrder?: number | null | undefined;
                          value?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              bedsideNurse?:
                | { fullName: string; phone: string }
                | null
                | undefined;
              payer?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    phone: string;
                    email?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    currentPaymentMethod?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              paymentMethod?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }
                | null
                | undefined;
              driver?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    twilioPhone?: string | null | undefined;
                    bio?: string | null | undefined;
                    isPlaceholder: boolean;
                    transportPartner?:
                      | {
                          availableTransportTypes?: string | null | undefined;
                          createdAt: any;
                          id: string;
                          name?: string | null | undefined;
                          stripeDashboardUrl?: string | null | undefined;
                          updatedAt?: any | null | undefined;
                        }
                      | null
                      | undefined;
                    currentVehicle?:
                      | {
                          id: string;
                          color?: string | null | undefined;
                          year?: string | null | undefined;
                          make?: string | null | undefined;
                          model?: string | null | undefined;
                          licensePlate?: string | null | undefined;
                          vehicleType?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              rider?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    phone?: string | null | undefined;
                    email?: string | null | undefined;
                    notifPrefs?: Array<string> | null | undefined;
                    notes?: string | null | undefined;
                    canMakeChanges?: boolean | null | undefined;
                    mobilityAssistance?: string | null | undefined;
                    visionImpairment?: string | null | undefined;
                    hearingImpairment?: string | null | undefined;
                    cognitiveImpairment?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    dob?: string | null | undefined;
                    heightInches?: number | null | undefined;
                    weightLbs?: number | null | undefined;
                    gender?: PersonGender | null | undefined;
                    languageId?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    paymentMethods?:
                      | Array<{
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                    carePartner?:
                      | {
                          email?: string | null | undefined;
                          firstName: string;
                          lastName: string;
                          notifPrefs?: Array<string> | null | undefined;
                          phone: string;
                          relationshipNotes?: string | null | undefined;
                          relationshipType: CarePartnerRelationship;
                          id: string;
                          twilioPhone?: string | null | undefined;
                          displayName: string;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              custodian?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email: string;
                    phone?: string | null | undefined;
                    notifPrefs: Array<string>;
                    admin: boolean;
                    canAccessBi: boolean;
                    promptForCancellationReason: boolean;
                    clinicalCredential?: string | null | undefined;
                    pushToken?: string | null | undefined;
                    availableTransportTypes?:
                      | Array<{
                          fullName: string;
                          id: number;
                          name: string;
                          shortName: string;
                        }>
                      | null
                      | undefined;
                    availableTransportReasons?:
                      | Array<{
                          categoryName: string;
                          id: string;
                          name: string;
                          requireAppointmentTime: boolean;
                          requireComment: boolean;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              startLocation: {
                id: string;
                name?: string | null | undefined;
                address?: string | null | undefined;
                longitude?: number | null | undefined;
                latitude?: number | null | undefined;
                zipcode?: string | null | undefined;
                instructions?: string | null | undefined;
              };
              endLocation?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              transportReason?:
                | {
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateAccountMutationMutationVariables = Exact<{
  account: UpdateAccountInput;
}>;

export type UpdateAccountMutationMutation = {
  updateAccount?:
    | {
        error?: string | null | undefined;
        account?:
          | {
              id: string;
              name?: string | null | undefined;
              creditCents?: number | null | undefined;
              riderCount?: number | null | undefined;
              billingEmail?: string | null | undefined;
              invoiced: boolean;
              firstAvailableBookingEnabled: boolean;
              gridViewEnabled: boolean;
              payerEmailRequired: boolean;
              channel?: string | null | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateAccountPaymentMethodMutationMutationVariables = Exact<{
  paymentMethod: UpdatePaymentMethodInput;
  paymentMethodId: Scalars['String'];
}>;

export type UpdateAccountPaymentMethodMutationMutation = {
  updateAccountPaymentMethod?:
    | {
        error?: string | null | undefined;
        paymentMethod?:
          | {
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateCustodianMutationVariables = Exact<{
  custodian: UpdateCustodianInput;
  custodianId?: Maybe<Scalars['String']>;
}>;

export type UpdateCustodianMutation = {
  updateCustodian?:
    | {
        error?: string | null | undefined;
        custodian?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              email: string;
              phone?: string | null | undefined;
              notifPrefs: Array<string>;
              admin: boolean;
              canAccessBi: boolean;
              promptForCancellationReason: boolean;
              clinicalCredential?: string | null | undefined;
              pushToken?: string | null | undefined;
              availableTransportTypes?:
                | Array<{
                    fullName: string;
                    id: number;
                    name: string;
                    shortName: string;
                  }>
                | null
                | undefined;
              availableTransportReasons?:
                | Array<{
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateFavoriteDriverMutationMutationVariables = Exact<{
  riderId: Scalars['String'];
  driverId: Scalars['String'];
  favorite: Scalars['Boolean'];
}>;

export type UpdateFavoriteDriverMutationMutation = {
  updateFavoriteDriver?:
    | { error?: string | null | undefined }
    | null
    | undefined;
};

export type UpdateRideMutationMutationVariables = Exact<{
  ride: UpdateRideInput;
  rideId: Scalars['Int'];
  bedsideNurse?: Maybe<CreateBedsideNurseInput>;
  mncsOptions?: Maybe<Array<CreateMncsInput> | CreateMncsInput>;
}>;

export type UpdateRideMutationMutation = {
  updateRide?:
    | {
        error?: string | null | undefined;
        ride?:
          | {
              id: string;
              requestedStartTime: any;
              requestedEndTime?: any | null | undefined;
              actualStartTime?: any | null | undefined;
              actualEndTime?: any | null | undefined;
              actualDistanceMiles?: number | null | undefined;
              estimatedDistanceMiles?: number | null | undefined;
              estimatedDurationMinutes?: number | null | undefined;
              estimatedCostCents?: number | null | undefined;
              actualCostCents?: number | null | undefined;
              notes?: string | null | undefined;
              passengerCount?: number | null | undefined;
              rideType: string;
              status: string;
              deliverySubstitution?: string | null | undefined;
              receiptPhotos?: Array<string> | null | undefined;
              custodianReviewedAt?: any | null | undefined;
              stairsCount?: number | null | undefined;
              stairsLabel?: string | null | undefined;
              transportType?: string | null | undefined;
              bariatric?: boolean | null | undefined;
              tzName: string;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              dropoffRoomNumber?: string | null | undefined;
              pickupRoomNumber?: string | null | undefined;
              riderWillUseProvidersWheelchair?: boolean | null | undefined;
              payerType?: string | null | undefined;
              costCenter?: string | null | undefined;
              oxygenRequired?: boolean | null | undefined;
              contactPrecautionsRequired?: boolean | null | undefined;
              estimatedEndTime?: string | null | undefined;
              firstAvailable: boolean;
              rideFeedbackRequestPath?: string | null | undefined;
              rideNewPaymentMethodPath?: string | null | undefined;
              transportReasonComment?: string | null | undefined;
              appointmentTime?: any | null | undefined;
              biddingWindowEndsAt?: any | null | undefined;
              bidRequestedStartTime?: any | null | undefined;
              originalRequestedStartTime?: any | null | undefined;
              bestTimeBidsDeclineBefore?: any | null | undefined;
              arrivalBufferMinutes?: number | null | undefined;
              batchId?: number | null | undefined;
              rideFeedback?:
                | {
                    createdAt?: any | null | undefined;
                    driverFeedback?: string | null | undefined;
                    stars?: number | null | undefined;
                  }
                | null
                | undefined;
              medicalNecessityCertificationStatement?:
                | {
                    mncsAnswers?:
                      | Array<{
                          fieldType: MncsPromptField;
                          group1?: string | null | undefined;
                          group2?: string | null | undefined;
                          name?: string | null | undefined;
                          notes?: string | null | undefined;
                          slug: string;
                          sortOrder?: number | null | undefined;
                          value?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              bedsideNurse?:
                | { fullName: string; phone: string }
                | null
                | undefined;
              payer?:
                | {
                    id: string;
                    firstName: string;
                    lastName: string;
                    phone: string;
                    email?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    currentPaymentMethod?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              paymentMethod?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }
                | null
                | undefined;
              driver?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    twilioPhone?: string | null | undefined;
                    bio?: string | null | undefined;
                    isPlaceholder: boolean;
                    transportPartner?:
                      | {
                          availableTransportTypes?: string | null | undefined;
                          createdAt: any;
                          id: string;
                          name?: string | null | undefined;
                          stripeDashboardUrl?: string | null | undefined;
                          updatedAt?: any | null | undefined;
                        }
                      | null
                      | undefined;
                    currentVehicle?:
                      | {
                          id: string;
                          color?: string | null | undefined;
                          year?: string | null | undefined;
                          make?: string | null | undefined;
                          model?: string | null | undefined;
                          licensePlate?: string | null | undefined;
                          vehicleType?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              rider?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    phone?: string | null | undefined;
                    email?: string | null | undefined;
                    notifPrefs?: Array<string> | null | undefined;
                    notes?: string | null | undefined;
                    canMakeChanges?: boolean | null | undefined;
                    mobilityAssistance?: string | null | undefined;
                    visionImpairment?: string | null | undefined;
                    hearingImpairment?: string | null | undefined;
                    cognitiveImpairment?: string | null | undefined;
                    profilePhoto?: string | null | undefined;
                    dob?: string | null | undefined;
                    heightInches?: number | null | undefined;
                    weightLbs?: number | null | undefined;
                    gender?: PersonGender | null | undefined;
                    languageId?: string | null | undefined;
                    address?:
                      | {
                          id: string;
                          name?: string | null | undefined;
                          address?: string | null | undefined;
                          longitude?: number | null | undefined;
                          latitude?: number | null | undefined;
                          zipcode?: string | null | undefined;
                          instructions?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    paymentMethods?:
                      | Array<{
                          id: string;
                          name?: string | null | undefined;
                          cardLast4?: string | null | undefined;
                          cardBrand?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                    carePartner?:
                      | {
                          email?: string | null | undefined;
                          firstName: string;
                          lastName: string;
                          notifPrefs?: Array<string> | null | undefined;
                          phone: string;
                          relationshipNotes?: string | null | undefined;
                          relationshipType: CarePartnerRelationship;
                          id: string;
                          twilioPhone?: string | null | undefined;
                          displayName: string;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              custodian?:
                | {
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email: string;
                    phone?: string | null | undefined;
                    notifPrefs: Array<string>;
                    admin: boolean;
                    canAccessBi: boolean;
                    promptForCancellationReason: boolean;
                    clinicalCredential?: string | null | undefined;
                    pushToken?: string | null | undefined;
                    availableTransportTypes?:
                      | Array<{
                          fullName: string;
                          id: number;
                          name: string;
                          shortName: string;
                        }>
                      | null
                      | undefined;
                    availableTransportReasons?:
                      | Array<{
                          categoryName: string;
                          id: string;
                          name: string;
                          requireAppointmentTime: boolean;
                          requireComment: boolean;
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              startLocation: {
                id: string;
                name?: string | null | undefined;
                address?: string | null | undefined;
                longitude?: number | null | undefined;
                latitude?: number | null | undefined;
                zipcode?: string | null | undefined;
                instructions?: string | null | undefined;
              };
              endLocation?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              transportReason?:
                | {
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateRiderMutationMutationVariables = Exact<{
  rider: CreateRiderInput;
  riderId: Scalars['String'];
}>;

export type UpdateRiderMutationMutation = {
  updateRider?:
    | {
        error?: string | null | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateRiderPaymentMethodMutationMutationVariables = Exact<{
  paymentMethod: UpdatePaymentMethodInput;
  paymentMethodId: Scalars['String'];
  riderId: Scalars['String'];
}>;

export type UpdateRiderPaymentMethodMutationMutation = {
  updateRiderPaymentMethod?:
    | {
        error?: string | null | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AccountCancellationReasonQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountCancellationReasonQueryQuery = {
  cancellationReasons?:
    | Array<{
        accountId: string;
        cancellationReason: string;
        displayOrder: number;
        id: string;
      }>
    | null
    | undefined;
};

export type AccountCustodiansPaginationQueryQueryVariables = Exact<{
  query: Scalars['String'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<FiltersInput> | FiltersInput>;
}>;

export type AccountCustodiansPaginationQueryQuery = {
  accountCustodiansCollection: {
    collection: Array<{
      id: string;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
      email: string;
      phone?: string | null | undefined;
      notifPrefs: Array<string>;
      admin: boolean;
      canAccessBi: boolean;
      promptForCancellationReason: boolean;
      clinicalCredential?: string | null | undefined;
      pushToken?: string | null | undefined;
      availableTransportTypes?:
        | Array<{
            fullName: string;
            id: number;
            name: string;
            shortName: string;
          }>
        | null
        | undefined;
      availableTransportReasons?:
        | Array<{
            categoryName: string;
            id: string;
            name: string;
            requireAppointmentTime: boolean;
            requireComment: boolean;
          }>
        | null
        | undefined;
    }>;
    metadata: {
      totalPages: number;
      totalCount: number;
      currentPage: number;
      limitValue: number;
    };
  };
};

export type AccountCustodiansQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountCustodiansQueryQuery = {
  accountCustodians?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
        phone?: string | null | undefined;
        notifPrefs: Array<string>;
        admin: boolean;
        canAccessBi: boolean;
        promptForCancellationReason: boolean;
        clinicalCredential?: string | null | undefined;
        pushToken?: string | null | undefined;
        availableTransportTypes?:
          | Array<{
              fullName: string;
              id: number;
              name: string;
              shortName: string;
            }>
          | null
          | undefined;
        availableTransportReasons?:
          | Array<{
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
  profile?: { canInvite: boolean } | null | undefined;
};

export type AccountFinancialResponsibilityChoicesQueryVariables = Exact<{
  accountId?: Maybe<Scalars['String']>;
  payerType: Scalars['String'];
}>;

export type AccountFinancialResponsibilityChoicesQuery = {
  accountFinancialResponsibilityChoices?:
    | Array<{
        costCenter?: string | null | undefined;
        promptForPayerDemographics: boolean;
      }>
    | null
    | undefined;
};

export type AccountLocationsQueryQueryVariables = Exact<{
  transportType?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
}>;

export type AccountLocationsQueryQuery = {
  accountLocations?:
    | Array<{
        id: string;
        name?: string | null | undefined;
        address?: string | null | undefined;
        longitude?: number | null | undefined;
        latitude?: number | null | undefined;
        zipcode?: string | null | undefined;
        instructions?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type AccountQueryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQueryQuery = {
  account?:
    | {
        id: string;
        name?: string | null | undefined;
        creditCents?: number | null | undefined;
        riderCount?: number | null | undefined;
        billingEmail?: string | null | undefined;
        invoiced: boolean;
        firstAvailableBookingEnabled: boolean;
        gridViewEnabled: boolean;
        payerEmailRequired: boolean;
        channel?: string | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
  profile?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
        phone?: string | null | undefined;
        notifPrefs: Array<string>;
        admin: boolean;
        canAccessBi: boolean;
        promptForCancellationReason: boolean;
        clinicalCredential?: string | null | undefined;
        pushToken?: string | null | undefined;
        availableTransportTypes?:
          | Array<{
              fullName: string;
              id: number;
              name: string;
              shortName: string;
            }>
          | null
          | undefined;
        availableTransportReasons?:
          | Array<{
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BatchRidesQueryQueryVariables = Exact<{
  rideId: Scalars['Int'];
}>;

export type BatchRidesQueryQuery = {
  batchRides?:
    | Array<{
        id: string;
        requestedStartTime: any;
        requestedEndTime?: any | null | undefined;
        actualStartTime?: any | null | undefined;
        actualEndTime?: any | null | undefined;
        actualDistanceMiles?: number | null | undefined;
        estimatedDistanceMiles?: number | null | undefined;
        estimatedDurationMinutes?: number | null | undefined;
        estimatedCostCents?: number | null | undefined;
        actualCostCents?: number | null | undefined;
        notes?: string | null | undefined;
        passengerCount?: number | null | undefined;
        rideType: string;
        status: string;
        deliverySubstitution?: string | null | undefined;
        receiptPhotos?: Array<string> | null | undefined;
        custodianReviewedAt?: any | null | undefined;
        stairsCount?: number | null | undefined;
        stairsLabel?: string | null | undefined;
        transportType?: string | null | undefined;
        bariatric?: boolean | null | undefined;
        tzName: string;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        dropoffRoomNumber?: string | null | undefined;
        pickupRoomNumber?: string | null | undefined;
        riderWillUseProvidersWheelchair?: boolean | null | undefined;
        payerType?: string | null | undefined;
        costCenter?: string | null | undefined;
        oxygenRequired?: boolean | null | undefined;
        contactPrecautionsRequired?: boolean | null | undefined;
        estimatedEndTime?: string | null | undefined;
        firstAvailable: boolean;
        rideFeedbackRequestPath?: string | null | undefined;
        rideNewPaymentMethodPath?: string | null | undefined;
        transportReasonComment?: string | null | undefined;
        appointmentTime?: any | null | undefined;
        biddingWindowEndsAt?: any | null | undefined;
        bidRequestedStartTime?: any | null | undefined;
        originalRequestedStartTime?: any | null | undefined;
        bestTimeBidsDeclineBefore?: any | null | undefined;
        arrivalBufferMinutes?: number | null | undefined;
        batchId?: number | null | undefined;
        rideFeedback?:
          | {
              createdAt?: any | null | undefined;
              driverFeedback?: string | null | undefined;
              stars?: number | null | undefined;
            }
          | null
          | undefined;
        medicalNecessityCertificationStatement?:
          | {
              mncsAnswers?:
                | Array<{
                    fieldType: MncsPromptField;
                    group1?: string | null | undefined;
                    group2?: string | null | undefined;
                    name?: string | null | undefined;
                    notes?: string | null | undefined;
                    slug: string;
                    sortOrder?: number | null | undefined;
                    value?: string | null | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        bedsideNurse?: { fullName: string; phone: string } | null | undefined;
        payer?:
          | {
              id: string;
              firstName: string;
              lastName: string;
              phone: string;
              email?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              currentPaymentMethod?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        paymentMethod?:
          | {
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }
          | null
          | undefined;
        driver?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              twilioPhone?: string | null | undefined;
              bio?: string | null | undefined;
              isPlaceholder: boolean;
              transportPartner?:
                | {
                    availableTransportTypes?: string | null | undefined;
                    createdAt: any;
                    id: string;
                    name?: string | null | undefined;
                    stripeDashboardUrl?: string | null | undefined;
                    updatedAt?: any | null | undefined;
                  }
                | null
                | undefined;
              currentVehicle?:
                | {
                    id: string;
                    color?: string | null | undefined;
                    year?: string | null | undefined;
                    make?: string | null | undefined;
                    model?: string | null | undefined;
                    licensePlate?: string | null | undefined;
                    vehicleType?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        custodian?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              email: string;
              phone?: string | null | undefined;
              notifPrefs: Array<string>;
              admin: boolean;
              canAccessBi: boolean;
              promptForCancellationReason: boolean;
              clinicalCredential?: string | null | undefined;
              pushToken?: string | null | undefined;
              availableTransportTypes?:
                | Array<{
                    fullName: string;
                    id: number;
                    name: string;
                    shortName: string;
                  }>
                | null
                | undefined;
              availableTransportReasons?:
                | Array<{
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        startLocation: {
          id: string;
          name?: string | null | undefined;
          address?: string | null | undefined;
          longitude?: number | null | undefined;
          latitude?: number | null | undefined;
          zipcode?: string | null | undefined;
          instructions?: string | null | undefined;
        };
        endLocation?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        transportReason?:
          | {
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  account?:
    | {
        id: string;
        name?: string | null | undefined;
        creditCents?: number | null | undefined;
        riderCount?: number | null | undefined;
        billingEmail?: string | null | undefined;
        invoiced: boolean;
        firstAvailableBookingEnabled: boolean;
        gridViewEnabled: boolean;
        payerEmailRequired: boolean;
        channel?: string | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CustodiansQueryQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type CustodiansQueryQuery = {
  custodians?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
      }>
    | null
    | undefined;
};

export type DashboardQueryQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardQueryQuery = {
  featureFlags?: Array<string> | null | undefined;
  profile?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
        phone?: string | null | undefined;
        notifPrefs: Array<string>;
        admin: boolean;
        canAccessBi: boolean;
        promptForCancellationReason: boolean;
        clinicalCredential?: string | null | undefined;
        pushToken?: string | null | undefined;
        availableTransportTypes?:
          | Array<{
              fullName: string;
              id: number;
              name: string;
              shortName: string;
            }>
          | null
          | undefined;
        availableTransportReasons?:
          | Array<{
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
  account?:
    | {
        id: string;
        name?: string | null | undefined;
        creditCents?: number | null | undefined;
        riderCount?: number | null | undefined;
        billingEmail?: string | null | undefined;
        invoiced: boolean;
        firstAvailableBookingEnabled: boolean;
        gridViewEnabled: boolean;
        payerEmailRequired: boolean;
        channel?: string | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
  upcomingRides?:
    | Array<{
        id: string;
        requestedStartTime: any;
        requestedEndTime?: any | null | undefined;
        actualStartTime?: any | null | undefined;
        actualEndTime?: any | null | undefined;
        estimatedDistanceMiles?: number | null | undefined;
        estimatedDurationMinutes?: number | null | undefined;
        estimatedCostCents?: number | null | undefined;
        actualCostCents?: number | null | undefined;
        rideType: string;
        status: string;
        tzName: string;
        transportType?: string | null | undefined;
        custodian?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              email: string;
              phone?: string | null | undefined;
              notifPrefs: Array<string>;
              admin: boolean;
              canAccessBi: boolean;
              promptForCancellationReason: boolean;
              clinicalCredential?: string | null | undefined;
              pushToken?: string | null | undefined;
              availableTransportTypes?:
                | Array<{
                    fullName: string;
                    id: number;
                    name: string;
                    shortName: string;
                  }>
                | null
                | undefined;
              availableTransportReasons?:
                | Array<{
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        driver?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              profilePhoto?: string | null | undefined;
            }
          | null
          | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              profilePhoto?: string | null | undefined;
            }
          | null
          | undefined;
        startLocation: {
          id: string;
          name?: string | null | undefined;
          address?: string | null | undefined;
        };
        endLocation?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  riders?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type TransportTypeOptionsQueryQueryVariables = Exact<{
  transportType: Scalars['String'];
}>;

export type TransportTypeOptionsQueryQuery = {
  rideTransportTypeOptions: {
    contactPrecautionsRequired: boolean;
    oxygenRequired: boolean;
    promptForMncs: boolean;
    promptForBedsideNurseContact: boolean;
  };
};

export type LanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type LanguagesQuery = {
  languages: Array<{ displayName: string; id: string; sortOrder: number }>;
};

export type ProfileQueryQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQueryQuery = {
  profile?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email: string;
        phone?: string | null | undefined;
        notifPrefs: Array<string>;
        admin: boolean;
        canAccessBi: boolean;
        promptForCancellationReason: boolean;
        clinicalCredential?: string | null | undefined;
        pushToken?: string | null | undefined;
        availableTransportTypes?:
          | Array<{
              fullName: string;
              id: number;
              name: string;
              shortName: string;
            }>
          | null
          | undefined;
        availableTransportReasons?:
          | Array<{
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RecentDriverQueryQueryVariables = Exact<{
  riderId?: Maybe<Scalars['String']>;
}>;

export type RecentDriverQueryQuery = {
  recentDrivers?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        profilePhoto?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type RecentLocationsQueryQueryVariables = Exact<{
  riderId?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
}>;

export type RecentLocationsQueryQuery = {
  recentLocations?:
    | Array<{
        id: string;
        name?: string | null | undefined;
        address?: string | null | undefined;
        longitude?: number | null | undefined;
        latitude?: number | null | undefined;
        zipcode?: string | null | undefined;
        instructions?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type RideEstimateQueryVariables = Exact<{
  ride: CreateRideInput;
}>;

export type RideEstimateQuery = {
  rideEstimate?:
    | {
        estimatedCostFormatted: string;
        estimatedEndTime?: string | null | undefined;
        estimatedDurationMinutesNoRound?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RideHistoryDetailsQueryQueryVariables = Exact<{
  rideId: Scalars['Int'];
}>;

export type RideHistoryDetailsQueryQuery = {
  ride?:
    | {
        id: string;
        actualStartTime?: any | null | undefined;
        actualEndTime?: any | null | undefined;
        actualCostCents?: number | null | undefined;
        receiptPhotos?: Array<string> | null | undefined;
        status: string;
        tzName: string;
        startLocation: {
          id: string;
          name?: string | null | undefined;
          address?: string | null | undefined;
          longitude?: number | null | undefined;
          latitude?: number | null | undefined;
          zipcode?: string | null | undefined;
          instructions?: string | null | undefined;
        };
        endLocation?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        rideCharges?:
          | Array<{
              id: string;
              amountCents: number;
              airportCents?: number | null | undefined;
              cancelledCents?: number | null | undefined;
              creditCents?: number | null | undefined;
              deliveryCents?: number | null | undefined;
              deliveryFeeCents?: number | null | undefined;
              holidayCents?: number | null | undefined;
              lastMinuteCents?: number | null | undefined;
              mileageCents?: number | null | undefined;
              oneOffCents?: number | null | undefined;
              parkingCents?: number | null | undefined;
              timeCents?: number | null | undefined;
              timeOfDayCents?: number | null | undefined;
              tipCents?: number | null | undefined;
              tollsCents?: number | null | undefined;
              status: string;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RideHistoryPaginatedQueryQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;

export type RideHistoryPaginatedQueryQuery = {
  rideHistoryPaginated?:
    | {
        hasMore: boolean;
        id: string;
        rides: Array<{
          id: string;
          requestedStartTime: any;
          actualStartTime?: any | null | undefined;
          actualEndTime?: any | null | undefined;
          actualCostCents?: number | null | undefined;
          actualDistanceMiles?: number | null | undefined;
          estimatedDistanceMiles?: number | null | undefined;
          status: string;
          rideType: string;
          tzName: string;
          transportType?: string | null | undefined;
          startLocation: { address?: string | null | undefined };
          endLocation?:
            | { address?: string | null | undefined }
            | null
            | undefined;
          rider?:
            | {
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
              }
            | null
            | undefined;
          driver?:
            | {
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type RideMncsPromptsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RideMncsPromptsQueryQuery = {
  rideMncsPrompts?:
    | Array<{
        fieldType: MncsPromptField;
        group1?: string | null | undefined;
        group2: string;
        name?: string | null | undefined;
        notes?: string | null | undefined;
        slug: string;
      }>
    | null
    | undefined;
};

export type RideNotesPlaceholderTextQueryQueryVariables = Exact<{
  transportType: Scalars['String'];
}>;

export type RideNotesPlaceholderTextQueryQuery = {
  rideNotesPlaceholderText?: string | null | undefined;
};

export type RidesPaginationQueryQueryVariables = Exact<{
  query: Scalars['String'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  scope?: Maybe<RidesCollectionScope>;
  filters?: Maybe<Array<FiltersInput> | FiltersInput>;
}>;

export type RidesPaginationQueryQuery = {
  ridesCollection: {
    collection: Array<{
      id: string;
      requestedStartTime: any;
      requestedEndTime?: any | null | undefined;
      actualStartTime?: any | null | undefined;
      actualEndTime?: any | null | undefined;
      actualDistanceMiles?: number | null | undefined;
      estimatedDistanceMiles?: number | null | undefined;
      estimatedDurationMinutes?: number | null | undefined;
      estimatedCostCents?: number | null | undefined;
      actualCostCents?: number | null | undefined;
      notes?: string | null | undefined;
      passengerCount?: number | null | undefined;
      rideType: string;
      status: string;
      deliverySubstitution?: string | null | undefined;
      receiptPhotos?: Array<string> | null | undefined;
      custodianReviewedAt?: any | null | undefined;
      stairsCount?: number | null | undefined;
      stairsLabel?: string | null | undefined;
      transportType?: string | null | undefined;
      bariatric?: boolean | null | undefined;
      tzName: string;
      heightInches?: number | null | undefined;
      weightLbs?: number | null | undefined;
      dropoffRoomNumber?: string | null | undefined;
      pickupRoomNumber?: string | null | undefined;
      riderWillUseProvidersWheelchair?: boolean | null | undefined;
      payerType?: string | null | undefined;
      costCenter?: string | null | undefined;
      oxygenRequired?: boolean | null | undefined;
      contactPrecautionsRequired?: boolean | null | undefined;
      estimatedEndTime?: string | null | undefined;
      firstAvailable: boolean;
      rideFeedbackRequestPath?: string | null | undefined;
      rideNewPaymentMethodPath?: string | null | undefined;
      transportReasonComment?: string | null | undefined;
      appointmentTime?: any | null | undefined;
      biddingWindowEndsAt?: any | null | undefined;
      bidRequestedStartTime?: any | null | undefined;
      originalRequestedStartTime?: any | null | undefined;
      bestTimeBidsDeclineBefore?: any | null | undefined;
      arrivalBufferMinutes?: number | null | undefined;
      batchId?: number | null | undefined;
      rideFeedback?:
        | {
            createdAt?: any | null | undefined;
            driverFeedback?: string | null | undefined;
            stars?: number | null | undefined;
          }
        | null
        | undefined;
      medicalNecessityCertificationStatement?:
        | {
            mncsAnswers?:
              | Array<{
                  fieldType: MncsPromptField;
                  group1?: string | null | undefined;
                  group2?: string | null | undefined;
                  name?: string | null | undefined;
                  notes?: string | null | undefined;
                  slug: string;
                  sortOrder?: number | null | undefined;
                  value?: string | null | undefined;
                }>
              | null
              | undefined;
          }
        | null
        | undefined;
      bedsideNurse?: { fullName: string; phone: string } | null | undefined;
      payer?:
        | {
            id: string;
            firstName: string;
            lastName: string;
            phone: string;
            email?: string | null | undefined;
            address?:
              | {
                  id: string;
                  name?: string | null | undefined;
                  address?: string | null | undefined;
                  longitude?: number | null | undefined;
                  latitude?: number | null | undefined;
                  zipcode?: string | null | undefined;
                  instructions?: string | null | undefined;
                }
              | null
              | undefined;
            currentPaymentMethod?:
              | {
                  id: string;
                  name?: string | null | undefined;
                  cardLast4?: string | null | undefined;
                  cardBrand?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      paymentMethod?:
        | {
            id: string;
            name?: string | null | undefined;
            cardLast4?: string | null | undefined;
            cardBrand?: string | null | undefined;
          }
        | null
        | undefined;
      driver?:
        | {
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            profilePhoto?: string | null | undefined;
            twilioPhone?: string | null | undefined;
            bio?: string | null | undefined;
            isPlaceholder: boolean;
            transportPartner?:
              | {
                  availableTransportTypes?: string | null | undefined;
                  createdAt: any;
                  id: string;
                  name?: string | null | undefined;
                  stripeDashboardUrl?: string | null | undefined;
                  updatedAt?: any | null | undefined;
                }
              | null
              | undefined;
            currentVehicle?:
              | {
                  id: string;
                  color?: string | null | undefined;
                  year?: string | null | undefined;
                  make?: string | null | undefined;
                  model?: string | null | undefined;
                  licensePlate?: string | null | undefined;
                  vehicleType?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      rider?:
        | {
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            phone?: string | null | undefined;
            email?: string | null | undefined;
            notifPrefs?: Array<string> | null | undefined;
            notes?: string | null | undefined;
            canMakeChanges?: boolean | null | undefined;
            mobilityAssistance?: string | null | undefined;
            visionImpairment?: string | null | undefined;
            hearingImpairment?: string | null | undefined;
            cognitiveImpairment?: string | null | undefined;
            profilePhoto?: string | null | undefined;
            dob?: string | null | undefined;
            heightInches?: number | null | undefined;
            weightLbs?: number | null | undefined;
            gender?: PersonGender | null | undefined;
            languageId?: string | null | undefined;
            address?:
              | {
                  id: string;
                  name?: string | null | undefined;
                  address?: string | null | undefined;
                  longitude?: number | null | undefined;
                  latitude?: number | null | undefined;
                  zipcode?: string | null | undefined;
                  instructions?: string | null | undefined;
                }
              | null
              | undefined;
            paymentMethods?:
              | Array<{
                  id: string;
                  name?: string | null | undefined;
                  cardLast4?: string | null | undefined;
                  cardBrand?: string | null | undefined;
                }>
              | null
              | undefined;
            carePartner?:
              | {
                  email?: string | null | undefined;
                  firstName: string;
                  lastName: string;
                  notifPrefs?: Array<string> | null | undefined;
                  phone: string;
                  relationshipNotes?: string | null | undefined;
                  relationshipType: CarePartnerRelationship;
                  id: string;
                  twilioPhone?: string | null | undefined;
                  displayName: string;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      custodian?:
        | {
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            email: string;
            phone?: string | null | undefined;
            notifPrefs: Array<string>;
            admin: boolean;
            canAccessBi: boolean;
            promptForCancellationReason: boolean;
            clinicalCredential?: string | null | undefined;
            pushToken?: string | null | undefined;
            availableTransportTypes?:
              | Array<{
                  fullName: string;
                  id: number;
                  name: string;
                  shortName: string;
                }>
              | null
              | undefined;
            availableTransportReasons?:
              | Array<{
                  categoryName: string;
                  id: string;
                  name: string;
                  requireAppointmentTime: boolean;
                  requireComment: boolean;
                }>
              | null
              | undefined;
          }
        | null
        | undefined;
      startLocation: {
        id: string;
        name?: string | null | undefined;
        address?: string | null | undefined;
        longitude?: number | null | undefined;
        latitude?: number | null | undefined;
        zipcode?: string | null | undefined;
        instructions?: string | null | undefined;
      };
      endLocation?:
        | {
            id: string;
            name?: string | null | undefined;
            address?: string | null | undefined;
            longitude?: number | null | undefined;
            latitude?: number | null | undefined;
            zipcode?: string | null | undefined;
            instructions?: string | null | undefined;
          }
        | null
        | undefined;
      transportReason?:
        | {
            categoryName: string;
            id: string;
            name: string;
            requireAppointmentTime: boolean;
            requireComment: boolean;
          }
        | null
        | undefined;
    }>;
    metadata: {
      totalPages: number;
      totalCount: number;
      currentPage: number;
      limitValue: number;
    };
  };
};

export type RideQueryQueryVariables = Exact<{
  rideId: Scalars['Int'];
}>;

export type RideQueryQuery = {
  ride?:
    | {
        id: string;
        requestedStartTime: any;
        requestedEndTime?: any | null | undefined;
        actualStartTime?: any | null | undefined;
        actualEndTime?: any | null | undefined;
        actualDistanceMiles?: number | null | undefined;
        estimatedDistanceMiles?: number | null | undefined;
        estimatedDurationMinutes?: number | null | undefined;
        estimatedCostCents?: number | null | undefined;
        actualCostCents?: number | null | undefined;
        notes?: string | null | undefined;
        passengerCount?: number | null | undefined;
        rideType: string;
        status: string;
        deliverySubstitution?: string | null | undefined;
        receiptPhotos?: Array<string> | null | undefined;
        custodianReviewedAt?: any | null | undefined;
        stairsCount?: number | null | undefined;
        stairsLabel?: string | null | undefined;
        transportType?: string | null | undefined;
        bariatric?: boolean | null | undefined;
        tzName: string;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        dropoffRoomNumber?: string | null | undefined;
        pickupRoomNumber?: string | null | undefined;
        riderWillUseProvidersWheelchair?: boolean | null | undefined;
        payerType?: string | null | undefined;
        costCenter?: string | null | undefined;
        oxygenRequired?: boolean | null | undefined;
        contactPrecautionsRequired?: boolean | null | undefined;
        estimatedEndTime?: string | null | undefined;
        firstAvailable: boolean;
        rideFeedbackRequestPath?: string | null | undefined;
        rideNewPaymentMethodPath?: string | null | undefined;
        transportReasonComment?: string | null | undefined;
        appointmentTime?: any | null | undefined;
        biddingWindowEndsAt?: any | null | undefined;
        bidRequestedStartTime?: any | null | undefined;
        originalRequestedStartTime?: any | null | undefined;
        bestTimeBidsDeclineBefore?: any | null | undefined;
        arrivalBufferMinutes?: number | null | undefined;
        batchId?: number | null | undefined;
        rideFeedback?:
          | {
              createdAt?: any | null | undefined;
              driverFeedback?: string | null | undefined;
              stars?: number | null | undefined;
            }
          | null
          | undefined;
        medicalNecessityCertificationStatement?:
          | {
              mncsAnswers?:
                | Array<{
                    fieldType: MncsPromptField;
                    group1?: string | null | undefined;
                    group2?: string | null | undefined;
                    name?: string | null | undefined;
                    notes?: string | null | undefined;
                    slug: string;
                    sortOrder?: number | null | undefined;
                    value?: string | null | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        bedsideNurse?: { fullName: string; phone: string } | null | undefined;
        payer?:
          | {
              id: string;
              firstName: string;
              lastName: string;
              phone: string;
              email?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              currentPaymentMethod?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        paymentMethod?:
          | {
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }
          | null
          | undefined;
        driver?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              twilioPhone?: string | null | undefined;
              bio?: string | null | undefined;
              isPlaceholder: boolean;
              transportPartner?:
                | {
                    availableTransportTypes?: string | null | undefined;
                    createdAt: any;
                    id: string;
                    name?: string | null | undefined;
                    stripeDashboardUrl?: string | null | undefined;
                    updatedAt?: any | null | undefined;
                  }
                | null
                | undefined;
              currentVehicle?:
                | {
                    id: string;
                    color?: string | null | undefined;
                    year?: string | null | undefined;
                    make?: string | null | undefined;
                    model?: string | null | undefined;
                    licensePlate?: string | null | undefined;
                    vehicleType?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        custodian?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              email: string;
              phone?: string | null | undefined;
              notifPrefs: Array<string>;
              admin: boolean;
              canAccessBi: boolean;
              promptForCancellationReason: boolean;
              clinicalCredential?: string | null | undefined;
              pushToken?: string | null | undefined;
              availableTransportTypes?:
                | Array<{
                    fullName: string;
                    id: number;
                    name: string;
                    shortName: string;
                  }>
                | null
                | undefined;
              availableTransportReasons?:
                | Array<{
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        startLocation: {
          id: string;
          name?: string | null | undefined;
          address?: string | null | undefined;
          longitude?: number | null | undefined;
          latitude?: number | null | undefined;
          zipcode?: string | null | undefined;
          instructions?: string | null | undefined;
        };
        endLocation?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        transportReason?:
          | {
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  account?:
    | {
        id: string;
        name?: string | null | undefined;
        creditCents?: number | null | undefined;
        riderCount?: number | null | undefined;
        billingEmail?: string | null | undefined;
        invoiced: boolean;
        firstAvailableBookingEnabled: boolean;
        gridViewEnabled: boolean;
        payerEmailRequired: boolean;
        channel?: string | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RidersPaginationQueryQueryVariables = Exact<{
  query: Scalars['String'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
  sortBy?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
}>;

export type RidersPaginationQueryQuery = {
  ridersCollection: {
    collection: Array<{
      id: string;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
      phone?: string | null | undefined;
      email?: string | null | undefined;
      notifPrefs?: Array<string> | null | undefined;
      notes?: string | null | undefined;
      canMakeChanges?: boolean | null | undefined;
      mobilityAssistance?: string | null | undefined;
      visionImpairment?: string | null | undefined;
      hearingImpairment?: string | null | undefined;
      cognitiveImpairment?: string | null | undefined;
      profilePhoto?: string | null | undefined;
      gender?: PersonGender | null | undefined;
      dob?: string | null | undefined;
      paymentMethods?:
        | Array<{
            id: string;
            name?: string | null | undefined;
            cardLast4?: string | null | undefined;
            cardBrand?: string | null | undefined;
          }>
        | null
        | undefined;
    }>;
    metadata: {
      totalPages: number;
      totalCount: number;
      currentPage: number;
      limitValue: number;
    };
  };
};

export type RiderProfileQueryQueryVariables = Exact<{
  riderId?: Maybe<Scalars['String']>;
}>;

export type RiderProfileQueryQuery = {
  rider?:
    | {
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        phone?: string | null | undefined;
        email?: string | null | undefined;
        notifPrefs?: Array<string> | null | undefined;
        notes?: string | null | undefined;
        canMakeChanges?: boolean | null | undefined;
        mobilityAssistance?: string | null | undefined;
        visionImpairment?: string | null | undefined;
        hearingImpairment?: string | null | undefined;
        cognitiveImpairment?: string | null | undefined;
        profilePhoto?: string | null | undefined;
        dob?: string | null | undefined;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        gender?: PersonGender | null | undefined;
        languageId?: string | null | undefined;
        address?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
        carePartner?:
          | {
              email?: string | null | undefined;
              firstName: string;
              lastName: string;
              notifPrefs?: Array<string> | null | undefined;
              phone: string;
              relationshipNotes?: string | null | undefined;
              relationshipType: CarePartnerRelationship;
              id: string;
              twilioPhone?: string | null | undefined;
              displayName: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  favoriteDrivers?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        profilePhoto?: string | null | undefined;
        twilioPhone?: string | null | undefined;
        bio?: string | null | undefined;
        isPlaceholder: boolean;
        transportPartner?:
          | {
              availableTransportTypes?: string | null | undefined;
              createdAt: any;
              id: string;
              name?: string | null | undefined;
              stripeDashboardUrl?: string | null | undefined;
              updatedAt?: any | null | undefined;
            }
          | null
          | undefined;
        currentVehicle?:
          | {
              id: string;
              color?: string | null | undefined;
              year?: string | null | undefined;
              make?: string | null | undefined;
              model?: string | null | undefined;
              licensePlate?: string | null | undefined;
              vehicleType?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  account?: { billingEmail?: string | null | undefined } | null | undefined;
  profile?: { email: string } | null | undefined;
};

export type RidersQueryQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;

export type RidersQueryQuery = {
  riders?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        phone?: string | null | undefined;
        email?: string | null | undefined;
        notifPrefs?: Array<string> | null | undefined;
        notes?: string | null | undefined;
        canMakeChanges?: boolean | null | undefined;
        mobilityAssistance?: string | null | undefined;
        visionImpairment?: string | null | undefined;
        hearingImpairment?: string | null | undefined;
        cognitiveImpairment?: string | null | undefined;
        profilePhoto?: string | null | undefined;
        dob?: string | null | undefined;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        gender?: PersonGender | null | undefined;
        languageId?: string | null | undefined;
        address?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
        carePartner?:
          | {
              email?: string | null | undefined;
              firstName: string;
              lastName: string;
              notifPrefs?: Array<string> | null | undefined;
              phone: string;
              relationshipNotes?: string | null | undefined;
              relationshipType: CarePartnerRelationship;
              id: string;
              twilioPhone?: string | null | undefined;
              displayName: string;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type RidersSimilarQueryQueryVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dob?: Maybe<Scalars['String']>;
}>;

export type RidersSimilarQueryQuery = {
  ridersSimilar?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        phone?: string | null | undefined;
        email?: string | null | undefined;
        dob?: string | null | undefined;
        historyRidesCount?: number | null | undefined;
        upcomingRidesCount?: number | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type RidesLastModifiedAtQueryQueryVariables = Exact<{
  scope?: Maybe<RidesCollectionScope>;
}>;

export type RidesLastModifiedAtQueryQuery = { ridesLastModifiedAt: number };

export type ScheduleQueryQueryVariables = Exact<{
  riderId?: Maybe<Scalars['Int']>;
}>;

export type ScheduleQueryQuery = {
  riders?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        phone?: string | null | undefined;
        email?: string | null | undefined;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        lastPaymentMethodId?: string | null | undefined;
        lastPaymentInvoiced?: boolean | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
        address?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  profile?:
    | {
        email: string;
        lastGeoLatitude?: number | null | undefined;
        lastGeoLongitude?: number | null | undefined;
        admin: boolean;
        promptForFinancialResponsibility: boolean;
        promptForRoomNumbers: boolean;
        availableTransportTypes?:
          | Array<{
              fullName: string;
              id: number;
              name: string;
              shortName: string;
            }>
          | null
          | undefined;
        availablePayerTypes?:
          | Array<{ name: string; promptForPayerDemographics: boolean }>
          | null
          | undefined;
      }
    | null
    | undefined;
  account?:
    | {
        id: string;
        name?: string | null | undefined;
        creditCents?: number | null | undefined;
        riderCount?: number | null | undefined;
        billingEmail?: string | null | undefined;
        invoiced: boolean;
        firstAvailableBookingEnabled: boolean;
        gridViewEnabled: boolean;
        payerEmailRequired: boolean;
        channel?: string | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ScheduleQueryWithRideQueryVariables = Exact<{
  rideId: Scalars['Int'];
  riderId?: Maybe<Scalars['Int']>;
}>;

export type ScheduleQueryWithRideQuery = {
  ride?:
    | {
        id: string;
        requestedStartTime: any;
        requestedEndTime?: any | null | undefined;
        actualStartTime?: any | null | undefined;
        actualEndTime?: any | null | undefined;
        actualDistanceMiles?: number | null | undefined;
        estimatedDistanceMiles?: number | null | undefined;
        estimatedDurationMinutes?: number | null | undefined;
        estimatedCostCents?: number | null | undefined;
        actualCostCents?: number | null | undefined;
        notes?: string | null | undefined;
        passengerCount?: number | null | undefined;
        rideType: string;
        status: string;
        deliverySubstitution?: string | null | undefined;
        receiptPhotos?: Array<string> | null | undefined;
        custodianReviewedAt?: any | null | undefined;
        stairsCount?: number | null | undefined;
        stairsLabel?: string | null | undefined;
        transportType?: string | null | undefined;
        bariatric?: boolean | null | undefined;
        tzName: string;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        dropoffRoomNumber?: string | null | undefined;
        pickupRoomNumber?: string | null | undefined;
        riderWillUseProvidersWheelchair?: boolean | null | undefined;
        payerType?: string | null | undefined;
        costCenter?: string | null | undefined;
        oxygenRequired?: boolean | null | undefined;
        contactPrecautionsRequired?: boolean | null | undefined;
        estimatedEndTime?: string | null | undefined;
        firstAvailable: boolean;
        rideFeedbackRequestPath?: string | null | undefined;
        rideNewPaymentMethodPath?: string | null | undefined;
        transportReasonComment?: string | null | undefined;
        appointmentTime?: any | null | undefined;
        biddingWindowEndsAt?: any | null | undefined;
        bidRequestedStartTime?: any | null | undefined;
        originalRequestedStartTime?: any | null | undefined;
        bestTimeBidsDeclineBefore?: any | null | undefined;
        arrivalBufferMinutes?: number | null | undefined;
        batchId?: number | null | undefined;
        rideFeedback?:
          | {
              createdAt?: any | null | undefined;
              driverFeedback?: string | null | undefined;
              stars?: number | null | undefined;
            }
          | null
          | undefined;
        medicalNecessityCertificationStatement?:
          | {
              mncsAnswers?:
                | Array<{
                    fieldType: MncsPromptField;
                    group1?: string | null | undefined;
                    group2?: string | null | undefined;
                    name?: string | null | undefined;
                    notes?: string | null | undefined;
                    slug: string;
                    sortOrder?: number | null | undefined;
                    value?: string | null | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        bedsideNurse?: { fullName: string; phone: string } | null | undefined;
        payer?:
          | {
              id: string;
              firstName: string;
              lastName: string;
              phone: string;
              email?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              currentPaymentMethod?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        paymentMethod?:
          | {
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }
          | null
          | undefined;
        driver?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              twilioPhone?: string | null | undefined;
              bio?: string | null | undefined;
              isPlaceholder: boolean;
              transportPartner?:
                | {
                    availableTransportTypes?: string | null | undefined;
                    createdAt: any;
                    id: string;
                    name?: string | null | undefined;
                    stripeDashboardUrl?: string | null | undefined;
                    updatedAt?: any | null | undefined;
                  }
                | null
                | undefined;
              currentVehicle?:
                | {
                    id: string;
                    color?: string | null | undefined;
                    year?: string | null | undefined;
                    make?: string | null | undefined;
                    model?: string | null | undefined;
                    licensePlate?: string | null | undefined;
                    vehicleType?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        custodian?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              email: string;
              phone?: string | null | undefined;
              notifPrefs: Array<string>;
              admin: boolean;
              canAccessBi: boolean;
              promptForCancellationReason: boolean;
              clinicalCredential?: string | null | undefined;
              pushToken?: string | null | undefined;
              availableTransportTypes?:
                | Array<{
                    fullName: string;
                    id: number;
                    name: string;
                    shortName: string;
                  }>
                | null
                | undefined;
              availableTransportReasons?:
                | Array<{
                    categoryName: string;
                    id: string;
                    name: string;
                    requireAppointmentTime: boolean;
                    requireComment: boolean;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        startLocation: {
          id: string;
          name?: string | null | undefined;
          address?: string | null | undefined;
          longitude?: number | null | undefined;
          latitude?: number | null | undefined;
          zipcode?: string | null | undefined;
          instructions?: string | null | undefined;
        };
        endLocation?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
        transportReason?:
          | {
              categoryName: string;
              id: string;
              name: string;
              requireAppointmentTime: boolean;
              requireComment: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  riders?:
    | Array<{
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        phone?: string | null | undefined;
        email?: string | null | undefined;
        heightInches?: number | null | undefined;
        weightLbs?: number | null | undefined;
        lastPaymentMethodId?: string | null | undefined;
        lastPaymentInvoiced?: boolean | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
        address?:
          | {
              id: string;
              name?: string | null | undefined;
              address?: string | null | undefined;
              longitude?: number | null | undefined;
              latitude?: number | null | undefined;
              zipcode?: string | null | undefined;
              instructions?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  profile?:
    | {
        admin: boolean;
        email: string;
        lastGeoLatitude?: number | null | undefined;
        lastGeoLongitude?: number | null | undefined;
        promptForFinancialResponsibility: boolean;
        promptForRoomNumbers: boolean;
        availableTransportTypes?:
          | Array<{
              fullName: string;
              id: number;
              name: string;
              shortName: string;
            }>
          | null
          | undefined;
        availablePayerTypes?:
          | Array<{ name: string; promptForPayerDemographics: boolean }>
          | null
          | undefined;
      }
    | null
    | undefined;
  account?:
    | {
        id: string;
        name?: string | null | undefined;
        creditCents?: number | null | undefined;
        riderCount?: number | null | undefined;
        billingEmail?: string | null | undefined;
        invoiced: boolean;
        firstAvailableBookingEnabled: boolean;
        gridViewEnabled: boolean;
        payerEmailRequired: boolean;
        channel?: string | null | undefined;
        paymentMethods?:
          | Array<{
              id: string;
              name?: string | null | undefined;
              cardLast4?: string | null | undefined;
              cardBrand?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type StripeSetupIntentQueryQueryVariables = Exact<{
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
}>;

export type StripeSetupIntentQueryQuery = {
  stripeSetupIntent?:
    | {
        setupIntent: string;
        ephemeralKey?: string | null | undefined;
        customer: string;
        publishableKey: string;
      }
    | null
    | undefined;
};

export type VetrideQueryQueryVariables = Exact<{
  vetrideRequestId: Scalars['Int'];
}>;

export type VetrideQueryQuery = {
  vetrideRequest?:
    | {
        newRide?:
          | {
              requestedStartTime: any;
              notes?: string | null | undefined;
              rideType: string;
              status: string;
              transportType?: string | null | undefined;
              tzName: string;
              firstAvailable: boolean;
              arrivalBufferMinutes?: number | null | undefined;
              startLocation: {
                id: string;
                name?: string | null | undefined;
                address?: string | null | undefined;
                longitude?: number | null | undefined;
                latitude?: number | null | undefined;
                zipcode?: string | null | undefined;
                instructions?: string | null | undefined;
              };
              endLocation?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        rider?:
          | {
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              phone?: string | null | undefined;
              email?: string | null | undefined;
              notifPrefs?: Array<string> | null | undefined;
              notes?: string | null | undefined;
              canMakeChanges?: boolean | null | undefined;
              mobilityAssistance?: string | null | undefined;
              visionImpairment?: string | null | undefined;
              hearingImpairment?: string | null | undefined;
              cognitiveImpairment?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              dob?: string | null | undefined;
              heightInches?: number | null | undefined;
              weightLbs?: number | null | undefined;
              gender?: PersonGender | null | undefined;
              languageId?: string | null | undefined;
              address?:
                | {
                    id: string;
                    name?: string | null | undefined;
                    address?: string | null | undefined;
                    longitude?: number | null | undefined;
                    latitude?: number | null | undefined;
                    zipcode?: string | null | undefined;
                    instructions?: string | null | undefined;
                  }
                | null
                | undefined;
              paymentMethods?:
                | Array<{
                    id: string;
                    name?: string | null | undefined;
                    cardLast4?: string | null | undefined;
                    cardBrand?: string | null | undefined;
                  }>
                | null
                | undefined;
              carePartner?:
                | {
                    email?: string | null | undefined;
                    firstName: string;
                    lastName: string;
                    notifPrefs?: Array<string> | null | undefined;
                    phone: string;
                    relationshipNotes?: string | null | undefined;
                    relationshipType: CarePartnerRelationship;
                    id: string;
                    twilioPhone?: string | null | undefined;
                    displayName: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const PaymentMethodFieldsFragmentDoc = gql`
  fragment PaymentMethodFields on PaymentMethod {
    id
    name
    cardLast4
    cardBrand
  }
`;
export const AccountFieldsFragmentDoc = gql`
  fragment AccountFields on Account {
    id
    name
    creditCents
    riderCount
    billingEmail
    invoiced
    firstAvailableBookingEnabled
    gridViewEnabled
    payerEmailRequired
    channel
    paymentMethods {
      ...PaymentMethodFields
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;
export const DashboardRideFieldsFragmentDoc = gql`
  fragment DashboardRideFields on Ride {
    id
    requestedStartTime
    requestedEndTime
    actualStartTime
    actualEndTime
    estimatedDistanceMiles
    estimatedDurationMinutes
    estimatedCostCents
    actualCostCents
    rideType
    status
    tzName
    driver {
      id
      firstName
      lastName
      profilePhoto
    }
    rider {
      id
      firstName
      lastName
      profilePhoto
    }
    startLocation {
      id
      name
      address
    }
    endLocation {
      id
      name
      address
    }
  }
`;
export const LocationFieldsFragmentDoc = gql`
  fragment LocationFields on Location {
    id
    name
    address
    longitude
    latitude
    zipcode
    instructions
  }
`;
export const NewRideFieldsFragmentDoc = gql`
  fragment NewRideFields on NewRide {
    requestedStartTime
    notes
    rideType
    status
    transportType
    tzName
    firstAvailable
    arrivalBufferMinutes
    startLocation {
      ...LocationFields
    }
    endLocation {
      ...LocationFields
    }
  }
  ${LocationFieldsFragmentDoc}
`;
export const RideChargeFieldsFragmentDoc = gql`
  fragment RideChargeFields on RideCharge {
    id
    amountCents
    airportCents
    cancelledCents
    creditCents
    deliveryCents
    deliveryFeeCents
    holidayCents
    lastMinuteCents
    mileageCents
    oneOffCents
    parkingCents
    timeCents
    timeOfDayCents
    tipCents
    tollsCents
    status
  }
`;
export const TransportPartnerFieldsFragmentDoc = gql`
  fragment TransportPartnerFields on TransportPartner {
    availableTransportTypes
    createdAt
    id
    name
    stripeDashboardUrl
    updatedAt
  }
`;
export const VehicleFieldsFragmentDoc = gql`
  fragment VehicleFields on Vehicle {
    id
    color
    year
    make
    model
    licensePlate
    vehicleType
  }
`;
export const DriverFieldsFragmentDoc = gql`
  fragment DriverFields on Driver {
    id
    firstName
    lastName
    profilePhoto
    twilioPhone
    bio
    isPlaceholder
    transportPartner {
      ...TransportPartnerFields
    }
    currentVehicle {
      ...VehicleFields
    }
  }
  ${TransportPartnerFieldsFragmentDoc}
  ${VehicleFieldsFragmentDoc}
`;
export const RiderFieldsFragmentDoc = gql`
  fragment RiderFields on Rider {
    id
    firstName
    lastName
    phone
    email
    notifPrefs
    notes
    canMakeChanges
    mobilityAssistance
    visionImpairment
    hearingImpairment
    cognitiveImpairment
    profilePhoto
    dob
    heightInches
    weightLbs
    gender
    address {
      ...LocationFields
    }
    paymentMethods {
      ...PaymentMethodFields
    }
    languageId
    carePartner {
      email
      firstName
      lastName
      notifPrefs
      phone
      relationshipNotes
      relationshipType
      id
      twilioPhone
      displayName
    }
  }
  ${LocationFieldsFragmentDoc}
  ${PaymentMethodFieldsFragmentDoc}
`;
export const TransportReasonFieldsFragmentDoc = gql`
  fragment TransportReasonFields on TransportReason {
    categoryName
    id
    name
    requireAppointmentTime
    requireComment
  }
`;
export const CustodianFieldsFragmentDoc = gql`
  fragment CustodianFields on Custodian {
    id
    firstName
    lastName
    email
    phone
    notifPrefs
    admin
    canAccessBi
    promptForCancellationReason
    clinicalCredential
    pushToken
    availableTransportTypes {
      fullName
      id
      name
      shortName
    }
    availableTransportReasons {
      ...TransportReasonFields
    }
  }
  ${TransportReasonFieldsFragmentDoc}
`;
export const RideFieldsFragmentDoc = gql`
  fragment RideFields on Ride {
    id
    requestedStartTime
    requestedEndTime
    actualStartTime
    actualEndTime
    actualDistanceMiles
    estimatedDistanceMiles
    estimatedDurationMinutes
    estimatedCostCents
    actualCostCents
    notes
    passengerCount
    rideType
    status
    deliverySubstitution
    receiptPhotos
    custodianReviewedAt
    stairsCount
    stairsLabel
    transportType
    bariatric
    tzName
    heightInches
    weightLbs
    dropoffRoomNumber
    pickupRoomNumber
    riderWillUseProvidersWheelchair
    payerType
    costCenter
    oxygenRequired
    contactPrecautionsRequired
    estimatedEndTime
    firstAvailable
    rideFeedback {
      createdAt
      driverFeedback
      stars
    }
    rideFeedbackRequestPath
    rideNewPaymentMethodPath
    medicalNecessityCertificationStatement {
      mncsAnswers {
        fieldType
        group1
        group2
        name
        notes
        slug
        sortOrder
        value
      }
    }
    bedsideNurse {
      fullName
      phone
    }
    payer {
      id
      firstName
      lastName
      phone
      email
      address {
        ...LocationFields
      }
      currentPaymentMethod {
        ...PaymentMethodFields
      }
    }
    paymentMethod {
      ...PaymentMethodFields
    }
    driver {
      ...DriverFields
    }
    rider {
      ...RiderFields
    }
    custodian {
      ...CustodianFields
    }
    startLocation {
      ...LocationFields
    }
    endLocation {
      ...LocationFields
    }
    transportReason {
      ...TransportReasonFields
    }
    transportReasonComment
    appointmentTime
    biddingWindowEndsAt
    bidRequestedStartTime
    originalRequestedStartTime
    bestTimeBidsDeclineBefore
    arrivalBufferMinutes
    batchId
  }
  ${LocationFieldsFragmentDoc}
  ${PaymentMethodFieldsFragmentDoc}
  ${DriverFieldsFragmentDoc}
  ${RiderFieldsFragmentDoc}
  ${CustodianFieldsFragmentDoc}
  ${TransportReasonFieldsFragmentDoc}
`;
export const AddCustodianDocument = gql`
  mutation AddCustodian($custodian: CreateCustodianInput!) {
    addCustodian(custodian: $custodian) {
      custodian {
        ...CustodianFields
      }
      error
    }
  }
  ${CustodianFieldsFragmentDoc}
`;
export type AddCustodianMutationFn = Apollo.MutationFunction<
  AddCustodianMutation,
  AddCustodianMutationVariables
>;

/**
 * __useAddCustodianMutation__
 *
 * To run a mutation, you first call `useAddCustodianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustodianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustodianMutation, { data, loading, error }] = useAddCustodianMutation({
 *   variables: {
 *      custodian: // value for 'custodian'
 *   },
 * });
 */
export function useAddCustodianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCustodianMutation,
    AddCustodianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCustodianMutation,
    AddCustodianMutationVariables
  >(AddCustodianDocument, options);
}
export type AddCustodianMutationHookResult = ReturnType<
  typeof useAddCustodianMutation
>;
export type AddCustodianMutationResult = Apollo.MutationResult<AddCustodianMutation>;
export type AddCustodianMutationOptions = Apollo.BaseMutationOptions<
  AddCustodianMutation,
  AddCustodianMutationVariables
>;
export const AddCustodianRiderMutationDocument = gql`
  mutation AddCustodianRiderMutation(
    $rider: CreateRiderInput!
    $ehrSessionId: Int
  ) {
    addCustodianRider(rider: $rider, ehrSessionId: $ehrSessionId) {
      rider {
        ...RiderFields
      }
      error
    }
  }
  ${RiderFieldsFragmentDoc}
`;
export type AddCustodianRiderMutationMutationFn = Apollo.MutationFunction<
  AddCustodianRiderMutationMutation,
  AddCustodianRiderMutationMutationVariables
>;

/**
 * __useAddCustodianRiderMutationMutation__
 *
 * To run a mutation, you first call `useAddCustodianRiderMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustodianRiderMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustodianRiderMutationMutation, { data, loading, error }] = useAddCustodianRiderMutationMutation({
 *   variables: {
 *      rider: // value for 'rider'
 *      ehrSessionId: // value for 'ehrSessionId'
 *   },
 * });
 */
export function useAddCustodianRiderMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCustodianRiderMutationMutation,
    AddCustodianRiderMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCustodianRiderMutationMutation,
    AddCustodianRiderMutationMutationVariables
  >(AddCustodianRiderMutationDocument, options);
}
export type AddCustodianRiderMutationMutationHookResult = ReturnType<
  typeof useAddCustodianRiderMutationMutation
>;
export type AddCustodianRiderMutationMutationResult = Apollo.MutationResult<AddCustodianRiderMutationMutation>;
export type AddCustodianRiderMutationMutationOptions = Apollo.BaseMutationOptions<
  AddCustodianRiderMutationMutation,
  AddCustodianRiderMutationMutationVariables
>;
export const CancelRideMutationDocument = gql`
  mutation CancelRideMutation(
    $rideId: Int!
    $cancellationReasonId: Int
    $batchCancel: Boolean
  ) {
    cancelRide(
      rideId: $rideId
      cancellationReasonId: $cancellationReasonId
      batchCancel: $batchCancel
    ) {
      ride {
        id
      }
      error
    }
  }
`;
export type CancelRideMutationMutationFn = Apollo.MutationFunction<
  CancelRideMutationMutation,
  CancelRideMutationMutationVariables
>;

/**
 * __useCancelRideMutationMutation__
 *
 * To run a mutation, you first call `useCancelRideMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRideMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRideMutationMutation, { data, loading, error }] = useCancelRideMutationMutation({
 *   variables: {
 *      rideId: // value for 'rideId'
 *      cancellationReasonId: // value for 'cancellationReasonId'
 *      batchCancel: // value for 'batchCancel'
 *   },
 * });
 */
export function useCancelRideMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRideMutationMutation,
    CancelRideMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelRideMutationMutation,
    CancelRideMutationMutationVariables
  >(CancelRideMutationDocument, options);
}
export type CancelRideMutationMutationHookResult = ReturnType<
  typeof useCancelRideMutationMutation
>;
export type CancelRideMutationMutationResult = Apollo.MutationResult<CancelRideMutationMutation>;
export type CancelRideMutationMutationOptions = Apollo.BaseMutationOptions<
  CancelRideMutationMutation,
  CancelRideMutationMutationVariables
>;
export const CreateRideMutationDocument = gql`
  mutation CreateRideMutation(
    $ride: CreateRideInput!
    $recurringOptions: CreateRecurringInput
    $isReturnTrip: Boolean!
    $mncsOptions: [CreateMncsInput!]
    $bedsideNurse: CreateBedsideNurseInput
  ) {
    createRide(
      ride: $ride
      isReturnTrip: $isReturnTrip
      recurringOptions: $recurringOptions
      mncsOptions: $mncsOptions
      bedsideNurse: $bedsideNurse
    ) {
      ride {
        ...RideFields
      }
      error
      rideErrors {
        field
        messages
      }
    }
  }
  ${RideFieldsFragmentDoc}
`;
export type CreateRideMutationMutationFn = Apollo.MutationFunction<
  CreateRideMutationMutation,
  CreateRideMutationMutationVariables
>;

/**
 * __useCreateRideMutationMutation__
 *
 * To run a mutation, you first call `useCreateRideMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRideMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRideMutationMutation, { data, loading, error }] = useCreateRideMutationMutation({
 *   variables: {
 *      ride: // value for 'ride'
 *      recurringOptions: // value for 'recurringOptions'
 *      isReturnTrip: // value for 'isReturnTrip'
 *      mncsOptions: // value for 'mncsOptions'
 *      bedsideNurse: // value for 'bedsideNurse'
 *   },
 * });
 */
export function useCreateRideMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRideMutationMutation,
    CreateRideMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRideMutationMutation,
    CreateRideMutationMutationVariables
  >(CreateRideMutationDocument, options);
}
export type CreateRideMutationMutationHookResult = ReturnType<
  typeof useCreateRideMutationMutation
>;
export type CreateRideMutationMutationResult = Apollo.MutationResult<CreateRideMutationMutation>;
export type CreateRideMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateRideMutationMutation,
  CreateRideMutationMutationVariables
>;
export const DeclineBestTimeBidsMutationDocument = gql`
  mutation DeclineBestTimeBidsMutation($rideId: ID!) {
    declineBestTimeBids(rideId: $rideId) {
      ride {
        id
      }
      error
    }
  }
`;
export type DeclineBestTimeBidsMutationMutationFn = Apollo.MutationFunction<
  DeclineBestTimeBidsMutationMutation,
  DeclineBestTimeBidsMutationMutationVariables
>;

/**
 * __useDeclineBestTimeBidsMutationMutation__
 *
 * To run a mutation, you first call `useDeclineBestTimeBidsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineBestTimeBidsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineBestTimeBidsMutationMutation, { data, loading, error }] = useDeclineBestTimeBidsMutationMutation({
 *   variables: {
 *      rideId: // value for 'rideId'
 *   },
 * });
 */
export function useDeclineBestTimeBidsMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineBestTimeBidsMutationMutation,
    DeclineBestTimeBidsMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineBestTimeBidsMutationMutation,
    DeclineBestTimeBidsMutationMutationVariables
  >(DeclineBestTimeBidsMutationDocument, options);
}
export type DeclineBestTimeBidsMutationMutationHookResult = ReturnType<
  typeof useDeclineBestTimeBidsMutationMutation
>;
export type DeclineBestTimeBidsMutationMutationResult = Apollo.MutationResult<DeclineBestTimeBidsMutationMutation>;
export type DeclineBestTimeBidsMutationMutationOptions = Apollo.BaseMutationOptions<
  DeclineBestTimeBidsMutationMutation,
  DeclineBestTimeBidsMutationMutationVariables
>;
export const RemoveAccountPaymentMethodDocument = gql`
  mutation RemoveAccountPaymentMethod($paymentMethodId: String!) {
    removeAccountPaymentMethod(paymentMethodId: $paymentMethodId) {
      account {
        ...AccountFields
      }
      error
    }
  }
  ${AccountFieldsFragmentDoc}
`;
export type RemoveAccountPaymentMethodMutationFn = Apollo.MutationFunction<
  RemoveAccountPaymentMethodMutation,
  RemoveAccountPaymentMethodMutationVariables
>;

/**
 * __useRemoveAccountPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveAccountPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountPaymentMethodMutation, { data, loading, error }] = useRemoveAccountPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useRemoveAccountPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAccountPaymentMethodMutation,
    RemoveAccountPaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAccountPaymentMethodMutation,
    RemoveAccountPaymentMethodMutationVariables
  >(RemoveAccountPaymentMethodDocument, options);
}
export type RemoveAccountPaymentMethodMutationHookResult = ReturnType<
  typeof useRemoveAccountPaymentMethodMutation
>;
export type RemoveAccountPaymentMethodMutationResult = Apollo.MutationResult<RemoveAccountPaymentMethodMutation>;
export type RemoveAccountPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  RemoveAccountPaymentMethodMutation,
  RemoveAccountPaymentMethodMutationVariables
>;
export const RemoveCarePartnerDocument = gql`
  mutation RemoveCarePartner($riderId: ID!) {
    removeCarePartner(riderId: $riderId) {
      riderId
      error
    }
  }
`;
export type RemoveCarePartnerMutationFn = Apollo.MutationFunction<
  RemoveCarePartnerMutation,
  RemoveCarePartnerMutationVariables
>;

/**
 * __useRemoveCarePartnerMutation__
 *
 * To run a mutation, you first call `useRemoveCarePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCarePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCarePartnerMutation, { data, loading, error }] = useRemoveCarePartnerMutation({
 *   variables: {
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useRemoveCarePartnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCarePartnerMutation,
    RemoveCarePartnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCarePartnerMutation,
    RemoveCarePartnerMutationVariables
  >(RemoveCarePartnerDocument, options);
}
export type RemoveCarePartnerMutationHookResult = ReturnType<
  typeof useRemoveCarePartnerMutation
>;
export type RemoveCarePartnerMutationResult = Apollo.MutationResult<RemoveCarePartnerMutation>;
export type RemoveCarePartnerMutationOptions = Apollo.BaseMutationOptions<
  RemoveCarePartnerMutation,
  RemoveCarePartnerMutationVariables
>;
export const RemoveRiderPaymentMethodDocument = gql`
  mutation RemoveRiderPaymentMethod(
    $paymentMethodId: String!
    $riderId: String!
  ) {
    removeRiderPaymentMethod(
      paymentMethodId: $paymentMethodId
      riderId: $riderId
    ) {
      rider {
        ...RiderFields
      }
      error
    }
  }
  ${RiderFieldsFragmentDoc}
`;
export type RemoveRiderPaymentMethodMutationFn = Apollo.MutationFunction<
  RemoveRiderPaymentMethodMutation,
  RemoveRiderPaymentMethodMutationVariables
>;

/**
 * __useRemoveRiderPaymentMethodMutation__
 *
 * To run a mutation, you first call `useRemoveRiderPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRiderPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRiderPaymentMethodMutation, { data, loading, error }] = useRemoveRiderPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useRemoveRiderPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRiderPaymentMethodMutation,
    RemoveRiderPaymentMethodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveRiderPaymentMethodMutation,
    RemoveRiderPaymentMethodMutationVariables
  >(RemoveRiderPaymentMethodDocument, options);
}
export type RemoveRiderPaymentMethodMutationHookResult = ReturnType<
  typeof useRemoveRiderPaymentMethodMutation
>;
export type RemoveRiderPaymentMethodMutationResult = Apollo.MutationResult<RemoveRiderPaymentMethodMutation>;
export type RemoveRiderPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  RemoveRiderPaymentMethodMutation,
  RemoveRiderPaymentMethodMutationVariables
>;
export const RequestRideFeedbackMutationDocument = gql`
  mutation RequestRideFeedbackMutation(
    $RequestRideFeedback: RequestRideFeedbackInput!
  ) {
    requestRideFeedback(requestRideFeedback: $RequestRideFeedback) {
      error
      ride {
        ...RideFields
      }
    }
  }
  ${RideFieldsFragmentDoc}
`;
export type RequestRideFeedbackMutationMutationFn = Apollo.MutationFunction<
  RequestRideFeedbackMutationMutation,
  RequestRideFeedbackMutationMutationVariables
>;

/**
 * __useRequestRideFeedbackMutationMutation__
 *
 * To run a mutation, you first call `useRequestRideFeedbackMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRideFeedbackMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRideFeedbackMutationMutation, { data, loading, error }] = useRequestRideFeedbackMutationMutation({
 *   variables: {
 *      RequestRideFeedback: // value for 'RequestRideFeedback'
 *   },
 * });
 */
export function useRequestRideFeedbackMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestRideFeedbackMutationMutation,
    RequestRideFeedbackMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestRideFeedbackMutationMutation,
    RequestRideFeedbackMutationMutationVariables
  >(RequestRideFeedbackMutationDocument, options);
}
export type RequestRideFeedbackMutationMutationHookResult = ReturnType<
  typeof useRequestRideFeedbackMutationMutation
>;
export type RequestRideFeedbackMutationMutationResult = Apollo.MutationResult<RequestRideFeedbackMutationMutation>;
export type RequestRideFeedbackMutationMutationOptions = Apollo.BaseMutationOptions<
  RequestRideFeedbackMutationMutation,
  RequestRideFeedbackMutationMutationVariables
>;
export const ResetCustodianPasswordDocument = gql`
  mutation ResetCustodianPassword($custodianId: String!) {
    resetCustodianPassword(custodianId: $custodianId) {
      error
    }
  }
`;
export type ResetCustodianPasswordMutationFn = Apollo.MutationFunction<
  ResetCustodianPasswordMutation,
  ResetCustodianPasswordMutationVariables
>;

/**
 * __useResetCustodianPasswordMutation__
 *
 * To run a mutation, you first call `useResetCustodianPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCustodianPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCustodianPasswordMutation, { data, loading, error }] = useResetCustodianPasswordMutation({
 *   variables: {
 *      custodianId: // value for 'custodianId'
 *   },
 * });
 */
export function useResetCustodianPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetCustodianPasswordMutation,
    ResetCustodianPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetCustodianPasswordMutation,
    ResetCustodianPasswordMutationVariables
  >(ResetCustodianPasswordDocument, options);
}
export type ResetCustodianPasswordMutationHookResult = ReturnType<
  typeof useResetCustodianPasswordMutation
>;
export type ResetCustodianPasswordMutationResult = Apollo.MutationResult<ResetCustodianPasswordMutation>;
export type ResetCustodianPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetCustodianPasswordMutation,
  ResetCustodianPasswordMutationVariables
>;
export const ReviewRideMutationDocument = gql`
  mutation ReviewRideMutation($rideId: String!, $ride: ReviewRideInput!) {
    reviewRide(rideId: $rideId, ride: $ride) {
      ride {
        ...RideFields
      }
      completedRideCount
      error
    }
  }
  ${RideFieldsFragmentDoc}
`;
export type ReviewRideMutationMutationFn = Apollo.MutationFunction<
  ReviewRideMutationMutation,
  ReviewRideMutationMutationVariables
>;

/**
 * __useReviewRideMutationMutation__
 *
 * To run a mutation, you first call `useReviewRideMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewRideMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewRideMutationMutation, { data, loading, error }] = useReviewRideMutationMutation({
 *   variables: {
 *      rideId: // value for 'rideId'
 *      ride: // value for 'ride'
 *   },
 * });
 */
export function useReviewRideMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewRideMutationMutation,
    ReviewRideMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReviewRideMutationMutation,
    ReviewRideMutationMutationVariables
  >(ReviewRideMutationDocument, options);
}
export type ReviewRideMutationMutationHookResult = ReturnType<
  typeof useReviewRideMutationMutation
>;
export type ReviewRideMutationMutationResult = Apollo.MutationResult<ReviewRideMutationMutation>;
export type ReviewRideMutationMutationOptions = Apollo.BaseMutationOptions<
  ReviewRideMutationMutation,
  ReviewRideMutationMutationVariables
>;
export const UpdateAccountMutationDocument = gql`
  mutation UpdateAccountMutation($account: UpdateAccountInput!) {
    updateAccount(account: $account) {
      account {
        ...AccountFields
      }
      error
    }
  }
  ${AccountFieldsFragmentDoc}
`;
export type UpdateAccountMutationMutationFn = Apollo.MutationFunction<
  UpdateAccountMutationMutation,
  UpdateAccountMutationMutationVariables
>;

/**
 * __useUpdateAccountMutationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutationMutation, { data, loading, error }] = useUpdateAccountMutationMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useUpdateAccountMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutationMutation,
    UpdateAccountMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountMutationMutation,
    UpdateAccountMutationMutationVariables
  >(UpdateAccountMutationDocument, options);
}
export type UpdateAccountMutationMutationHookResult = ReturnType<
  typeof useUpdateAccountMutationMutation
>;
export type UpdateAccountMutationMutationResult = Apollo.MutationResult<UpdateAccountMutationMutation>;
export type UpdateAccountMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutationMutation,
  UpdateAccountMutationMutationVariables
>;
export const UpdateAccountPaymentMethodMutationDocument = gql`
  mutation UpdateAccountPaymentMethodMutation(
    $paymentMethod: UpdatePaymentMethodInput!
    $paymentMethodId: String!
  ) {
    updateAccountPaymentMethod(
      paymentMethod: $paymentMethod
      paymentMethodId: $paymentMethodId
    ) {
      paymentMethod {
        ...PaymentMethodFields
      }
      error
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;
export type UpdateAccountPaymentMethodMutationMutationFn = Apollo.MutationFunction<
  UpdateAccountPaymentMethodMutationMutation,
  UpdateAccountPaymentMethodMutationMutationVariables
>;

/**
 * __useUpdateAccountPaymentMethodMutationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPaymentMethodMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPaymentMethodMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPaymentMethodMutationMutation, { data, loading, error }] = useUpdateAccountPaymentMethodMutationMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateAccountPaymentMethodMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountPaymentMethodMutationMutation,
    UpdateAccountPaymentMethodMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountPaymentMethodMutationMutation,
    UpdateAccountPaymentMethodMutationMutationVariables
  >(UpdateAccountPaymentMethodMutationDocument, options);
}
export type UpdateAccountPaymentMethodMutationMutationHookResult = ReturnType<
  typeof useUpdateAccountPaymentMethodMutationMutation
>;
export type UpdateAccountPaymentMethodMutationMutationResult = Apollo.MutationResult<UpdateAccountPaymentMethodMutationMutation>;
export type UpdateAccountPaymentMethodMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountPaymentMethodMutationMutation,
  UpdateAccountPaymentMethodMutationMutationVariables
>;
export const UpdateCustodianDocument = gql`
  mutation UpdateCustodian(
    $custodian: UpdateCustodianInput!
    $custodianId: String
  ) {
    updateCustodian(custodian: $custodian, custodianId: $custodianId) {
      custodian {
        ...CustodianFields
      }
      error
    }
  }
  ${CustodianFieldsFragmentDoc}
`;
export type UpdateCustodianMutationFn = Apollo.MutationFunction<
  UpdateCustodianMutation,
  UpdateCustodianMutationVariables
>;

/**
 * __useUpdateCustodianMutation__
 *
 * To run a mutation, you first call `useUpdateCustodianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustodianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustodianMutation, { data, loading, error }] = useUpdateCustodianMutation({
 *   variables: {
 *      custodian: // value for 'custodian'
 *      custodianId: // value for 'custodianId'
 *   },
 * });
 */
export function useUpdateCustodianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustodianMutation,
    UpdateCustodianMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCustodianMutation,
    UpdateCustodianMutationVariables
  >(UpdateCustodianDocument, options);
}
export type UpdateCustodianMutationHookResult = ReturnType<
  typeof useUpdateCustodianMutation
>;
export type UpdateCustodianMutationResult = Apollo.MutationResult<UpdateCustodianMutation>;
export type UpdateCustodianMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustodianMutation,
  UpdateCustodianMutationVariables
>;
export const UpdateFavoriteDriverMutationDocument = gql`
  mutation UpdateFavoriteDriverMutation(
    $riderId: String!
    $driverId: String!
    $favorite: Boolean!
  ) {
    updateFavoriteDriver(
      riderId: $riderId
      driverId: $driverId
      favorite: $favorite
    ) {
      error
    }
  }
`;
export type UpdateFavoriteDriverMutationMutationFn = Apollo.MutationFunction<
  UpdateFavoriteDriverMutationMutation,
  UpdateFavoriteDriverMutationMutationVariables
>;

/**
 * __useUpdateFavoriteDriverMutationMutation__
 *
 * To run a mutation, you first call `useUpdateFavoriteDriverMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFavoriteDriverMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFavoriteDriverMutationMutation, { data, loading, error }] = useUpdateFavoriteDriverMutationMutation({
 *   variables: {
 *      riderId: // value for 'riderId'
 *      driverId: // value for 'driverId'
 *      favorite: // value for 'favorite'
 *   },
 * });
 */
export function useUpdateFavoriteDriverMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFavoriteDriverMutationMutation,
    UpdateFavoriteDriverMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFavoriteDriverMutationMutation,
    UpdateFavoriteDriverMutationMutationVariables
  >(UpdateFavoriteDriverMutationDocument, options);
}
export type UpdateFavoriteDriverMutationMutationHookResult = ReturnType<
  typeof useUpdateFavoriteDriverMutationMutation
>;
export type UpdateFavoriteDriverMutationMutationResult = Apollo.MutationResult<UpdateFavoriteDriverMutationMutation>;
export type UpdateFavoriteDriverMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateFavoriteDriverMutationMutation,
  UpdateFavoriteDriverMutationMutationVariables
>;
export const UpdateRideMutationDocument = gql`
  mutation UpdateRideMutation(
    $ride: UpdateRideInput!
    $rideId: Int!
    $bedsideNurse: CreateBedsideNurseInput
    $mncsOptions: [CreateMncsInput!]
  ) {
    updateRide(
      ride: $ride
      rideId: $rideId
      bedsideNurse: $bedsideNurse
      mncsOptions: $mncsOptions
    ) {
      ride {
        ...RideFields
      }
      error
    }
  }
  ${RideFieldsFragmentDoc}
`;
export type UpdateRideMutationMutationFn = Apollo.MutationFunction<
  UpdateRideMutationMutation,
  UpdateRideMutationMutationVariables
>;

/**
 * __useUpdateRideMutationMutation__
 *
 * To run a mutation, you first call `useUpdateRideMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRideMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRideMutationMutation, { data, loading, error }] = useUpdateRideMutationMutation({
 *   variables: {
 *      ride: // value for 'ride'
 *      rideId: // value for 'rideId'
 *      bedsideNurse: // value for 'bedsideNurse'
 *      mncsOptions: // value for 'mncsOptions'
 *   },
 * });
 */
export function useUpdateRideMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRideMutationMutation,
    UpdateRideMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRideMutationMutation,
    UpdateRideMutationMutationVariables
  >(UpdateRideMutationDocument, options);
}
export type UpdateRideMutationMutationHookResult = ReturnType<
  typeof useUpdateRideMutationMutation
>;
export type UpdateRideMutationMutationResult = Apollo.MutationResult<UpdateRideMutationMutation>;
export type UpdateRideMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateRideMutationMutation,
  UpdateRideMutationMutationVariables
>;
export const UpdateRiderMutationDocument = gql`
  mutation UpdateRiderMutation($rider: CreateRiderInput!, $riderId: String!) {
    updateRider(rider: $rider, riderId: $riderId) {
      rider {
        ...RiderFields
      }
      error
    }
  }
  ${RiderFieldsFragmentDoc}
`;
export type UpdateRiderMutationMutationFn = Apollo.MutationFunction<
  UpdateRiderMutationMutation,
  UpdateRiderMutationMutationVariables
>;

/**
 * __useUpdateRiderMutationMutation__
 *
 * To run a mutation, you first call `useUpdateRiderMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiderMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiderMutationMutation, { data, loading, error }] = useUpdateRiderMutationMutation({
 *   variables: {
 *      rider: // value for 'rider'
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useUpdateRiderMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRiderMutationMutation,
    UpdateRiderMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRiderMutationMutation,
    UpdateRiderMutationMutationVariables
  >(UpdateRiderMutationDocument, options);
}
export type UpdateRiderMutationMutationHookResult = ReturnType<
  typeof useUpdateRiderMutationMutation
>;
export type UpdateRiderMutationMutationResult = Apollo.MutationResult<UpdateRiderMutationMutation>;
export type UpdateRiderMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateRiderMutationMutation,
  UpdateRiderMutationMutationVariables
>;
export const UpdateRiderPaymentMethodMutationDocument = gql`
  mutation UpdateRiderPaymentMethodMutation(
    $paymentMethod: UpdatePaymentMethodInput!
    $paymentMethodId: String!
    $riderId: String!
  ) {
    updateRiderPaymentMethod(
      paymentMethod: $paymentMethod
      paymentMethodId: $paymentMethodId
      riderId: $riderId
    ) {
      rider {
        ...RiderFields
      }
      error
    }
  }
  ${RiderFieldsFragmentDoc}
`;
export type UpdateRiderPaymentMethodMutationMutationFn = Apollo.MutationFunction<
  UpdateRiderPaymentMethodMutationMutation,
  UpdateRiderPaymentMethodMutationMutationVariables
>;

/**
 * __useUpdateRiderPaymentMethodMutationMutation__
 *
 * To run a mutation, you first call `useUpdateRiderPaymentMethodMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiderPaymentMethodMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiderPaymentMethodMutationMutation, { data, loading, error }] = useUpdateRiderPaymentMethodMutationMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useUpdateRiderPaymentMethodMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRiderPaymentMethodMutationMutation,
    UpdateRiderPaymentMethodMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRiderPaymentMethodMutationMutation,
    UpdateRiderPaymentMethodMutationMutationVariables
  >(UpdateRiderPaymentMethodMutationDocument, options);
}
export type UpdateRiderPaymentMethodMutationMutationHookResult = ReturnType<
  typeof useUpdateRiderPaymentMethodMutationMutation
>;
export type UpdateRiderPaymentMethodMutationMutationResult = Apollo.MutationResult<UpdateRiderPaymentMethodMutationMutation>;
export type UpdateRiderPaymentMethodMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateRiderPaymentMethodMutationMutation,
  UpdateRiderPaymentMethodMutationMutationVariables
>;
export const AccountCancellationReasonQueryDocument = gql`
  query AccountCancellationReasonQuery {
    cancellationReasons {
      accountId
      cancellationReason
      displayOrder
      id
    }
  }
`;

/**
 * __useAccountCancellationReasonQueryQuery__
 *
 * To run a query within a React component, call `useAccountCancellationReasonQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCancellationReasonQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCancellationReasonQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountCancellationReasonQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountCancellationReasonQueryQuery,
    AccountCancellationReasonQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountCancellationReasonQueryQuery,
    AccountCancellationReasonQueryQueryVariables
  >(AccountCancellationReasonQueryDocument, options);
}
export function useAccountCancellationReasonQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountCancellationReasonQueryQuery,
    AccountCancellationReasonQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountCancellationReasonQueryQuery,
    AccountCancellationReasonQueryQueryVariables
  >(AccountCancellationReasonQueryDocument, options);
}
export type AccountCancellationReasonQueryQueryHookResult = ReturnType<
  typeof useAccountCancellationReasonQueryQuery
>;
export type AccountCancellationReasonQueryLazyQueryHookResult = ReturnType<
  typeof useAccountCancellationReasonQueryLazyQuery
>;
export type AccountCancellationReasonQueryQueryResult = Apollo.QueryResult<
  AccountCancellationReasonQueryQuery,
  AccountCancellationReasonQueryQueryVariables
>;
export const AccountCustodiansPaginationQueryDocument = gql`
  query AccountCustodiansPaginationQuery(
    $query: String!
    $page: Int!
    $limit: Int!
    $sortBy: String
    $sortDirection: String
    $filters: [FiltersInput!]
  ) {
    accountCustodiansCollection(
      query: $query
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
      filters: $filters
    ) {
      collection {
        ...CustodianFields
      }
      metadata {
        totalPages
        totalCount
        currentPage
        limitValue
      }
    }
  }
  ${CustodianFieldsFragmentDoc}
`;

/**
 * __useAccountCustodiansPaginationQueryQuery__
 *
 * To run a query within a React component, call `useAccountCustodiansPaginationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCustodiansPaginationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCustodiansPaginationQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAccountCustodiansPaginationQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountCustodiansPaginationQueryQuery,
    AccountCustodiansPaginationQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountCustodiansPaginationQueryQuery,
    AccountCustodiansPaginationQueryQueryVariables
  >(AccountCustodiansPaginationQueryDocument, options);
}
export function useAccountCustodiansPaginationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountCustodiansPaginationQueryQuery,
    AccountCustodiansPaginationQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountCustodiansPaginationQueryQuery,
    AccountCustodiansPaginationQueryQueryVariables
  >(AccountCustodiansPaginationQueryDocument, options);
}
export type AccountCustodiansPaginationQueryQueryHookResult = ReturnType<
  typeof useAccountCustodiansPaginationQueryQuery
>;
export type AccountCustodiansPaginationQueryLazyQueryHookResult = ReturnType<
  typeof useAccountCustodiansPaginationQueryLazyQuery
>;
export type AccountCustodiansPaginationQueryQueryResult = Apollo.QueryResult<
  AccountCustodiansPaginationQueryQuery,
  AccountCustodiansPaginationQueryQueryVariables
>;
export const AccountCustodiansQueryDocument = gql`
  query AccountCustodiansQuery {
    accountCustodians {
      ...CustodianFields
    }
    profile {
      canInvite
    }
  }
  ${CustodianFieldsFragmentDoc}
`;

/**
 * __useAccountCustodiansQueryQuery__
 *
 * To run a query within a React component, call `useAccountCustodiansQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCustodiansQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCustodiansQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountCustodiansQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountCustodiansQueryQuery,
    AccountCustodiansQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountCustodiansQueryQuery,
    AccountCustodiansQueryQueryVariables
  >(AccountCustodiansQueryDocument, options);
}
export function useAccountCustodiansQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountCustodiansQueryQuery,
    AccountCustodiansQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountCustodiansQueryQuery,
    AccountCustodiansQueryQueryVariables
  >(AccountCustodiansQueryDocument, options);
}
export type AccountCustodiansQueryQueryHookResult = ReturnType<
  typeof useAccountCustodiansQueryQuery
>;
export type AccountCustodiansQueryLazyQueryHookResult = ReturnType<
  typeof useAccountCustodiansQueryLazyQuery
>;
export type AccountCustodiansQueryQueryResult = Apollo.QueryResult<
  AccountCustodiansQueryQuery,
  AccountCustodiansQueryQueryVariables
>;
export const AccountFinancialResponsibilityChoicesDocument = gql`
  query AccountFinancialResponsibilityChoices(
    $accountId: String
    $payerType: String!
  ) {
    accountFinancialResponsibilityChoices(
      accountId: $accountId
      payerType: $payerType
    ) {
      costCenter
      promptForPayerDemographics
    }
  }
`;

/**
 * __useAccountFinancialResponsibilityChoicesQuery__
 *
 * To run a query within a React component, call `useAccountFinancialResponsibilityChoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountFinancialResponsibilityChoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountFinancialResponsibilityChoicesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      payerType: // value for 'payerType'
 *   },
 * });
 */
export function useAccountFinancialResponsibilityChoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AccountFinancialResponsibilityChoicesQuery,
    AccountFinancialResponsibilityChoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountFinancialResponsibilityChoicesQuery,
    AccountFinancialResponsibilityChoicesQueryVariables
  >(AccountFinancialResponsibilityChoicesDocument, options);
}
export function useAccountFinancialResponsibilityChoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountFinancialResponsibilityChoicesQuery,
    AccountFinancialResponsibilityChoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountFinancialResponsibilityChoicesQuery,
    AccountFinancialResponsibilityChoicesQueryVariables
  >(AccountFinancialResponsibilityChoicesDocument, options);
}
export type AccountFinancialResponsibilityChoicesQueryHookResult = ReturnType<
  typeof useAccountFinancialResponsibilityChoicesQuery
>;
export type AccountFinancialResponsibilityChoicesLazyQueryHookResult = ReturnType<
  typeof useAccountFinancialResponsibilityChoicesLazyQuery
>;
export type AccountFinancialResponsibilityChoicesQueryResult = Apollo.QueryResult<
  AccountFinancialResponsibilityChoicesQuery,
  AccountFinancialResponsibilityChoicesQueryVariables
>;
export const AccountLocationsQueryDocument = gql`
  query AccountLocationsQuery($transportType: String, $query: String) {
    accountLocations(transportType: $transportType, query: $query) {
      ...LocationFields
    }
  }
  ${LocationFieldsFragmentDoc}
`;

/**
 * __useAccountLocationsQueryQuery__
 *
 * To run a query within a React component, call `useAccountLocationsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLocationsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLocationsQueryQuery({
 *   variables: {
 *      transportType: // value for 'transportType'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAccountLocationsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountLocationsQueryQuery,
    AccountLocationsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountLocationsQueryQuery,
    AccountLocationsQueryQueryVariables
  >(AccountLocationsQueryDocument, options);
}
export function useAccountLocationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountLocationsQueryQuery,
    AccountLocationsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountLocationsQueryQuery,
    AccountLocationsQueryQueryVariables
  >(AccountLocationsQueryDocument, options);
}
export type AccountLocationsQueryQueryHookResult = ReturnType<
  typeof useAccountLocationsQueryQuery
>;
export type AccountLocationsQueryLazyQueryHookResult = ReturnType<
  typeof useAccountLocationsQueryLazyQuery
>;
export type AccountLocationsQueryQueryResult = Apollo.QueryResult<
  AccountLocationsQueryQuery,
  AccountLocationsQueryQueryVariables
>;
export const AccountQueryDocument = gql`
  query AccountQuery {
    account {
      ...AccountFields
    }
    profile {
      ...CustodianFields
    }
  }
  ${AccountFieldsFragmentDoc}
  ${CustodianFieldsFragmentDoc}
`;

/**
 * __useAccountQueryQuery__
 *
 * To run a query within a React component, call `useAccountQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountQueryQuery,
    AccountQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQueryQuery, AccountQueryQueryVariables>(
    AccountQueryDocument,
    options,
  );
}
export function useAccountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountQueryQuery,
    AccountQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQueryQuery, AccountQueryQueryVariables>(
    AccountQueryDocument,
    options,
  );
}
export type AccountQueryQueryHookResult = ReturnType<
  typeof useAccountQueryQuery
>;
export type AccountQueryLazyQueryHookResult = ReturnType<
  typeof useAccountQueryLazyQuery
>;
export type AccountQueryQueryResult = Apollo.QueryResult<
  AccountQueryQuery,
  AccountQueryQueryVariables
>;
export const BatchRidesQueryDocument = gql`
  query BatchRidesQuery($rideId: Int!) {
    batchRides(rideId: $rideId) {
      ...RideFields
    }
    account {
      ...AccountFields
    }
  }
  ${RideFieldsFragmentDoc}
  ${AccountFieldsFragmentDoc}
`;

/**
 * __useBatchRidesQueryQuery__
 *
 * To run a query within a React component, call `useBatchRidesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchRidesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchRidesQueryQuery({
 *   variables: {
 *      rideId: // value for 'rideId'
 *   },
 * });
 */
export function useBatchRidesQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    BatchRidesQueryQuery,
    BatchRidesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BatchRidesQueryQuery, BatchRidesQueryQueryVariables>(
    BatchRidesQueryDocument,
    options,
  );
}
export function useBatchRidesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatchRidesQueryQuery,
    BatchRidesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatchRidesQueryQuery,
    BatchRidesQueryQueryVariables
  >(BatchRidesQueryDocument, options);
}
export type BatchRidesQueryQueryHookResult = ReturnType<
  typeof useBatchRidesQueryQuery
>;
export type BatchRidesQueryLazyQueryHookResult = ReturnType<
  typeof useBatchRidesQueryLazyQuery
>;
export type BatchRidesQueryQueryResult = Apollo.QueryResult<
  BatchRidesQueryQuery,
  BatchRidesQueryQueryVariables
>;
export const CustodiansQueryDocument = gql`
  query CustodiansQuery($query: String!) {
    custodians(query: $query) {
      id
      firstName
      lastName
      email
    }
  }
`;

/**
 * __useCustodiansQueryQuery__
 *
 * To run a query within a React component, call `useCustodiansQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustodiansQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustodiansQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCustodiansQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustodiansQueryQuery,
    CustodiansQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustodiansQueryQuery, CustodiansQueryQueryVariables>(
    CustodiansQueryDocument,
    options,
  );
}
export function useCustodiansQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustodiansQueryQuery,
    CustodiansQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CustodiansQueryQuery,
    CustodiansQueryQueryVariables
  >(CustodiansQueryDocument, options);
}
export type CustodiansQueryQueryHookResult = ReturnType<
  typeof useCustodiansQueryQuery
>;
export type CustodiansQueryLazyQueryHookResult = ReturnType<
  typeof useCustodiansQueryLazyQuery
>;
export type CustodiansQueryQueryResult = Apollo.QueryResult<
  CustodiansQueryQuery,
  CustodiansQueryQueryVariables
>;
export const DashboardQueryDocument = gql`
  query DashboardQuery {
    profile {
      ...CustodianFields
    }
    account {
      ...AccountFields
    }
    upcomingRides {
      id
      requestedStartTime
      requestedEndTime
      actualStartTime
      actualEndTime
      estimatedDistanceMiles
      estimatedDurationMinutes
      estimatedCostCents
      actualCostCents
      rideType
      status
      tzName
      transportType
      custodian {
        ...CustodianFields
      }
      driver {
        id
        firstName
        lastName
        profilePhoto
      }
      rider {
        id
        firstName
        lastName
        profilePhoto
      }
      startLocation {
        id
        name
        address
      }
      endLocation {
        id
        name
        address
      }
    }
    riders {
      id
      firstName
      lastName
    }
    featureFlags
  }
  ${CustodianFieldsFragmentDoc}
  ${AccountFieldsFragmentDoc}
`;

/**
 * __useDashboardQueryQuery__
 *
 * To run a query within a React component, call `useDashboardQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardQueryQuery,
    DashboardQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardQueryQuery, DashboardQueryQueryVariables>(
    DashboardQueryDocument,
    options,
  );
}
export function useDashboardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardQueryQuery,
    DashboardQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardQueryQuery, DashboardQueryQueryVariables>(
    DashboardQueryDocument,
    options,
  );
}
export type DashboardQueryQueryHookResult = ReturnType<
  typeof useDashboardQueryQuery
>;
export type DashboardQueryLazyQueryHookResult = ReturnType<
  typeof useDashboardQueryLazyQuery
>;
export type DashboardQueryQueryResult = Apollo.QueryResult<
  DashboardQueryQuery,
  DashboardQueryQueryVariables
>;
export const TransportTypeOptionsQueryDocument = gql`
  query TransportTypeOptionsQuery($transportType: String!) {
    rideTransportTypeOptions(transportType: $transportType) {
      contactPrecautionsRequired
      oxygenRequired
      promptForMncs
      promptForBedsideNurseContact
    }
  }
`;

/**
 * __useTransportTypeOptionsQueryQuery__
 *
 * To run a query within a React component, call `useTransportTypeOptionsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransportTypeOptionsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransportTypeOptionsQueryQuery({
 *   variables: {
 *      transportType: // value for 'transportType'
 *   },
 * });
 */
export function useTransportTypeOptionsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransportTypeOptionsQueryQuery,
    TransportTypeOptionsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransportTypeOptionsQueryQuery,
    TransportTypeOptionsQueryQueryVariables
  >(TransportTypeOptionsQueryDocument, options);
}
export function useTransportTypeOptionsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransportTypeOptionsQueryQuery,
    TransportTypeOptionsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransportTypeOptionsQueryQuery,
    TransportTypeOptionsQueryQueryVariables
  >(TransportTypeOptionsQueryDocument, options);
}
export type TransportTypeOptionsQueryQueryHookResult = ReturnType<
  typeof useTransportTypeOptionsQueryQuery
>;
export type TransportTypeOptionsQueryLazyQueryHookResult = ReturnType<
  typeof useTransportTypeOptionsQueryLazyQuery
>;
export type TransportTypeOptionsQueryQueryResult = Apollo.QueryResult<
  TransportTypeOptionsQueryQuery,
  TransportTypeOptionsQueryQueryVariables
>;
export const LanguagesDocument = gql`
  query Languages {
    languages {
      displayName
      id
      sortOrder
    }
  }
`;

/**
 * __useLanguagesQuery__
 *
 * To run a query within a React component, call `useLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LanguagesQuery,
    LanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LanguagesQuery, LanguagesQueryVariables>(
    LanguagesDocument,
    options,
  );
}
export function useLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LanguagesQuery,
    LanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LanguagesQuery, LanguagesQueryVariables>(
    LanguagesDocument,
    options,
  );
}
export type LanguagesQueryHookResult = ReturnType<typeof useLanguagesQuery>;
export type LanguagesLazyQueryHookResult = ReturnType<
  typeof useLanguagesLazyQuery
>;
export type LanguagesQueryResult = Apollo.QueryResult<
  LanguagesQuery,
  LanguagesQueryVariables
>;
export const ProfileQueryDocument = gql`
  query ProfileQuery {
    profile {
      ...CustodianFields
    }
  }
  ${CustodianFieldsFragmentDoc}
`;

/**
 * __useProfileQueryQuery__
 *
 * To run a query within a React component, call `useProfileQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfileQueryQuery,
    ProfileQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileQueryQuery, ProfileQueryQueryVariables>(
    ProfileQueryDocument,
    options,
  );
}
export function useProfileQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileQueryQuery,
    ProfileQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileQueryQuery, ProfileQueryQueryVariables>(
    ProfileQueryDocument,
    options,
  );
}
export type ProfileQueryQueryHookResult = ReturnType<
  typeof useProfileQueryQuery
>;
export type ProfileQueryLazyQueryHookResult = ReturnType<
  typeof useProfileQueryLazyQuery
>;
export type ProfileQueryQueryResult = Apollo.QueryResult<
  ProfileQueryQuery,
  ProfileQueryQueryVariables
>;
export const RecentDriverQueryDocument = gql`
  query RecentDriverQuery($riderId: String) {
    recentDrivers(riderId: $riderId) {
      id
      firstName
      lastName
      profilePhoto
    }
  }
`;

/**
 * __useRecentDriverQueryQuery__
 *
 * To run a query within a React component, call `useRecentDriverQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentDriverQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentDriverQueryQuery({
 *   variables: {
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useRecentDriverQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecentDriverQueryQuery,
    RecentDriverQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecentDriverQueryQuery,
    RecentDriverQueryQueryVariables
  >(RecentDriverQueryDocument, options);
}
export function useRecentDriverQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentDriverQueryQuery,
    RecentDriverQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecentDriverQueryQuery,
    RecentDriverQueryQueryVariables
  >(RecentDriverQueryDocument, options);
}
export type RecentDriverQueryQueryHookResult = ReturnType<
  typeof useRecentDriverQueryQuery
>;
export type RecentDriverQueryLazyQueryHookResult = ReturnType<
  typeof useRecentDriverQueryLazyQuery
>;
export type RecentDriverQueryQueryResult = Apollo.QueryResult<
  RecentDriverQueryQuery,
  RecentDriverQueryQueryVariables
>;
export const RecentLocationsQueryDocument = gql`
  query RecentLocationsQuery(
    $riderId: String
    $transportType: String
    $query: String
  ) {
    recentLocations(
      riderId: $riderId
      transportType: $transportType
      query: $query
    ) {
      ...LocationFields
    }
  }
  ${LocationFieldsFragmentDoc}
`;

/**
 * __useRecentLocationsQueryQuery__
 *
 * To run a query within a React component, call `useRecentLocationsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentLocationsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentLocationsQueryQuery({
 *   variables: {
 *      riderId: // value for 'riderId'
 *      transportType: // value for 'transportType'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRecentLocationsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecentLocationsQueryQuery,
    RecentLocationsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecentLocationsQueryQuery,
    RecentLocationsQueryQueryVariables
  >(RecentLocationsQueryDocument, options);
}
export function useRecentLocationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentLocationsQueryQuery,
    RecentLocationsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecentLocationsQueryQuery,
    RecentLocationsQueryQueryVariables
  >(RecentLocationsQueryDocument, options);
}
export type RecentLocationsQueryQueryHookResult = ReturnType<
  typeof useRecentLocationsQueryQuery
>;
export type RecentLocationsQueryLazyQueryHookResult = ReturnType<
  typeof useRecentLocationsQueryLazyQuery
>;
export type RecentLocationsQueryQueryResult = Apollo.QueryResult<
  RecentLocationsQueryQuery,
  RecentLocationsQueryQueryVariables
>;
export const RideEstimateDocument = gql`
  query RideEstimate($ride: CreateRideInput!) {
    rideEstimate(ride: $ride) {
      estimatedCostFormatted
      estimatedEndTime
      estimatedDurationMinutesNoRound
    }
  }
`;

/**
 * __useRideEstimateQuery__
 *
 * To run a query within a React component, call `useRideEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideEstimateQuery({
 *   variables: {
 *      ride: // value for 'ride'
 *   },
 * });
 */
export function useRideEstimateQuery(
  baseOptions: Apollo.QueryHookOptions<
    RideEstimateQuery,
    RideEstimateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RideEstimateQuery, RideEstimateQueryVariables>(
    RideEstimateDocument,
    options,
  );
}
export function useRideEstimateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RideEstimateQuery,
    RideEstimateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RideEstimateQuery, RideEstimateQueryVariables>(
    RideEstimateDocument,
    options,
  );
}
export type RideEstimateQueryHookResult = ReturnType<
  typeof useRideEstimateQuery
>;
export type RideEstimateLazyQueryHookResult = ReturnType<
  typeof useRideEstimateLazyQuery
>;
export type RideEstimateQueryResult = Apollo.QueryResult<
  RideEstimateQuery,
  RideEstimateQueryVariables
>;
export const RideHistoryDetailsQueryDocument = gql`
  query RideHistoryDetailsQuery($rideId: Int!) {
    ride(rideId: $rideId) {
      id
      actualStartTime
      actualEndTime
      actualCostCents
      receiptPhotos
      status
      tzName
      startLocation {
        ...LocationFields
      }
      endLocation {
        ...LocationFields
      }
      rideCharges {
        ...RideChargeFields
      }
    }
  }
  ${LocationFieldsFragmentDoc}
  ${RideChargeFieldsFragmentDoc}
`;

/**
 * __useRideHistoryDetailsQueryQuery__
 *
 * To run a query within a React component, call `useRideHistoryDetailsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideHistoryDetailsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideHistoryDetailsQueryQuery({
 *   variables: {
 *      rideId: // value for 'rideId'
 *   },
 * });
 */
export function useRideHistoryDetailsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RideHistoryDetailsQueryQuery,
    RideHistoryDetailsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RideHistoryDetailsQueryQuery,
    RideHistoryDetailsQueryQueryVariables
  >(RideHistoryDetailsQueryDocument, options);
}
export function useRideHistoryDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RideHistoryDetailsQueryQuery,
    RideHistoryDetailsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RideHistoryDetailsQueryQuery,
    RideHistoryDetailsQueryQueryVariables
  >(RideHistoryDetailsQueryDocument, options);
}
export type RideHistoryDetailsQueryQueryHookResult = ReturnType<
  typeof useRideHistoryDetailsQueryQuery
>;
export type RideHistoryDetailsQueryLazyQueryHookResult = ReturnType<
  typeof useRideHistoryDetailsQueryLazyQuery
>;
export type RideHistoryDetailsQueryQueryResult = Apollo.QueryResult<
  RideHistoryDetailsQueryQuery,
  RideHistoryDetailsQueryQueryVariables
>;
export const RideHistoryPaginatedQueryDocument = gql`
  query RideHistoryPaginatedQuery($offset: Int!, $limit: Int!) {
    rideHistoryPaginated(offset: $offset, limit: $limit) {
      rides {
        id
        requestedStartTime
        actualStartTime
        actualEndTime
        actualCostCents
        actualDistanceMiles
        estimatedDistanceMiles
        status
        rideType
        tzName
        transportType
        startLocation {
          address
        }
        endLocation {
          address
        }
        rider {
          id
          firstName
          lastName
        }
        driver {
          id
          firstName
          lastName
        }
      }
      hasMore
      id
    }
  }
`;

/**
 * __useRideHistoryPaginatedQueryQuery__
 *
 * To run a query within a React component, call `useRideHistoryPaginatedQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideHistoryPaginatedQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideHistoryPaginatedQueryQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRideHistoryPaginatedQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RideHistoryPaginatedQueryQuery,
    RideHistoryPaginatedQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RideHistoryPaginatedQueryQuery,
    RideHistoryPaginatedQueryQueryVariables
  >(RideHistoryPaginatedQueryDocument, options);
}
export function useRideHistoryPaginatedQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RideHistoryPaginatedQueryQuery,
    RideHistoryPaginatedQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RideHistoryPaginatedQueryQuery,
    RideHistoryPaginatedQueryQueryVariables
  >(RideHistoryPaginatedQueryDocument, options);
}
export type RideHistoryPaginatedQueryQueryHookResult = ReturnType<
  typeof useRideHistoryPaginatedQueryQuery
>;
export type RideHistoryPaginatedQueryLazyQueryHookResult = ReturnType<
  typeof useRideHistoryPaginatedQueryLazyQuery
>;
export type RideHistoryPaginatedQueryQueryResult = Apollo.QueryResult<
  RideHistoryPaginatedQueryQuery,
  RideHistoryPaginatedQueryQueryVariables
>;
export const RideMncsPromptsQueryDocument = gql`
  query RideMncsPromptsQuery {
    rideMncsPrompts {
      fieldType
      group1
      group2
      name
      notes
      slug
    }
  }
`;

/**
 * __useRideMncsPromptsQueryQuery__
 *
 * To run a query within a React component, call `useRideMncsPromptsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideMncsPromptsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideMncsPromptsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useRideMncsPromptsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RideMncsPromptsQueryQuery,
    RideMncsPromptsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RideMncsPromptsQueryQuery,
    RideMncsPromptsQueryQueryVariables
  >(RideMncsPromptsQueryDocument, options);
}
export function useRideMncsPromptsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RideMncsPromptsQueryQuery,
    RideMncsPromptsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RideMncsPromptsQueryQuery,
    RideMncsPromptsQueryQueryVariables
  >(RideMncsPromptsQueryDocument, options);
}
export type RideMncsPromptsQueryQueryHookResult = ReturnType<
  typeof useRideMncsPromptsQueryQuery
>;
export type RideMncsPromptsQueryLazyQueryHookResult = ReturnType<
  typeof useRideMncsPromptsQueryLazyQuery
>;
export type RideMncsPromptsQueryQueryResult = Apollo.QueryResult<
  RideMncsPromptsQueryQuery,
  RideMncsPromptsQueryQueryVariables
>;
export const RideNotesPlaceholderTextQueryDocument = gql`
  query RideNotesPlaceholderTextQuery($transportType: String!) {
    rideNotesPlaceholderText(transportType: $transportType)
  }
`;

/**
 * __useRideNotesPlaceholderTextQueryQuery__
 *
 * To run a query within a React component, call `useRideNotesPlaceholderTextQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideNotesPlaceholderTextQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideNotesPlaceholderTextQueryQuery({
 *   variables: {
 *      transportType: // value for 'transportType'
 *   },
 * });
 */
export function useRideNotesPlaceholderTextQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RideNotesPlaceholderTextQueryQuery,
    RideNotesPlaceholderTextQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RideNotesPlaceholderTextQueryQuery,
    RideNotesPlaceholderTextQueryQueryVariables
  >(RideNotesPlaceholderTextQueryDocument, options);
}
export function useRideNotesPlaceholderTextQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RideNotesPlaceholderTextQueryQuery,
    RideNotesPlaceholderTextQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RideNotesPlaceholderTextQueryQuery,
    RideNotesPlaceholderTextQueryQueryVariables
  >(RideNotesPlaceholderTextQueryDocument, options);
}
export type RideNotesPlaceholderTextQueryQueryHookResult = ReturnType<
  typeof useRideNotesPlaceholderTextQueryQuery
>;
export type RideNotesPlaceholderTextQueryLazyQueryHookResult = ReturnType<
  typeof useRideNotesPlaceholderTextQueryLazyQuery
>;
export type RideNotesPlaceholderTextQueryQueryResult = Apollo.QueryResult<
  RideNotesPlaceholderTextQueryQuery,
  RideNotesPlaceholderTextQueryQueryVariables
>;
export const RidesPaginationQueryDocument = gql`
  query RidesPaginationQuery(
    $query: String!
    $page: Int!
    $limit: Int!
    $sortBy: String
    $sortDirection: String
    $scope: RidesCollectionScope
    $filters: [FiltersInput!]
  ) {
    ridesCollection(
      query: $query
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
      scope: $scope
      filters: $filters
    ) {
      collection {
        ...RideFields
      }
      metadata {
        totalPages
        totalCount
        currentPage
        limitValue
      }
    }
  }
  ${RideFieldsFragmentDoc}
`;

/**
 * __useRidesPaginationQueryQuery__
 *
 * To run a query within a React component, call `useRidesPaginationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRidesPaginationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRidesPaginationQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      scope: // value for 'scope'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRidesPaginationQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RidesPaginationQueryQuery,
    RidesPaginationQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RidesPaginationQueryQuery,
    RidesPaginationQueryQueryVariables
  >(RidesPaginationQueryDocument, options);
}
export function useRidesPaginationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RidesPaginationQueryQuery,
    RidesPaginationQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RidesPaginationQueryQuery,
    RidesPaginationQueryQueryVariables
  >(RidesPaginationQueryDocument, options);
}
export type RidesPaginationQueryQueryHookResult = ReturnType<
  typeof useRidesPaginationQueryQuery
>;
export type RidesPaginationQueryLazyQueryHookResult = ReturnType<
  typeof useRidesPaginationQueryLazyQuery
>;
export type RidesPaginationQueryQueryResult = Apollo.QueryResult<
  RidesPaginationQueryQuery,
  RidesPaginationQueryQueryVariables
>;
export const RideQueryDocument = gql`
  query RideQuery($rideId: Int!) {
    ride(rideId: $rideId) {
      ...RideFields
    }
    account {
      ...AccountFields
    }
  }
  ${RideFieldsFragmentDoc}
  ${AccountFieldsFragmentDoc}
`;

/**
 * __useRideQueryQuery__
 *
 * To run a query within a React component, call `useRideQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRideQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRideQueryQuery({
 *   variables: {
 *      rideId: // value for 'rideId'
 *   },
 * });
 */
export function useRideQueryQuery(
  baseOptions: Apollo.QueryHookOptions<RideQueryQuery, RideQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RideQueryQuery, RideQueryQueryVariables>(
    RideQueryDocument,
    options,
  );
}
export function useRideQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RideQueryQuery,
    RideQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RideQueryQuery, RideQueryQueryVariables>(
    RideQueryDocument,
    options,
  );
}
export type RideQueryQueryHookResult = ReturnType<typeof useRideQueryQuery>;
export type RideQueryLazyQueryHookResult = ReturnType<
  typeof useRideQueryLazyQuery
>;
export type RideQueryQueryResult = Apollo.QueryResult<
  RideQueryQuery,
  RideQueryQueryVariables
>;
export const RidersPaginationQueryDocument = gql`
  query RidersPaginationQuery(
    $query: String!
    $page: Int!
    $limit: Int!
    $sortBy: String
    $sortDirection: String
  ) {
    ridersCollection(
      query: $query
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      collection {
        id
        firstName
        lastName
        phone
        email
        notifPrefs
        notes
        canMakeChanges
        mobilityAssistance
        visionImpairment
        hearingImpairment
        cognitiveImpairment
        profilePhoto
        gender
        dob
        paymentMethods {
          ...PaymentMethodFields
        }
      }
      metadata {
        totalPages
        totalCount
        currentPage
        limitValue
      }
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;

/**
 * __useRidersPaginationQueryQuery__
 *
 * To run a query within a React component, call `useRidersPaginationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRidersPaginationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRidersPaginationQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useRidersPaginationQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RidersPaginationQueryQuery,
    RidersPaginationQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RidersPaginationQueryQuery,
    RidersPaginationQueryQueryVariables
  >(RidersPaginationQueryDocument, options);
}
export function useRidersPaginationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RidersPaginationQueryQuery,
    RidersPaginationQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RidersPaginationQueryQuery,
    RidersPaginationQueryQueryVariables
  >(RidersPaginationQueryDocument, options);
}
export type RidersPaginationQueryQueryHookResult = ReturnType<
  typeof useRidersPaginationQueryQuery
>;
export type RidersPaginationQueryLazyQueryHookResult = ReturnType<
  typeof useRidersPaginationQueryLazyQuery
>;
export type RidersPaginationQueryQueryResult = Apollo.QueryResult<
  RidersPaginationQueryQuery,
  RidersPaginationQueryQueryVariables
>;
export const RiderProfileQueryDocument = gql`
  query RiderProfileQuery($riderId: String) {
    rider(riderId: $riderId) {
      ...RiderFields
    }
    favoriteDrivers(riderId: $riderId) {
      ...DriverFields
    }
    account {
      billingEmail
    }
    profile {
      email
    }
  }
  ${RiderFieldsFragmentDoc}
  ${DriverFieldsFragmentDoc}
`;

/**
 * __useRiderProfileQueryQuery__
 *
 * To run a query within a React component, call `useRiderProfileQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiderProfileQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiderProfileQueryQuery({
 *   variables: {
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useRiderProfileQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RiderProfileQueryQuery,
    RiderProfileQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RiderProfileQueryQuery,
    RiderProfileQueryQueryVariables
  >(RiderProfileQueryDocument, options);
}
export function useRiderProfileQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RiderProfileQueryQuery,
    RiderProfileQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RiderProfileQueryQuery,
    RiderProfileQueryQueryVariables
  >(RiderProfileQueryDocument, options);
}
export type RiderProfileQueryQueryHookResult = ReturnType<
  typeof useRiderProfileQueryQuery
>;
export type RiderProfileQueryLazyQueryHookResult = ReturnType<
  typeof useRiderProfileQueryLazyQuery
>;
export type RiderProfileQueryQueryResult = Apollo.QueryResult<
  RiderProfileQueryQuery,
  RiderProfileQueryQueryVariables
>;
export const RidersQueryDocument = gql`
  query RidersQuery($query: String) {
    riders(query: $query) {
      ...RiderFields
    }
  }
  ${RiderFieldsFragmentDoc}
`;

/**
 * __useRidersQueryQuery__
 *
 * To run a query within a React component, call `useRidersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRidersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRidersQueryQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRidersQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RidersQueryQuery,
    RidersQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RidersQueryQuery, RidersQueryQueryVariables>(
    RidersQueryDocument,
    options,
  );
}
export function useRidersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RidersQueryQuery,
    RidersQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RidersQueryQuery, RidersQueryQueryVariables>(
    RidersQueryDocument,
    options,
  );
}
export type RidersQueryQueryHookResult = ReturnType<typeof useRidersQueryQuery>;
export type RidersQueryLazyQueryHookResult = ReturnType<
  typeof useRidersQueryLazyQuery
>;
export type RidersQueryQueryResult = Apollo.QueryResult<
  RidersQueryQuery,
  RidersQueryQueryVariables
>;
export const RidersSimilarQueryDocument = gql`
  query RidersSimilarQuery(
    $firstName: String!
    $lastName: String!
    $dob: String
  ) {
    ridersSimilar(firstName: $firstName, lastName: $lastName, dob: $dob) {
      id
      firstName
      lastName
      phone
      email
      dob
      historyRidesCount
      upcomingRidesCount
      paymentMethods {
        ...PaymentMethodFields
      }
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
`;

/**
 * __useRidersSimilarQueryQuery__
 *
 * To run a query within a React component, call `useRidersSimilarQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRidersSimilarQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRidersSimilarQueryQuery({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dob: // value for 'dob'
 *   },
 * });
 */
export function useRidersSimilarQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RidersSimilarQueryQuery,
    RidersSimilarQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RidersSimilarQueryQuery,
    RidersSimilarQueryQueryVariables
  >(RidersSimilarQueryDocument, options);
}
export function useRidersSimilarQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RidersSimilarQueryQuery,
    RidersSimilarQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RidersSimilarQueryQuery,
    RidersSimilarQueryQueryVariables
  >(RidersSimilarQueryDocument, options);
}
export type RidersSimilarQueryQueryHookResult = ReturnType<
  typeof useRidersSimilarQueryQuery
>;
export type RidersSimilarQueryLazyQueryHookResult = ReturnType<
  typeof useRidersSimilarQueryLazyQuery
>;
export type RidersSimilarQueryQueryResult = Apollo.QueryResult<
  RidersSimilarQueryQuery,
  RidersSimilarQueryQueryVariables
>;
export const RidesLastModifiedAtQueryDocument = gql`
  query RidesLastModifiedAtQuery($scope: RidesCollectionScope) {
    ridesLastModifiedAt(scope: $scope)
  }
`;

/**
 * __useRidesLastModifiedAtQueryQuery__
 *
 * To run a query within a React component, call `useRidesLastModifiedAtQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRidesLastModifiedAtQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRidesLastModifiedAtQueryQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useRidesLastModifiedAtQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RidesLastModifiedAtQueryQuery,
    RidesLastModifiedAtQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RidesLastModifiedAtQueryQuery,
    RidesLastModifiedAtQueryQueryVariables
  >(RidesLastModifiedAtQueryDocument, options);
}
export function useRidesLastModifiedAtQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RidesLastModifiedAtQueryQuery,
    RidesLastModifiedAtQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RidesLastModifiedAtQueryQuery,
    RidesLastModifiedAtQueryQueryVariables
  >(RidesLastModifiedAtQueryDocument, options);
}
export type RidesLastModifiedAtQueryQueryHookResult = ReturnType<
  typeof useRidesLastModifiedAtQueryQuery
>;
export type RidesLastModifiedAtQueryLazyQueryHookResult = ReturnType<
  typeof useRidesLastModifiedAtQueryLazyQuery
>;
export type RidesLastModifiedAtQueryQueryResult = Apollo.QueryResult<
  RidesLastModifiedAtQueryQuery,
  RidesLastModifiedAtQueryQueryVariables
>;
export const ScheduleQueryDocument = gql`
  query ScheduleQuery($riderId: Int) {
    riders(riderId: $riderId) {
      id
      firstName
      lastName
      phone
      email
      paymentMethods {
        ...PaymentMethodFields
      }
      heightInches
      weightLbs
      lastPaymentMethodId
      lastPaymentInvoiced
      address {
        ...LocationFields
      }
    }
    profile {
      email
      lastGeoLatitude
      lastGeoLongitude
      admin
      availableTransportTypes {
        fullName
        id
        name
        shortName
      }
      promptForFinancialResponsibility
      promptForRoomNumbers
      availablePayerTypes {
        name
        promptForPayerDemographics
      }
    }
    account {
      ...AccountFields
    }
  }
  ${PaymentMethodFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${AccountFieldsFragmentDoc}
`;

/**
 * __useScheduleQueryQuery__
 *
 * To run a query within a React component, call `useScheduleQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleQueryQuery({
 *   variables: {
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useScheduleQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScheduleQueryQuery,
    ScheduleQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduleQueryQuery, ScheduleQueryQueryVariables>(
    ScheduleQueryDocument,
    options,
  );
}
export function useScheduleQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleQueryQuery,
    ScheduleQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduleQueryQuery, ScheduleQueryQueryVariables>(
    ScheduleQueryDocument,
    options,
  );
}
export type ScheduleQueryQueryHookResult = ReturnType<
  typeof useScheduleQueryQuery
>;
export type ScheduleQueryLazyQueryHookResult = ReturnType<
  typeof useScheduleQueryLazyQuery
>;
export type ScheduleQueryQueryResult = Apollo.QueryResult<
  ScheduleQueryQuery,
  ScheduleQueryQueryVariables
>;
export const ScheduleQueryWithRideDocument = gql`
  query ScheduleQueryWithRide($rideId: Int!, $riderId: Int) {
    ride(rideId: $rideId) {
      ...RideFields
    }
    riders(riderId: $riderId) {
      id
      firstName
      lastName
      phone
      email
      paymentMethods {
        ...PaymentMethodFields
      }
      heightInches
      weightLbs
      lastPaymentMethodId
      lastPaymentInvoiced
      address {
        ...LocationFields
      }
    }
    profile {
      admin
      email
      lastGeoLatitude
      lastGeoLongitude
      availableTransportTypes {
        fullName
        id
        name
        shortName
      }
      promptForFinancialResponsibility
      promptForRoomNumbers
      availablePayerTypes {
        name
        promptForPayerDemographics
      }
    }
    account {
      ...AccountFields
    }
  }
  ${RideFieldsFragmentDoc}
  ${PaymentMethodFieldsFragmentDoc}
  ${LocationFieldsFragmentDoc}
  ${AccountFieldsFragmentDoc}
`;

/**
 * __useScheduleQueryWithRideQuery__
 *
 * To run a query within a React component, call `useScheduleQueryWithRideQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleQueryWithRideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleQueryWithRideQuery({
 *   variables: {
 *      rideId: // value for 'rideId'
 *      riderId: // value for 'riderId'
 *   },
 * });
 */
export function useScheduleQueryWithRideQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleQueryWithRideQuery,
    ScheduleQueryWithRideQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleQueryWithRideQuery,
    ScheduleQueryWithRideQueryVariables
  >(ScheduleQueryWithRideDocument, options);
}
export function useScheduleQueryWithRideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleQueryWithRideQuery,
    ScheduleQueryWithRideQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleQueryWithRideQuery,
    ScheduleQueryWithRideQueryVariables
  >(ScheduleQueryWithRideDocument, options);
}
export type ScheduleQueryWithRideQueryHookResult = ReturnType<
  typeof useScheduleQueryWithRideQuery
>;
export type ScheduleQueryWithRideLazyQueryHookResult = ReturnType<
  typeof useScheduleQueryWithRideLazyQuery
>;
export type ScheduleQueryWithRideQueryResult = Apollo.QueryResult<
  ScheduleQueryWithRideQuery,
  ScheduleQueryWithRideQueryVariables
>;
export const StripeSetupIntentQueryDocument = gql`
  query StripeSetupIntentQuery($ownerId: String!, $ownerType: String!) {
    stripeSetupIntent(ownerId: $ownerId, ownerType: $ownerType) {
      setupIntent
      ephemeralKey
      customer
      publishableKey
    }
  }
`;

/**
 * __useStripeSetupIntentQueryQuery__
 *
 * To run a query within a React component, call `useStripeSetupIntentQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeSetupIntentQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeSetupIntentQueryQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      ownerType: // value for 'ownerType'
 *   },
 * });
 */
export function useStripeSetupIntentQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    StripeSetupIntentQueryQuery,
    StripeSetupIntentQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    StripeSetupIntentQueryQuery,
    StripeSetupIntentQueryQueryVariables
  >(StripeSetupIntentQueryDocument, options);
}
export function useStripeSetupIntentQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StripeSetupIntentQueryQuery,
    StripeSetupIntentQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StripeSetupIntentQueryQuery,
    StripeSetupIntentQueryQueryVariables
  >(StripeSetupIntentQueryDocument, options);
}
export type StripeSetupIntentQueryQueryHookResult = ReturnType<
  typeof useStripeSetupIntentQueryQuery
>;
export type StripeSetupIntentQueryLazyQueryHookResult = ReturnType<
  typeof useStripeSetupIntentQueryLazyQuery
>;
export type StripeSetupIntentQueryQueryResult = Apollo.QueryResult<
  StripeSetupIntentQueryQuery,
  StripeSetupIntentQueryQueryVariables
>;
export const VetrideQueryDocument = gql`
  query vetrideQuery($vetrideRequestId: Int!) {
    vetrideRequest(vetrideRequestId: $vetrideRequestId) {
      newRide {
        ...NewRideFields
      }
      rider {
        ...RiderFields
      }
    }
  }
  ${NewRideFieldsFragmentDoc}
  ${RiderFieldsFragmentDoc}
`;

/**
 * __useVetrideQueryQuery__
 *
 * To run a query within a React component, call `useVetrideQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVetrideQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVetrideQueryQuery({
 *   variables: {
 *      vetrideRequestId: // value for 'vetrideRequestId'
 *   },
 * });
 */
export function useVetrideQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    VetrideQueryQuery,
    VetrideQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VetrideQueryQuery, VetrideQueryQueryVariables>(
    VetrideQueryDocument,
    options,
  );
}
export function useVetrideQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VetrideQueryQuery,
    VetrideQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VetrideQueryQuery, VetrideQueryQueryVariables>(
    VetrideQueryDocument,
    options,
  );
}
export type VetrideQueryQueryHookResult = ReturnType<
  typeof useVetrideQueryQuery
>;
export type VetrideQueryLazyQueryHookResult = ReturnType<
  typeof useVetrideQueryLazyQuery
>;
export type VetrideQueryQueryResult = Apollo.QueryResult<
  VetrideQueryQuery,
  VetrideQueryQueryVariables
>;
