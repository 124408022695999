import React from 'react';
import { PusherProvider } from '@harelpls/use-pusher';
import ENV from 'constants/Env';

const config = {
  // required config props
  clientKey: ENV.pusherAppKey,
  cluster: ENV.pusherCluster,
};

// Wrap app in provider
export default function RealtimeProvider({ children }) {
  return <PusherProvider {...config}>{children}</PusherProvider>;
}
