import React from 'react';
import { useStyles } from './styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { RideTimeSummaryProps } from './props';
import moment from 'moment';
import { HOUR_FORMAT_12, HOUR_FORMAT_24, formatDuration } from 'lib/TimeUtils';

const _RideTimeSummary: React.FC<RideTimeSummaryProps> = ({
  arrivalDate,
  arrivalTime,
  bufferMinutes,
  rideEstimate,
  pickupTime,
}) => {
  const classes = useStyles();
  const item = (label: string, value: string, highlight?: boolean) => {
    return (
      <Grid item xs={12}>
        <Typography component="span" variant="body1">
          <div className={classes.root}>
            <Box display="flex">
              <Box
                flexGrow={1}
                fontWeight="fontWeightMedium"
                className={classes.label}
              >
                {label}
              </Box>
              <Box
                fontWeight="fontWeightMedium"
                className={highlight ? classes.result : classes.empty}
              >
                {value}
              </Box>
            </Box>
          </div>
        </Typography>
      </Grid>
    );
  };
  const formatArrivalDateTime = (
    arrivalDate: Date,
    arrivalTime: string,
  ): string => {
    return `${moment(arrivalDate).format('MMM Do')} ${moment(
      arrivalTime,
      HOUR_FORMAT_24,
    ).format(HOUR_FORMAT_12)}`;
  };

  return (
    <Grid container className={classes.root}>
      <Typography component="span" variant="h5" className={classes.title}>
        Summary
      </Typography>
      {item(
        'Arrival Date and Time',
        formatArrivalDateTime(arrivalDate, arrivalTime),
      )}
      {item('Buffer Time', formatDuration(bufferMinutes))}
      {item('Ride Estimate', formatDuration(rideEstimate))}
      {item('Proposed Pickup Time', pickupTime, true)}
    </Grid>
  );
};

export const RideTimeSummary = React.memo(_RideTimeSummary);
