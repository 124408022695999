import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import onwardTheme from './lib/onwardTheme';
import RootRouter from './navigation/RootRouter';
import AuthProvider from './contexts/auth-context';
import OnwardApolloProvider from './data/OnwardApolloProvider';
import { ProvideTracking } from './lib/analytics/Tracker';
import { LoadingProvider } from './components/layout/Loading';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ToastProvider } from 'components/layout/Toast';
import RealtimeProvider from 'lib/RealtimeProvider';
import ErrorHandler from '@onwardcare/common/lib/ErrorHandler';
import FhirClientProvider from './contexts/ehr-context';
import ENV, { getEnvironment } from 'constants/Env';

// pick a date util library
import MomentUtils from '@date-io/moment';
import './App.css';
import { useEffect } from 'react';
import SessionProvider from './contexts/session-context';
import VetridesProvider from './contexts/vetrides-context';

function App() {
  useEffect(() => {
    ErrorHandler.init(ENV.reportErrors, ENV.sentryDsn, getEnvironment());
  }, []);

  useEffect(() => {
    const loaderContainer = document.getElementById('loader-container');
    if (loaderContainer) {
      loaderContainer.style.display = 'none';
    }
  }, []);

  return (
    <ThemeProvider theme={onwardTheme}>
      <CssBaseline />
      <VetridesProvider>
        <AuthProvider>
          {/* depends on auth */}
          <ProvideTracking>
            {/* depends on auth to sign out on 401s */}
            <OnwardApolloProvider>
              <LoadingProvider>
                <SessionProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    {/* depends on auth for private routes */}
                    <RealtimeProvider>
                      <ToastProvider>
                        <FhirClientProvider>
                          <RootRouter />
                        </FhirClientProvider>
                      </ToastProvider>
                    </RealtimeProvider>
                  </MuiPickersUtilsProvider>
                </SessionProvider>
              </LoadingProvider>
            </OnwardApolloProvider>
          </ProvideTracking>
        </AuthProvider>
      </VetridesProvider>
    </ThemeProvider>
  );
}

export default App;
