import {
  AppBar,
  Box,
  Button,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ENV, { getEnvironment } from 'constants/Env';
import onwardColors from 'lib/onwardColors';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DrawerContent from './DrawerContent';
import FloatingAvatar from './FloatingAvatar';
import { Loading } from './Loading';
import RealtimeHandler from './RealtimeHandler';
import { useSessionContext } from '../../contexts/session-context';
import { AccountQueryQuery } from 'generated/graphql';
import { useVetridesContext } from '../../contexts/vetrides-context';
import { useEhrContext } from '../../contexts/ehr-context';

const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  progressContainer: {
    width: '100%',
  },
  content: {
    flexGrow: 1,
  },
  contentInternal: {
    display: 'flex',
    height: '100vh',
  },
  contentInternalWithPadding: {
    padding: theme.spacing(3),
    height: '100vh',
    display: 'flex',
  },
  fixedAvatar: {
    position: 'fixed',
    right: 20,
    top: 20,
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  envBar: {
    backgroundColor: onwardColors.onwardBlue,
    color: 'white',
    textAlign: 'center',
  },
  bookButton: {
    marginRight: theme.spacing(2),
  },
}));

export type ResponsiveDrawerProps = {
  account: AccountQueryQuery['account']; //  Account;
  profile: AccountQueryQuery['profile']; // Custodian;
  usePadding: boolean;
};

const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = ({
  account,
  profile,
  usePadding,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isEhrPatientContext } = useEhrContext();
  const { isVetride } = useVetridesContext();
  const [mobileOpen, setMobileOpen] = useState(false);
  const env = getEnvironment();
  const location = useLocation();

  const bookingDisabled = location.pathname === '/rides/new';

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!isVetride) {
      return;
    }

    const portalDomain = ENV.portalUrl;
    window.parent.postMessage(document.body.scrollHeight, portalDomain);

    // create an Observer instance
    const resizeObserver = new ResizeObserver(entries => {
      const scrollHeight = entries[0].target.scrollHeight;
      window.parent.postMessage(scrollHeight, portalDomain);
    });

    const mutationObserver = new MutationObserver(entries => {
      for (const mutation of entries) {
        if (mutation.type === 'childList') {
          const scrollHeight = document.body.scrollHeight;
          window.parent.postMessage(scrollHeight, portalDomain);
        }
      }
    });
    // start observing a DOM node
    resizeObserver.observe(document.body);
    mutationObserver.observe(document.body, { childList: true, subtree: true });
  });

  return (
    <div className={classes.root}>
      {isEhrPatientContext || isVetride ? (
        <div className={classes.content}>
          <Loading />

          <main
            className={
              usePadding
                ? classes.contentInternalWithPadding
                : classes.contentInternal
            }
          >
            {children}
          </main>
        </div>
      ) : (
        <>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                Onward
              </Typography>
              {!location.pathname.includes('/rides/new') &&
                !location.pathname.includes('/copy') &&
                !location.pathname.includes('/edit') && (
                  <Button
                    className={classes.bookButton}
                    variant="contained"
                    color="secondary"
                    component={Link}
                    disabled={bookingDisabled}
                    to="/rides/new"
                  >
                    New Ride
                  </Button>
                )}
              <FloatingAvatar />
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="rides riders history">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden mdUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <DrawerContent account={account} profile={profile} />
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                <DrawerContent account={account} profile={profile} />
              </Drawer>
            </Hidden>
          </nav>
          <div className={classes.content}>
            <Hidden mdUp implementation="css">
              <div className={classes.toolbar} />
            </Hidden>
            <Loading />
            {false && env !== 'production' && (
              <Box className={classes.envBar}>
                <Typography variant="caption">
                  {'Environment: ' + env}
                </Typography>
              </Box>
            )}
            {location.pathname === '/' && (
              <FloatingAvatar buttonStyle={classes.fixedAvatar} />
            )}

            <main
              className={
                usePadding
                  ? classes.contentInternalWithPadding
                  : classes.contentInternal
              }
            >
              {children}
            </main>
          </div>
        </>
      )}
    </div>
  );
};

export type ResponsiveDrawerContainerProps = {
  usePadding?: 'yes' | 'no' | 'maybe';
  getAccount?: (data: AccountQueryQuery) => void;
};
// usePadding supports 3 options:
// yes: always use, eg dashboard
// no: never use, eg ride booking
// maybe: dynamic based on screen size, eg most forms
const ResponsiveDrawerContainer: React.FC<ResponsiveDrawerContainerProps> = ({
  children,
  usePadding = 'maybe',
}) => {
  const { error, loading, session } = useSessionContext();

  const theme = useTheme();
  const xsmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const shouldUsePadding =
    (usePadding === 'yes' && !xsmallScreen) ||
    (usePadding === 'maybe' && !xsmallScreen);

  if (loading || !session) {
    return null;
  }
  if (error) {
    // ErrorHandler
    console.error(error);
    return <div>Error!</div>;
  }

  return (
    <ResponsiveDrawer
      account={session.account}
      profile={session.profile}
      usePadding={shouldUsePadding}
    >
      <RealtimeHandler account={session.account} />
      {children}
    </ResponsiveDrawer>
  );
};

export default ResponsiveDrawerContainer;
