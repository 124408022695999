import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Typography } from '@material-ui/core';
import { useAuth } from '../../contexts/auth-context';
import { useApolloClient } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { personInitials } from '@onwardcare/common/lib/NameUtils';
import { useTracking } from 'lib/analytics/Tracker';
import { useSessionContext } from '../../contexts/session-context';
import { useEhrContext } from '../../contexts/ehr-context';

const useStyles = makeStyles(theme => ({
  avatar: {
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
    boxShadow: theme.shadows[1],
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
}));

const FloatingAvatar = ({ buttonStyle = '' }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let auth = useAuth();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const tracker = useTracking();
  const { session, loading, error } = useSessionContext();
  const { isEhr } = useEhrContext();
  const open = Boolean(anchorEl);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    tracker.reset();
    history && history.replace({ pathname: '/login' });
  };

  const signOut = () => {
    handleClose();
    tracker.track('Logout');
    auth.logout(apolloClient, onLogout);
  };

  if (loading || error) {
    return (
      <Skeleton>
        <Avatar
          className={`${buttonStyle} ${classes.avatar}`}
          color="inherit"
        />
      </Skeleton>
    );
  }

  if (!session) {
    return null;
  }

  const { account, profile } = session;

  return (
    <div>
      <Avatar
        className={`${buttonStyle} ${classes.avatar}`}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        data-testid="avatar"
      >
        <Typography variant="button">{personInitials(profile)}</Typography>
      </Avatar>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null} //https://stackoverflow.com/questions/48157863/how-to-make-a-dropdown-menu-open-below-the-appbar-using-material-ui
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/profile">
          Edit Profile
        </MenuItem>
        {profile?.admin || account?.channel !== 'b2b' ? (
          <MenuItem component={Link} to="/account">
            Account Settings
          </MenuItem>
        ) : null}
        {isEhr === false && <MenuItem onClick={signOut}>Sign Out</MenuItem>}
      </Menu>
    </div>
  );
};

export default FloatingAvatar;
