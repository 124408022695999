import React, { useEffect } from 'react';
import { useStyles } from './styles';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { RideTimeHelperFormProps } from './props';
import { RideTimeSummary } from '../RideTimeSummary/RideTimeSummary';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { RideTimeError } from '../RideTimeError/RideTimeError';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import onwardColors from 'lib/onwardColors';
import {
  HOUR_FORMAT_24,
  MONTH_DAY_YEAR_FORMAT,
  PICKUP_TIME_ADD,
  PICKUP_TIME_RMV,
  calculatePickupTime,
} from 'lib/TimeUtils';

const RIDE_ESTIMATE_QUERY = loader(
  '../../../../../data/queries/RideEstimateQuery.graphql',
);

const _RideTimeHelperForm: React.FC<RideTimeHelperFormProps> = ({
  defaultDate,
  defaultTime,
  arrivalDate,
  setArrivalDate,
  arrivalTime,
  setArrivalTime,
  bufferMinutes,
  setBufferMinutes,
  bufferMinutesOptions,
  ride,
  pickupTime,
  setPickupTime,
  setError,
}) => {
  const classes = useStyles();

  const filteredRide = {
    rideType: ride.rideType,
    startLocation: ride.startLocation,
    endLocation: ride.endLocation,
    requestedStartTime: ride.requestedStartTime,
    requestedEndTime: ride.requestedEndTime,
    transportType: ride.transportType,
    weightLbs: ride.weight,
    stairsCount: ride.stairsCount,
    oxygenRequired: ride.oxygenRequired,
    contactPrecautionsRequired: ride.contactPrecautionsRequired,
  };

  const { loading, error, data } = useQuery(RIDE_ESTIMATE_QUERY, {
    variables: { ride: filteredRide },
  });

  // Convert this to date to use on calculate pickup time
  useEffect(() => {
    const calculateArrivalDate = () => {
      if (
        !!data?.rideEstimate?.estimatedDurationMinutesNoRound &&
        defaultDate !== null &&
        defaultTime !== null
      ) {
        const date = calculatePickupTime({
          date: defaultDate,
          time: defaultTime,
          bufferMinutes: bufferMinutes || 0,
          rideEstimate: parseInt(
            data.rideEstimate.estimatedDurationMinutesNoRound,
          ),
          operation: PICKUP_TIME_ADD,
        });
        setArrivalDate(moment(date.clean, MONTH_DAY_YEAR_FORMAT));
        // Use 24 Format HH because the TextField only accepts this format and not hh:mm A
        setArrivalTime(moment(date.clean).format(HOUR_FORMAT_24));
      }
    };

    calculateArrivalDate();
  }, [
    data?.rideEstimate?.estimatedDurationMinutesNoRound,
    setArrivalDate,
    setArrivalTime,
    defaultDate,
    defaultTime,
  ]);

  useEffect(() => {
    if (
      !!data?.rideEstimate?.estimatedDurationMinutesNoRound &&
      arrivalDate !== null &&
      arrivalTime !== null
    ) {
      const date = calculatePickupTime({
        date: new Date(arrivalDate.toString()),
        time: moment(arrivalTime, HOUR_FORMAT_24).toDate(),
        bufferMinutes: bufferMinutes || 0,
        rideEstimate: parseInt(
          data.rideEstimate.estimatedDurationMinutesNoRound,
        ),
        operation: PICKUP_TIME_RMV,
      });
      setPickupTime(date);
    }
  }, [
    arrivalDate,
    arrivalTime,
    bufferMinutes,
    data?.rideEstimate?.estimatedDurationMinutesNoRound,
    setPickupTime,
  ]);

  if (error) {
    setError(error);
    return (
      <RideTimeError
        error=" There was an error generating your estimate. Please contact Onward at
  1-800-700-4797."
      />
    );
  }
  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress size={24} color="secondary" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.section}>
        <Grid item xs={7}>
          <LabelWithTooltip
            labelText="Arrival Day"
            tooltipText=""
            labelStyle={{
              fontSize: '14px',
              color: onwardColors.onwardLightGray,
            }}
          />
          <DatePicker
            id="arrivalDay"
            disablePast
            onChange={date => setArrivalDate(date)}
            value={arrivalDate}
          />
        </Grid>
        <Grid item xs={5}>
          <LabelWithTooltip
            labelText="Arrival Time"
            tooltipText=""
            labelStyle={{
              fontSize: '14px',
              color: onwardColors.onwardLightGray,
            }}
          />
          <TextField
            id="arrivalTime"
            type="time"
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={date => {
              setArrivalTime(date.target.value);
            }}
            defaultValue={arrivalTime}
            value={arrivalTime}
          />
        </Grid>
        <Grid item xs={12} className={classes.section}></Grid>
        <Grid item xs={12} className={classes.section}>
          <LabelWithTooltip
            labelText="Buffer Time"
            labelStyle={{
              fontSize: '14px',
              color: onwardColors.onwardLightGray,
            }}
            tooltipText="Buffer time allows for additional time for parking, loading or unloading, or finding the room or specific location."
          />
          <TextField
            id="bufferTime"
            select
            value={bufferMinutes}
            onChange={event => setBufferMinutes(parseInt(event.target.value))}
            SelectProps={{
              native: true,
            }}
            className={classes.bufferTimeTextField}
          >
            {bufferMinutesOptions.map(option => (
              <option key={option} value={option}>
                {option} minutes
              </option>
            ))}
          </TextField>
        </Grid>
        <RideTimeSummary
          pickupTime={pickupTime.formatted}
          arrivalDate={
            arrivalDate ? new Date(arrivalDate.toString()) : new Date()
          }
          arrivalTime={arrivalTime}
          rideEstimate={parseInt(
            data.rideEstimate.estimatedDurationMinutesNoRound,
          )}
          bufferMinutes={bufferMinutes || 0}
        />
      </Grid>
    </div>
  );
};

export const RideTimeHelperForm = React.memo(_RideTimeHelperForm);
