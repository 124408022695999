import React from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import { validPhone } from '@onwardcare/common/lib/FormValidation';
import { Controller } from 'react-hook-form';
import GooglePlacesAutocomplete from 'components/GooglePlacesAutocomplete';
import { useStyles } from '../../rideFormStyles';
import type { FinancialResponsibilityProps } from './props';

export const FinancialResponsibility: React.FC<FinancialResponsibilityProps> = ({
  control,
  account,
  errors,
  profile,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.fareSection}>
      <Box>
        <Grid className={classes.formControl} container>
          <Grid item>
            <span>Financial Responsibility Party</span>
          </Grid>
          <Grid container spacing={1} item>
            <Grid item xs={6}>
              <FormControl component="fieldset" style={{ flex: 1 }}>
                <Controller
                  name="ride.patient.firstName"
                  control={control}
                  rules={{ required: true }}
                  render={props => {
                    return (
                      <TextField
                        error={
                          // @ts-ignore
                          !!errors.ride?.patient?.firstName
                        }
                        fullWidth
                        label="First Name"
                        onChange={props.onChange}
                        variant="standard"
                        value={props.value ? props.value : ''}
                        helperText={
                          !!(
                            // @ts-ignore
                            errors.ride?.patient?.firstName
                          ) ? (
                            <FormHelperText error>
                              Name is required
                            </FormHelperText>
                          ) : null
                        }
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset" style={{ flex: 1 }}>
                <Controller
                  name="ride.patient.lastName"
                  control={control}
                  rules={{ required: true }}
                  render={props => {
                    return (
                      <TextField
                        error={
                          // @ts-ignore
                          !!errors.ride?.patient?.lastName
                        }
                        fullWidth
                        label="Last Name"
                        onChange={props.onChange}
                        variant="standard"
                        value={props.value ? props.value : ''}
                        helperText={
                          // @ts-ignore
                          !!errors.ride?.patient?.lastName ? (
                            <FormHelperText error>
                              Last Name is required
                            </FormHelperText>
                          ) : null
                        }
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={1} className={classes.formControl}>
          <Grid item xs={12} className={classes.withTooltip}>
            <FormControl component="fieldset" style={{ flex: 1 }}>
              <Controller
                control={control}
                name="ride.patient.address"
                onChange={([, data]: any) => data}
                render={({ onChange, ...props }) => {
                  return (
                    <GooglePlacesAutocomplete
                      homeAddress={null}
                      center={{
                        // @ts-ignore
                        latitude: profile?.lastGeoLatitude || '37.840730',
                        // @ts-ignore
                        longitude: profile?.lastGeoLongitude || '-122.251691',
                      }}
                      id="patientAddress"
                      value={props.value ? props.value : ''}
                      label="Address"
                      recentLocations={[]}
                      predefinedLocations={[]}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={1} className={classes.formControl}>
          <Grid item xs={12} className={classes.withTooltip}>
            <FormControl component="fieldset" style={{ flex: 1 }}>
              <Controller
                name="ride.patient.email"
                control={control}
                rules={{
                  validate: e => {
                    const payerEmailRequired = !!account?.payerEmailRequired;

                    if (!e && payerEmailRequired) {
                      return false;
                    }

                    if (!e) {
                      return true;
                    }

                    return /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+/.test(
                      e,
                    );
                  },
                }}
                render={props => {
                  return (
                    <TextField
                      error={
                        //@ts-ignore
                        errors.ride?.patient?.email?.type === 'validate'
                      }
                      helperText={
                        //@ts-ignore
                        errors.ride?.patient?.email?.type === 'validate' ? (
                          <FormHelperText error>
                            Correct email is required
                          </FormHelperText>
                        ) : null
                      }
                      fullWidth
                      label="Email"
                      onChange={props.onChange}
                      variant="standard"
                      value={props.value ? props.value : ''}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.withTooltip}>
            <FormControl component="fieldset" style={{ flex: 1 }}>
              <Controller
                name="ride.patient.phone"
                control={control}
                rules={{
                  required: true,
                  validate: (value: string) => {
                    return validPhone(
                      value
                        //Non-Breaking Hyphen
                        .replaceAll('\u2011', '-')
                        //Non-Breaking Space
                        .replaceAll('U+00A0', ' ')
                        .replaceAll(/\s+/g, ''),
                    );
                  },
                }}
                render={props => {
                  return (
                    <TextField
                      error={
                        //@ts-ignore
                        !!errors.ride?.patient?.phone
                      }
                      helperText={
                        //@ts-ignore
                        (errors.ride?.patient?.phone?.type === 'required' && (
                          <FormHelperText error>
                            Phone is required
                          </FormHelperText>
                        )) ||
                        //@ts-ignore
                        (errors.ride?.patient?.phone?.type === 'validate' && (
                          <FormHelperText error>
                            Phone is incorrect
                          </FormHelperText>
                        ))
                      }
                      fullWidth
                      label="Phone"
                      onChange={props.onChange}
                      variant="standard"
                      value={props.value ? props.value : ''}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
