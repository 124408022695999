import React from 'react';
import { Control, Controller } from 'react-hook-form';
import onwardColors from '../../../../lib/onwardColors';
import {
  Box,
  FormControl,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { LabelWithTooltip } from 'components/LabelWithTooltip';

type Props = {
  control: Control;
  disabled: boolean;
};

const useStyles = makeStyles(theme => ({
  fareSection: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: '95%',
    borderLeftColor: onwardColors.onwardBlue,
    borderLeftWidth: 3,
    borderLeftStyle: 'solid',
    paddingLeft: '15px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%',
  },
  heightContainer: {
    marginTop: theme.spacing(2),
  },
}));

const _FirstAvailableCheckboxComponent: React.FC<Props> = ({
  control,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.fareSection}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormControlLabel
          control={
            <Controller
              name={`ride.firstAvailable`}
              control={control}
              render={props => {
                return (
                  <Checkbox
                    disabled={disabled}
                    onChange={e => {
                      props.onChange(e.target.checked);
                    }}
                    checked={!!props.value}
                  />
                );
              }}
            />
          }
          label={
            <LabelWithTooltip
              labelText="Book as First Available"
              tooltipText="Once confirmed, the driver will have an additional 30 minutes to begin the trip"
            />
          }
        />
      </FormControl>
    </Box>
  );
};

export const FirstAvailableCheckboxComponent = React.memo(
  _FirstAvailableCheckboxComponent,
);
