import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PinIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/core/styles';
import ShowMoreText from 'react-show-more-text';

const useStyles = makeStyles(theme => ({
  name: {
    marginBottom: -1 * theme.spacing(1),
    maxHeight: theme.spacing(3),
    overflow: 'hidden',
  },
  root: {
    marginBottom: theme.spacing(1),
  },
  instructionText: {
    fontSize: '0.75rem',
    fontStyle: 'italic',
  },
  truncated: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addressContainer: {
    overflow: 'hidden',
  }
}));

const LocationRow = ({ location, showAddress, showInstructions }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" width="100%">
      <PinIcon color="primary" />
      <Box className={classes.addressContainer} display="flex" flexDirection="column" alignItems="stretch">
        <Typography className={classes.name} variant="body1">
          {location.name}
        </Typography>
        {showAddress && (
          <Typography className={classes.truncated} variant="caption">{location.address}</Typography>
        )}
        {showInstructions && (
          <ShowMoreText lines={2} className={classes.instructionText}>
            {location.instructions}
          </ShowMoreText>
        )}
      </Box>
    </Box>
  );
};

export default LocationRow;
