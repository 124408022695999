import { useEffect, useState, useMemo, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Room as PinIcon, Home as HomeIcon } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { Box } from '@material-ui/core';

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
}));

const GooglePlacesAutocomplete = function ({
  recentLocations,
  predefinedLocations,
  homeAddress,
  inputStyle,
  label,
  onChange,
  center = { latitude: '37.840730', longitude: '-122.251691' },
  inputError,
  helperText,
  value,
  onInputChange,
  ...otherProps
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  const formatOption = option => {
    let zipcode = null;

    const postalCodeComponent = option.address_components.find(c => {
      return c.types.includes('postal_code');
    });
    if (postalCodeComponent) {
      zipcode = postalCodeComponent.short_name;
    }

    return {
      name: option.name,
      address: option.formatted_address,
      latitude: option.geometry.location.lat(),
      longitude: option.geometry.location.lng(),
      zipcode,
    };
  };

  const formatSelection = selection => {
    const {
      id,
      name,
      address,
      latitude,
      longitude,
      zipcode,
      instructions,
    } = selection;
    return { id, name, address, latitude, longitude, zipcode, instructions };
  };

  useEffect(() => {
    let active = true;
    let recentOptions = recentLocations.map(place => ({
      ...place,
      isRecentLocation: true,
    }));

    let presets = predefinedLocations.map(place => ({
      ...place,
      isPredefinedLocation: true,
    }));

    const filteredRecentOptions = recentOptions.reduce((result, resentLoc) => {
      const dedupPresets = presets.findIndex(
        loc => loc.name === resentLoc.name
      );

      const withHomeAddressDuplicate =
        homeAddress && homeAddress.name === resentLoc.name;

      if (dedupPresets === -1 && !withHomeAddressDuplicate) {
        result.push(resentLoc);
      }

      return result;
    }, []);

    const homeAddressOptions = homeAddress
      ? { ...homeAddress, isHomeAddress: true }
      : null;
    const combinedOptions = [
      ...(homeAddressOptions ? [homeAddressOptions] : []),
      ...presets,
      ...filteredRecentOptions,
    ].slice(0, 10);


    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current || inputValue === '') {
      setOptions(combinedOptions);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        location: new window.google.maps.LatLng(
          center.latitude,
          center.longitude,
        ),
        radius: '160000',
        components: 'country:us',
      },
      results => {
        if (active) {
          let newOptions = combinedOptions.filter(place => {
            const lowerCaseInput = inputValue.toLowerCase();

            return (
              place.name.toLowerCase().indexOf(lowerCaseInput) > -1 ||
              place.address.toLowerCase().indexOf(lowerCaseInput) > -1
            );
          });

          if (results) {
            newOptions = [
              ...newOptions,
              ...results.map(option => {
                return {
                  name: option.structured_formatting.main_text,
                  address: `${option.structured_formatting.main_text}, ${option.structured_formatting.secondary_text}`,
                  reference: option.reference,
                };
              }),
            ];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [
    inputValue,
    recentLocations,
    center.latitude,
    center.longitude,
    fetch,
    predefinedLocations,
    homeAddress,
  ]);

  const handleSelection = (_, newValue) => {
    if (newValue) {
      if (
        newValue.isRecentLocation ||
        newValue.isPredefinedLocation ||
        newValue.isHomeAddress
      ) {
        onChange(formatSelection(newValue));
      } else {
        let s = new window.google.maps.places.PlacesService(
          document.createElement('div'),
        );
        s.getDetails({ reference: newValue.reference }, details => {
          onChange(formatSelection(formatOption(details)));
        });
      }
    } else {
      onChange(null);
    }
  };

  const pinIconElement = useCallback(option => {
    switch (true) {
      case option.isPredefinedLocation:
        return <PinIcon style={{ color: 'green' }} className={classes.icon} />;
      case option.isRecentLocation:
        return <PinIcon color="secondary" className={classes.icon} />;
      case option.isHomeAddress:
        return (
          <HomeIcon style={{ color: 'purple' }} className={classes.icon} />
        );
      default:
        return <PinIcon color="inherit" className={classes.icon} />;
    }
  }, [classes.icon]);

  return (
    <Autocomplete
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.address
      }
      data-testid="google-autocomplete"
      value={value}
      noOptionsText={inputValue.length > 0 ? 'No matches' : 'Start typing...'}
      filterOptions={x => x} // don't filter
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      onChange={handleSelection}
      onInputChange={(_, newInputValue) => {
        onInputChange?.(newInputValue);
        setInputValue(newInputValue);
      }}
      getOptionSelected={(option, selectedValue) =>
        option.name === selectedValue.name
      }
      renderInput={params => (
        <TextField
          {...params}
          data-testid="google-autocomplete-text"
          label={label}
          name={label}
          error={inputError}
          helperText={helperText}
          className={inputStyle}
        />
      )}
      renderOption={option => {
        return (
          <Grid container alignItems="center">
            <Grid item>{pinIconElement(option)}</Grid>
            <Grid item xs>
              <Box
                fontWeight={
                  options.isRecentLocation ? 'fontWeightMedium' : 'normal'
                }
              >
                <Typography variant="body1">{option.name}</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                {option.address}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default GooglePlacesAutocomplete;
