import { formattedTimeInZone } from '../../lib/TimeUtils';
import { Ride } from '../../generated/graphql';
import moment from '@onwardcare/common/lib/moment-timezone';

export const getBestTimeText = (ride: Ride) => {
  const {
    bidRequestedStartTime,
    biddingWindowEndsAt,
    originalRequestedStartTime,
    driver,
    tzName,
  } = ride;

  let bestTimeModalText = '';

  const biddingTimeEndsAt = formattedTimeInZone({
    time: biddingWindowEndsAt,
    tzName,
  });

  const isBestTimeEnabled = !!biddingWindowEndsAt;
  const isBiddingWindowOpened =
    isBestTimeEnabled && moment().isBefore(moment(biddingWindowEndsAt));

  // NOTE:
  // - after bidding window was closed, the ride becomes a usuall ride (with isBestTimeEnabled = false)
  // - if a best time ride was accepted by a driver/dispatcher at requested start time, it becomes a usuall ride

  if (isBiddingWindowOpened) {
    // 1. Best Time is enabled for the ride and bidding window is still opened
    bestTimeModalText = `Best Time bidding ends at ${biddingTimeEndsAt}.`;
  } else if (isBestTimeEnabled) {
    // 2. Best Time is enabled for the ride, but bidding window ended -- need to refresh page to sync ride
    bestTimeModalText = `Best Time bidding ended. Please refresh the page.`;
  } else if (!!bidRequestedStartTime) {
    // 3. Ride has a winning Best Time bid (isBestTimeEnabled will be false at this time)
    let driverName = '';

    if (driver) {
      const { firstName, lastName } = driver;

      if (firstName && lastName) {
        driverName = `${firstName} ${lastName}`;
      }
    }

    const originalStartTime = `${formattedTimeInZone({
      time: originalRequestedStartTime,
      tzName: tzName,
    })}`;

    const bidRequestedTime = `${formattedTimeInZone({
      time: bidRequestedStartTime,
      tzName: tzName,
    })}`;

    bestTimeModalText = `Driver ${driverName} accepted a ride via a Best Time Bid.
     Ride updated from ride requested time at ${originalStartTime} to a ${bidRequestedTime}.`;
  }

  return bestTimeModalText;
};
