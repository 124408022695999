import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { validEmail } from '@onwardcare/common/lib/FormValidation';
import { useTracking } from 'lib/analytics/Tracker';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import onwardPin from '../images/logo192.png';
import ErrorHandler from '@onwardcare/common/lib/ErrorHandler';
import ENV from '../constants/Env';

const { apiUrl, webAppHost } = ENV;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://onwardrides.com/">
        Onward Health, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  helpText: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

function ForgotPasswordComponent({ forgotError, onForgot, success }) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    onForgot(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar alt="Onward Pin" className={classes.avatar} src={onwardPin} />

        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        {forgotError && (
          <Alert severity="error" className={classes.alert}>
            We weren't able to process your request. Please try again later.
          </Alert>
        )}
        {success && (
          <Alert severity="success" className={classes.alert}>
            Check your email for an email that will allow you to reset your
            password.
          </Alert>
        )}

        {!success && (
          <>
            <Typography
              component="h2"
              variant="body1"
              align="center"
              className={classes.helpText}
            >
              Enter your email address and we will email you a link to reset
              your password
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={errors.email}
                helperText={
                  errors.email &&
                  ((errors.email.type === 'required' && 'Email is required') ||
                    (errors.email.type === 'validate' && 'Email is invalid'))
                }
                inputRef={register({
                  required: true,
                  validate: e => !!validEmail(e),
                })}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to="/login" variant="body2">
                    Login
                  </Link>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Don't have an account?{' '}
                    <Link
                      variant="body2"
                      href="mailto:support@onwardrides.com?subject=Onward Rides Sign-up Request"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact Us
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default function ForgotPasswordPage() {
  const [forgotError, setForgotError] = useState(false);
  const [success, setSuccess] = useState(false);

  let tracker = useTracking();

  useEffect(() => {
    tracker.track('Forgot Password Viewed');
  }, [tracker]);

  const onSuccess = () => {
    tracker.track('Forgot Password Success');

    setSuccess(true);
    setForgotError(false);
  };

  const onFailure = () => {
    tracker.track('Forgot Password Error');

    setForgotError(true);
  };

  let forgotPassword = async data => {
    const { email } = data;
    tracker.track('Forgot Password Form Submitted');

    const resp = await fetch(`${apiUrl}/custodian_auth/password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        redirect_url: `${webAppHost}/updatePassword`,
      }),
    });

    if (resp.status === 200) {
      onSuccess();
    } else {
      ErrorHandler.captureMessage(
        'Error initiated forgot password for: ' + email,
      );
      onFailure();
    }
  };

  return (
    <ForgotPasswordComponent
      forgotError={forgotError}
      success={success}
      onForgot={forgotPassword}
    />
  );
}
