import {
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HistoryIcon from '@material-ui/icons/History';
import { centsToFormattedDollars } from '@onwardcare/common/lib/MoneyUtils';
import { formattedTimeInZone, fullDate } from 'lib/TimeUtils';
import RideTypeBadge from 'components/rides/RideTypeBadge';
import TransportTypeBadge from 'components/rides/TransportTypeBadge';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUISortOptions,
} from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccountCustodiansQueryQuery,
  Custodian,
  FiltersInput,
  Ride,
  RidesCollectionScope,
  RidesPaginationQueryQuery,
  useRidesPaginationQueryLazyQuery,
} from '../../generated/graphql';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import moment from '@onwardcare/common/lib/moment-timezone';
import { firstLastInitial, personName } from '@onwardcare/common/lib/NameUtils';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDownloadCsv } from '../useCSVData';
import { LabelWithTooltip } from '../LabelWithTooltip';
import { upcomingRidesStorage } from '../../storages/storage';
import { DashboardFilterLables } from '../common/DashboardFilterLables';
import { getBestTimeText } from '../common/BestTimeMessages';
import { useSessionContext } from '../../contexts/session-context';

type SortType = {
  sortDirection: MUISortOptions['direction'];
  sortBy: string;
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 600,
  },
  loading: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noRecords: {
    fontSize: theme.typography.h6.fontSize,
    marginTop: theme.spacing(6),
  },
  detailGroup: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  detailField: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  detailLabel: {
    whiteSpace: 'nowrap',
  },
}));

const defaultLimits = {
  page: 0,
  limit: 25,
  sort: null as any,
};

type Props = {
  dataCustodians: AccountCustodiansQueryQuery | undefined;
  riderId?: string;
  showSingleRider?: boolean;
  updateRides: (updateFn: () => void) => void;
};

export const UpcomingRidesTable: React.FC<Props> = ({
  dataCustodians,
  riderId,
  showSingleRider,
  updateRides,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { session } = useSessionContext();
  const availableTransportTypes = session?.profile?.availableTransportTypes;
  const history = useHistory();

  const [
    getPagingHistory,
    { data: dataHistory, loading: loadingRides },
  ] = useRidesPaginationQueryLazyQuery({ fetchPolicy: 'no-cache' });

  const [result, setResult] = useState<RidesPaginationQueryQuery | null>(null);

  const rides = result?.ridesCollection.collection;
  const metaData = result?.ridesCollection.metadata;

  // If we are showing rides for a single rider, then we don't want to load any
  // options from storage because we want the table to be using the defaults.
  const upcomingRidesTableOptions = showSingleRider
    ? {}
    : upcomingRidesStorage.getData();

  // Will not save table options to storage if we are just showing a single rider.
  const saveToStorage = (value: any) => {
    if (!showSingleRider) {
      upcomingRidesStorage.setData(value);
    }
  };

  let initialLimits = defaultLimits;

  if (upcomingRidesTableOptions) {
    const { limit, sortingValue, page } = upcomingRidesTableOptions;
    initialLimits = {
      page: page || 0,
      limit: limit || 25,
      sort: sortingValue
        ? {
            sortBy: sortingValue[0],
            sortDirection: sortingValue[1],
          }
        : showSingleRider
        ? { sortBy: 'requestedStartTime', sortDirection: 'asc' }
        : null,
    };
  }

  const [limits, setLimits] = useState<{
    page: number;
    limit: number;
    sort: SortType | null;
  }>(initialLimits);

  const [search, setSearch] = useState(() => {
    let initialSearch = '';
    upcomingRidesTableOptions?.filters?.forEach(cf => {
      if (cf.field === 'rider') {
        initialSearch = cf.value?.[0] || '';
      }
    });
    return initialSearch;
  });

  const [filters, setFilters] = useState<Array<FiltersInput>>(
    upcomingRidesTableOptions?.filters || [],
  );

  const downloadCSV = useDownloadCsv(
    RidesCollectionScope.Upcoming,
    filters,
    search,
  );

  const defaultColumns: MUIDataTableColumn[] = React.useMemo(() => {
    const filtersValue = filters.reduce((acc, curr) => {
      acc[curr.field] = curr.value;
      return acc;
    }, {} as any);

    return [
      {
        name: 'id',
        options: {
          display: false,
          filter: false,
          sort: !showSingleRider,
          sortThirdClickReset: true,
        },
      },
      {
        name: 'requestedStartTime',
        label: DashboardFilterLables.start_time,
        options: {
          filterList: filtersValue['requestedStartTime'],
          customHeadLabelRender: () => (
            <th style={{ minWidth: 120, fontWeight: 700 }}>Start Time</th>
          ),
          customBodyRender: (_, tableMeta) => {
            const ride = rides?.[tableMeta.rowIndex];

            if (!ride) {
              return null;
            }

            const bestTimeBidText = getBestTimeText(ride as Ride);

            if (
              !bestTimeBidText &&
              ride.firstAvailable &&
              ride.status === 'pending'
            ) {
              return (
                <LabelWithTooltip
                  onHover
                  labelText={
                    <Typography className={classes.bold}>
                      First Available
                    </Typography>
                  }
                  tooltipText="Once confirmed, the driver will have an additional 30 minutes to begin the trip."
                />
              );
            }

            let ToolTip = null;
            if (bestTimeBidText) {
              ToolTip = (
                <LabelWithTooltip
                  icon={<HistoryIcon />}
                  labelText=""
                  tooltipText={bestTimeBidText}
                />
              );
            } else if (ride?.firstAvailable && ride.status !== 'pending') {
              ToolTip = (
                <LabelWithTooltip
                  onHover
                  labelText={
                    <Typography className={classes.bold}>
                      First Available
                    </Typography>
                  }
                  tooltipText="The driver will have an additional 30 minutes to begin the trip."
                />
              );
            }

            const startTime = ride.actualStartTime || ride.requestedStartTime;

            return startTime ? (
              <Typography className={classes.bold}>
                {fullDate(moment(startTime).tz(ride.tzName))}
                <div style={{ display: 'flex' }}>
                  {formattedTimeInZone({
                    time: startTime,
                    tzName: ride.tzName,
                  })}
                  {ToolTip}
                </div>
              </Typography>
            ) : null;
          },
          sortDescFirst: true,
          sort: !showSingleRider,
          filter: !showSingleRider,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              const { start_date, end_date } = DashboardFilterLables;

              let min = v[0];
              let max = v[1];
              if (min && max) {
                return [
                  `${start_date}: ${fullDate(min)}`,
                  `${end_date}: ${fullDate(max)}`,
                ];
              } else if (v[0]) {
                return `${start_date}: ${fullDate(min)}`;
              } else if (v[1]) {
                return `${end_date}: ${fullDate(max)}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Date Range</FormLabel>
                <FormGroup row>
                  <KeyboardDatePicker
                    clearable
                    autoOk
                    value={filterList[index][0] || null}
                    placeholder="10/10/2018"
                    onChange={date => {
                      if (date) {
                        filterList[index][0] = date.toISOString();
                        if (!filterList[index][1]) {
                          filterList[index][1] = '';
                        }
                        onChange(filterList[index], index, column);
                      } else {
                        filterList[index][0] = '';
                        onChange(filterList[index], index, column);
                      }
                    }}
                    format="MM/DD/YYYY"
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <KeyboardDatePicker
                    clearable
                    autoOk
                    value={filterList[index][1] || null}
                    placeholder="10/10/2018"
                    onChange={date => {
                      if (date) {
                        filterList[index][1] = date.toISOString();
                        if (!filterList[index][0]) {
                          filterList[index][0] = '';
                        }
                        onChange(filterList[index], index, column);
                      } else {
                        filterList[index][1] = '';
                        onChange(filterList[index], index, column);
                      }
                    }}
                    format="MM/DD/YYYY"
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: 'rider',
        label: DashboardFilterLables.rider,
        options: {
          display: !showSingleRider,
          filterList: filtersValue['rider'],
          customHeadLabelRender: () => (
            <th style={{ minWidth: 120, fontWeight: 700 }}>Rider</th>
          ),
          sort: !showSingleRider,
          filterType: 'textField',
          customBodyRender: value => (
            <Typography className={classes.bold}>
              {personName(value)}
            </Typography>
          ),
          customFilterListOptions: {
            render: v => {
              return `${DashboardFilterLables.rider}: ${v}`;
            },
          },
        },
      },
      {
        name: 'grouped_status',
        label: DashboardFilterLables.status,
        options: {
          filterList: filtersValue['grouped_status'],
          sort: !showSingleRider,
          sortThirdClickReset: true,
          customHeadLabelRender: () => (
            <th style={{ minWidth: 120 }}>Driver Details</th>
          ),
          customBodyRender: (_, tableMeta) => {
            const ride = rides?.[tableMeta.rowIndex];
            if (!ride) {
              return null;
            }

            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  <b>Status:</b> {ride.status}
                </span>

                {ride.status === 'pending' ? (
                  <span>Driver Pending</span>
                ) : ride.transportType === 'rideshare' &&
                  ride.driver?.isPlaceholder ? (
                  <span>Uber Driver to be Assigned</span>
                ) : (
                  ride.driver && (
                    <>
                      <span>
                        <b>
                          {ride.driver.transportPartner?.name || ''} Driver:
                        </b>{' '}
                        {ride.driver.firstName} {ride.driver.lastName}
                      </span>
                      {ride.driver?.currentVehicle && (
                        <span>
                          <b>Vehicle:</b> {ride.driver.currentVehicle.color}{' '}
                          {ride.driver.currentVehicle.make}{' '}
                          {ride.driver.currentVehicle.model} <b>License:</b>{' '}
                          {ride.driver.currentVehicle.licensePlate}
                        </span>
                      )}
                    </>
                  )
                )}
              </div>
            );
          },
          customFilterListOptions: {
            render: v => {
              return `${DashboardFilterLables.status}: ${v}`;
            },
          },
          filterOptions: {
            names: ['pending', 'confirmed', 'in-progress'],
          },
        },
      },
      {
        name: 'custodian',
        label: DashboardFilterLables.custodian,
        options: {
          filterList: filtersValue['custodian'],
          customHeadLabelRender: () => (
            <th style={{ minWidth: 100, fontWeight: 700 }}>Custodian</th>
          ),
          customFilterListOptions: {
            render: v => {
              const selectedCustodians = dataCustodians?.accountCustodians?.filter(
                custodian => v.includes(custodian.id),
              );

              if (selectedCustodians) {
                return selectedCustodians.map(selectedCustodian => {
                  if (session?.profile?.id === selectedCustodian.id) {
                    return 'My Rides';
                  }

                  return `${DashboardFilterLables.custodian}: ${
                    selectedCustodian.firstName || ''
                  }
                    ${selectedCustodian.lastName || ''}`;
                });
              }

              return [];
            },
          },

          filterOptions: {
            display: (filterList, onChange, index, column) => {
              const result = dataCustodians?.accountCustodians?.reduce(
                (prev, current) => {
                  if (current.id === session?.profile?.id) {
                    return [current as Custodian, ...prev];
                  }
                  prev.push(current as Custodian);
                  return prev;
                },
                [] as Array<Custodian>,
              );
              return (
                <FormControl fullWidth>
                  <FormLabel>Custodian</FormLabel>
                  <Select
                    multiple
                    labelId="select-accountCustodians-label"
                    id="accountCustodiansSelect"
                    value={filterList[index]}
                    onChange={event => {
                      filterList[index] = event.target.value as string[];
                      onChange(filterList[index], index, column);
                    }}
                  >
                    {result?.map(custodian => {
                      return (
                        <MenuItem key={custodian.id} value={custodian.id}>
                          {session?.profile?.id === custodian.id
                            ? 'My Rides'
                            : `${custodian?.firstName} ${custodian.lastName}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            },
          },
          filterType: 'custom',
          sort: !showSingleRider,
          customBodyRender: value => (
            <Typography className={classes.bold}>
              {personName(value)}
            </Typography>
          ),
        },
      },
      {
        name: 'rideType',
        label: DashboardFilterLables.type,
        options: {
          display: false,
          filter: false,
          sort: !showSingleRider,
          customBodyRender: value => <RideTypeBadge rideType={value} />,
        },
      },
      {
        name: 'transportType',
        label: DashboardFilterLables.transport_type,
        options: {
          filterList: filtersValue['transportType'],
          display: false,
          sort: !showSingleRider,
          customBodyRender: (_, tableMeta) => {
            const ride = rides?.[tableMeta.rowIndex];
            if (!ride) {
              return null;
            }

            const transportType = ride.custodian?.availableTransportTypes?.find(
              at => at.name === ride.transportType,
            );

            return <TransportTypeBadge transportType={transportType} />;
          },
          filterType: 'multiselect',
          customFilterListOptions: {
            render: v => {
              return `${DashboardFilterLables.transport_type}: ${
                availableTransportTypes?.find(at => at.name === v)?.fullName ||
                ''
              }`;
            },
          },
          filterOptions: {
            names: availableTransportTypes?.map(t => t.name),
            renderValue: t =>
              availableTransportTypes?.find(at => at.name === t)?.fullName ||
              '',
          },
        },
      },
      {
        name: 'grouped_type',
        label: DashboardFilterLables.ride_type,
        options: {
          filterList: filtersValue['grouped_type'],
          customHeadLabelRender: () => <div>Type</div>,
          customBodyRender: (_, tableMeta) => {
            const ride = rides?.[tableMeta.rowIndex];
            if (!ride) {
              return null;
            }

            const transportType = ride.custodian?.availableTransportTypes?.find(
              at => at.name === ride.transportType,
            );

            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RideTypeBadge rideType={ride.rideType} />
                <br />
                <TransportTypeBadge transportType={transportType} />
                {ride.batchId && (
                  <>
                    <br />
                    <RideTypeBadge rideType={'recurring'} />
                  </>
                )}
              </div>
            );
          },
          filter: !showSingleRider,
          sort: !showSingleRider,
          filterOptions: {
            names: ['Oneway', 'Wait and Return', 'Delivery'],
          },
          customFilterListOptions: {
            render: v => {
              return `${DashboardFilterLables.type}: ${v}`;
            },
          },
        },
      },
      {
        name: 'actualCostCents',
        label: DashboardFilterLables.cost,
        options: {
          filterList: filtersValue['actualCostCents'],
          display: false,
          customBodyRender: (_, tableMeta) => {
            const ride = rides?.[tableMeta.rowIndex];
            if (!ride) {
              return null;
            }
            const price = ride?.estimatedCostCents || ride?.actualCostCents;
            return (
              <Typography className={classes.bold}>
                {centsToFormattedDollars(price)}
              </Typography>
            );
          },
          filter: !showSingleRider,
          sort: !showSingleRider,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => {
              const { min_cost, max_cost } = DashboardFilterLables;

              let min = v[0];
              if (min) min *= 100;
              let max = v[1];
              if (max) max *= 100;
              if (min && max) {
                return [
                  `${min_cost}: ${centsToFormattedDollars(min)}`,
                  `${max_cost}: ${centsToFormattedDollars(max)}`,
                ];
              } else if (v[0]) {
                return `${min_cost}: ${centsToFormattedDollars(min)}`;
              } else if (v[1]) {
                return `${max_cost}: ${centsToFormattedDollars(max)}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            logic(cost, filters) {
              let min = filters[0];
              if (min) min *= 100;
              let max = filters[1];
              if (max) max *= 100;

              if ((min || max) && cost === '') return true;
              const costCents = parseInt(
                cost?.toString()?.replace(/[^\d]/g, ''),
              );

              if (min && max) {
                return costCents < min || costCents > max;
              } else if (min) {
                return costCents < min;
              } else if (max) {
                return costCents > max;
              }

              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Cost ($)</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      if (!filterList[index][1]) {
                        filterList[index][1] = '';
                      }
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      if (!filterList[index][0]) {
                        filterList[index][0] = '';
                      }
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name: 'actualDistanceMiles',
        label: DashboardFilterLables.distance,
        options: {
          filterList: filtersValue['actualDistanceMiles'],
          display: false,
          customBodyRender: (_, tableMeta) => {
            const ride = rides?.[tableMeta.rowIndex];
            if (!ride) {
              return null;
            }
            const distance =
              ride?.actualDistanceMiles || ride.estimatedDistanceMiles; // actualDistanceMiles - не возвращается из пагинированного запроса
            return distance ? `${distance} miles` : null;
          },
          sortDescFirst: true,
          sort: !showSingleRider,
          filterType: 'multiselect',
          filterOptions: {
            names: ['Short', 'Medium', 'Long'],
            renderValue: value => {
              if (value === 'Short') {
                return 'Short (< 5 miles)';
              }
              if (value === 'Medium') {
                return 'Medium (5 - 20 miles)';
              }
              if (value === 'Long') {
                return 'Long (> 20 miles)';
              }
              return value;
            },
            logic(distance, filterVal) {
              if (filterVal.length && distance === null) return true;
              const parsedDistance = parseInt(distance.replace(/[^\d]/g, ''));
              const show =
                (filterVal.includes('Short') && parsedDistance < 5) ||
                (filterVal.includes('Medium') &&
                  parsedDistance >= 5 &&
                  parsedDistance < 20) ||
                (filterVal.includes('Long') && parsedDistance >= 20);
              return !show;
            },
          },
          customFilterListOptions: {
            render: v => {
              return `${DashboardFilterLables.distance}: ${v || ''}`;
            },
          },
        },
      },
      {
        name: 'driver',
        label: DashboardFilterLables.driver,
        options: {
          display: false,
          filterList: filtersValue['driver'],
          customHeadLabelRender: () => (
            <th style={{ minWidth: 200, fontWeight: 700 }}>Driver</th>
          ),
          sort: !showSingleRider,
          filterType: 'textField',
          customFilterListOptions: {
            render: v => {
              return `${DashboardFilterLables.driver}: ${v}`;
            },
          },
          customBodyRender: value =>
            value ? (
              <Typography className={classes.bold}>
                {firstLastInitial(value)}
              </Typography>
            ) : null,
        },
      },
      {
        name: 'grouped_details',
        label: DashboardFilterLables.ride_details,
        options: {
          customBodyRender: (_, tableMeta) => {
            const ride = rides?.[tableMeta.rowIndex];
            if (!ride) {
              return null;
            }

            const {
              estimatedCostCents,
              actualCostCents,
              actualDistanceMiles,
              estimatedDistanceMiles,
            } = ride;
            const priceFieldValue = estimatedCostCents || actualCostCents;
            const price = priceFieldValue === 0 ? null : priceFieldValue;
            const distance = actualDistanceMiles || estimatedDistanceMiles;

            return (
              <div
                style={{
                  minWidth: 200,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <span>
                  <b>Start:</b> {ride.startLocation.address}
                </span>
                <span>
                  <b>End:</b> {ride.endLocation?.address || ''}
                </span>
                <span className={classes.detailGroup}>
                  <span className={classes.detailField}>
                    <b className={classes.detailLabel}>Ride ID:</b> {ride.id}
                  </span>
                  {price && (
                    <span className={classes.detailField}>
                      <b className={classes.detailLabel}>Cost:</b>{' '}
                      {centsToFormattedDollars(price)}
                    </span>
                  )}
                  {distance && (
                    <span className={classes.detailField}>
                      <b className={classes.detailLabel}>Distance:</b>
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {distance} miles
                      </span>
                    </span>
                  )}
                </span>
              </div>
            );
          },
          filter: false,
          sort: !showSingleRider,
        },
      },
      {
        name: 'startLocation',
        label: DashboardFilterLables.start_location,
        options: {
          display: false,
          customHeadLabelRender: () => (
            <th style={{ minWidth: 200, fontWeight: 500 }}>Start Location</th>
          ),
          filter: false,
          sort: !showSingleRider,
          customBodyRender: startLocation =>
            startLocation ? (
              <Typography className={classes.bold}>
                {startLocation.address}
              </Typography>
            ) : null,
        },
      },
      {
        name: 'endLocation',
        label: DashboardFilterLables.end_location,
        options: {
          display: false,
          customHeadLabelRender: () => (
            <th style={{ minWidth: 200, fontWeight: 500 }}>End Location</th>
          ),
          filter: false,
          sort: !showSingleRider,
          customBodyRender: endLocation =>
            endLocation ? (
              <Typography className={classes.bold}>
                {endLocation.address}
              </Typography>
            ) : null,
        },
      },
    ];
  }, [
    availableTransportTypes,
    rides,
    session?.profile?.id,
    dataCustodians?.accountCustodians,
    session?.account?.firstAvailableBookingEnabled,
    showSingleRider,
  ]);

  const [columns, setColumns] = useState<MUIDataTableColumn[]>(defaultColumns);
  const debouncedSetFilters = useCallback(debounce(setFilters, 300), [
    setFilters,
  ]);

  useEffect(() => {
    debouncedSetFilters(f => {
      const riderFilter = riderId
        ? { field: 'rider_id', value: [riderId] }
        : { field: 'rider', value: search ? [search] : [] };
      const selectedRiderFilterIndex = f.findIndex(
        currentFilter => currentFilter.field === 'rider',
      );
      if (selectedRiderFilterIndex === -1) {
        saveToStorage({ filters: [...f, riderFilter] });
        return [...f, riderFilter];
      }
      const finalFilters = [
        ...f.slice(0, selectedRiderFilterIndex),
        riderFilter,
        ...f.slice(selectedRiderFilterIndex + 1),
      ];

      saveToStorage({ filters: finalFilters });
      return finalFilters;
    });
  }, [debouncedSetFilters, riderId, search]);

  useEffect(() => {
    const variables = {
      page: limits.page,
      limit: limits.limit,
      query: '',
      scope: RidesCollectionScope.Upcoming,
      ...limits.sort,
      filters: filters,
    };

    updateRides(() => {
      return getPagingHistory({
        variables,
      });
    });
  }, [limits, filters, getPagingHistory, updateRides]);

  useEffect(() => {
    const upcomingRidesTableOptions = upcomingRidesStorage.getData();

    // If we are showing a single rider, then we don't want to load any options
    // from storage because we want the table to be using the defaults.
    if (upcomingRidesTableOptions && showSingleRider !== true) {
      const { columnView } = upcomingRidesTableOptions;
      if (columnView) {
        setColumns(prev => {
          let changedColumns = cloneDeep(prev);
          const newDefaultColumns = cloneDeep(defaultColumns);

          Object.entries(columnView).forEach(([key, value]) => {
            changedColumns = newDefaultColumns.map(defaultColumn => {
              if (defaultColumn.name === key) {
                defaultColumn.options = {
                  ...defaultColumn.options,
                  display: value !== 'remove',
                };
              }

              return defaultColumn;
            });
          });
          return changedColumns;
        });
      } else {
        setColumns(defaultColumns);
      }
    } else {
      setColumns(defaultColumns);
    }
  }, [defaultColumns, showSingleRider]);

  useEffect(() => {
    if (dataHistory) {
      setResult({ ...dataHistory });
    }
  }, [dataHistory]);

  useEffect(() => {
    // TODO: Take a look at this when used on the "Upcoming Rides" page as well.
    // It seems  that it's also calling this query twice. Once with no filter,
    // then a second time with a filter with an empty rider. Possibly an issue
    // with how we are handling the filters?

    // Skip calling the query if we are showing a single rider and there are no
    // filters. It seems that initially, the filters are empty and it will make
    // a call, then the filters get added and it makes a second one.
    if (showSingleRider && filters.length === 0) {
      return;
    }

    getPagingHistory({
      variables: {
        page: limits.page,
        limit: limits.limit,
        query: '',
        scope: RidesCollectionScope.Upcoming,
        ...limits.sort,
        filters: filters,
      },
    });
  }, [getPagingHistory, limits, filters, showSingleRider]);

  const buildFilters = (filterList?: Array<string[]>) => {
    const currentFilters: Array<FiltersInput> = [];
    filterList?.forEach((filter, ind) => {
      if (filter.length !== 0) {
        if (typeof columns[ind] !== 'string') {
          currentFilters.push({
            field: columns[ind].name,
            value: filter,
          });
        }
      }
    });
    return currentFilters;
  };

  const handleFilterSubmit = (
    applyFilters?: (...args: any[]) => Array<string[]>,
  ) => {
    let filterList = applyFilters?.();
    const currentFilters = buildFilters(filterList);
    setFilters(currentFilters);

    currentFilters.forEach(cf => {
      if (cf.field === 'rider') {
        cf?.value && setSearch(cf.value?.[0]);
      }
    });

    saveToStorage({
      filters: currentFilters,
    });
  };

  if (!rides || !metaData) {
    return null;
  }

  const options: MUIDataTableOptions = {
    onFilterDialogOpen: () => {
      if (smallScreen) {
        setTimeout(() => {
          const elements = document.getElementsByClassName(
            'MuiPaper-root MuiPopover-paper MuiPaper-elevation2 MuiPaper-rounded',
          ) as HTMLCollectionOf<HTMLElement>;
          if (elements) {
            elements[0].style.maxWidth = '100%';
            elements[0].style.left = '0';
            elements[0].style.top = '0';
          }
        }, 10);
      }
    },
    print: !showSingleRider,
    search: !showSingleRider,
    viewColumns: !showSingleRider,
    download: false,
    filter: !showSingleRider,
    serverSide: true,
    confirmFilters: true,
    enableNestedDataAccess: '?.',
    customToolbar: () => {
      if (showSingleRider) {
        return <></>;
      }

      return (
        <>
          <Tooltip title={'Download CSV'}>
            <IconButton onClick={downloadCSV}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Reset Options to Default'}>
            <IconButton
              onClick={() => {
                upcomingRidesStorage.clearStorage();

                setColumns(prev => {
                  let changedColumns = cloneDeep(prev);

                  changedColumns = changedColumns.map((changeColumn, ind) => {
                    changeColumn.options = {
                      ...changeColumn.options,
                      display: defaultColumns[ind]?.options?.display ?? true,
                      filterList: [],
                    };

                    return changeColumn;
                  });
                  return changedColumns;
                });
                setFilters([]);
                setLimits({ ...defaultLimits });
                setSearch('');
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    onColumnSortChange: (
      changedColumn: string,
      sortDirection: MUISortOptions['direction'],
    ) => {
      setLimits(prev => ({
        ...prev,
        sort: { sortDirection, sortBy: changedColumn },
      }));

      saveToStorage({
        sortingValue: [changedColumn, sortDirection],
      });
    },
    onViewColumnsChange: (changedColumn: string, action: string) => {
      setColumns(prev => {
        let changedColumns = cloneDeep(prev);

        changedColumns.forEach(column => {
          if (column.name === changedColumn) {
            column.options = {
              ...column.options,
              display: action !== 'remove',
            };
          }
        });

        return changedColumns;
      });
      saveToStorage({
        columnView: { [changedColumn]: action as 'remove' | 'add' },
      });
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              handleFilterSubmit(applyNewFilters);
            }}
          >
            Apply Filters
          </Button>
        </div>
      );
    },
    onFilterChipClose: (index, removedFilter, filterList) => {
      const currentFilters = buildFilters(filterList);

      if (columns[index].name === 'rider') {
        setSearch('');
      }

      setColumns(prev => {
        let changedColumns = cloneDeep(prev);
        const filtersValue = currentFilters.reduce((acc, curr) => {
          acc[curr.field] = curr.value;
          return acc;
        }, {} as any);

        changedColumns = changedColumns.map((changeColumn, ind) => {
          changeColumn.options = {
            ...changeColumn.options,
            display: defaultColumns[ind]?.options?.display ?? true,
            filterList: filtersValue[changeColumn.name] ?? [],
          };

          return changeColumn;
        });

        return changedColumns;
      });

      setFilters(currentFilters);
      saveToStorage({
        filters: currentFilters,
      });
    },
    page: metaData.currentPage - 1,
    count: metaData.totalCount,
    rowsPerPage: metaData.limitValue,
    rowsPerPageOptions: [5, 10, 15, 25],
    jumpToPage: true,
    searchText: search,
    onSearchChange: text => {
      setSearch(text || '');
    },
    onSearchClose: () => setSearch(''),
    onChangePage: currentPage => {
      setLimits(prev => ({
        ...prev,
        page: currentPage + 1,
      }));
      saveToStorage({ page: currentPage + 1 });
    },
    onChangeRowsPerPage: (limit: number) => {
      setLimits(prev => ({
        ...prev,
        limit,
      }));
      saveToStorage({ limit });
    },
    filterType: 'dropdown',
    tableBodyHeight: '80%',
    selectableRows: 'none',
    expandableRowsHeader: false,
    onRowClick: (rowData, rowMeta) => {
      history.push(`/rides/${rides[rowMeta.rowIndex].id}`);
    },
    textLabels: {
      body: {
        noMatch:
          rides.length > 0
            ? 'There are no rides that match your filters.'
            : 'No upcoming rides',
      },
    },
  };

  if (showSingleRider && loadingRides) {
    return (
      <div className={classes.loading}>
        <CircularProgress size={60} />
      </div>
    );
  }

  return (
    <>
      {showSingleRider && rides.length === 0 ? (
        <div className={classes.noRecords}>
          <p>
            No upcoming rides.{' '}
            <Link to={`/rides/new?riderID=${riderId}`} component={RouterLink}>
              Book one today
            </Link>
            .
          </p>
        </div>
      ) : (
        <MUIDataTable
          title={'Upcoming Rides'}
          data={rides}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
};
