import { createBrowserHistory, createMemoryHistory } from 'history';

const isTest = process.env.NODE_ENV === 'test';

const history = isTest
  ? createMemoryHistory({ initialEntries: ['/'] })
  : createBrowserHistory();

// history.listen((location, action) => {
//   console.log(
//     `The current URL is ${location.pathname}${location.search}${location.hash}`,
//   );
//   console.log(
//     `The last navigation action was ${action}`,
//     JSON.stringify(history, null, 2),
//   );
// });

export default history;
