import { Box, Tooltip, Button, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

type Props = {
  labelText: React.ReactNode;
  tooltipText: React.ReactNode;
  style?: any;
  iconProps?: any;
  wrapText?: boolean;
  onHover?: boolean;
  icon?: React.ReactNode;
  labelStyle?: any;
};

export const LabelWithTooltip: React.FC<Props> = ({
  labelText,
  tooltipText,
  style = {},
  iconProps = {},
  wrapText = false,
  onHover = false,
  icon,
  labelStyle,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const Icon = icon ?? (
    <InfoIcon fontSize="small" color="primary" {...iconProps} />
  );

  return (
    <Box display="flex" alignItems="center" style={style}>
      <div style={{ marginRight: 3 }}>
        <Typography noWrap={wrapText} variant="body1" style={{...labelStyle}}>
          {labelText}
        </Typography>
      </div>
      {tooltipText && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            {...rest}
            title={tooltipText}
            style={{ marginLeft: '4px' }}
          >
            <Button
              style={{
                minWidth: 0,
                padding: 0,
              }}
              onMouseEnter={onHover ? handleTooltipOpen : () => {}}
              onClick={onHover ? () => {} : handleTooltipOpen}
            >
              {Icon}
            </Button>
          </Tooltip>
        </ClickAwayListener>
      )}
    </Box>
  );
};
