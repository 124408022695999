import {
  Backdrop,
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Button,
  Grid,
  TextField,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import MainLayout from '../components/layout/MainLayout';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import SectionHeader from '../components/SectionHeader';
import { Controller, useForm } from 'react-hook-form';
import {
  RidersSimilarQueryQueryVariables,
  useRidersSimilarQueryLazyQuery,
} from '../generated/graphql';
import { RiderElementComponent } from '../components/riders/RiderElementComponent';
import AvatarUpload from '../components/AvatarUpload';
import { useEffect, useRef } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { isValidDate } from 'helpers/helpers';
import { useRiderProfileNavigation } from '../hooks/useRiderProfileNavigation';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    height: 'fit-content',
  },
  section: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tabPanel: {
    padding: 0,
  },
  tabIndicator: {
    height: 4,
  },
  actionBar: {
    marginBottom: theme.spacing(0.5),
  },
  submit: {
    margin: theme.spacing(3, 1, 3, 1),
    width: '95%',
  },
  impairmentGridItem: {
    marginBottom: theme.spacing(2),
  },

  avatarUpload: {
    marginTop: theme.spacing(2),
    paddingBottom: 36,
  },
  header: {
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
}));

function generateQueryParams(
  rider: {
    firstName?: string;
    lastName?: string;
    dob?: string;
  },
  additionalParams?: string,
) {
  const { firstName, lastName, dob } = rider || {};
  const params = new URLSearchParams(additionalParams);

  params.set('firstName', firstName || '');
  params.set('lastName', lastName || '');

  if (dob) {
    params.set('dob', moment(dob).format('YYYY-MM-DD'));
  }

  return params.toString();
}

export type SelectRideForm = {
  firstName: string;
  lastName: string;
  dob?: string;
};

export const SelectRider = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    generateFromParams,
    generateRedirectUrl,
  } = useRiderProfileNavigation();
  const fromParams = generateFromParams();

  const {
    handleSubmit,
    errors,
    getValues,
    register,
    control,
    trigger,
  } = useForm<SelectRideForm>();

  const valuesRef = useRef<SelectRideForm | null>();

  const handleChange = () => {
    const values = getValues();
    if (values.lastName || values.firstName || values.dob) {
      valuesRef.current = values;
    }
  };

  const [getRiders, { data }] = useRidersSimilarQueryLazyQuery();

  const submit = async (data: RidersSimilarQueryQueryVariables) => {
    if (data.dob === '') {
      // We don't want to send the `dob` param to the API if it wasn't populated.
      delete data.dob;
    }
    await getRiders({ variables: data });
  };

  let content = null;

  useEffect(() => {
    if (data) {
      if (data.ridersSimilar?.length === 0) {
        const { firstName, lastName, dob } = valuesRef.current || {};
        const params = generateQueryParams(
          { firstName, lastName, dob },
          fromParams,
        );

        history.push(`/riders/new?${params}`);
      }
    }
  }, [data, getValues, history, fromParams]);

  if (data && data.ridersSimilar?.length) {
    content = (
      <>
        <header className={classes.header}>
          <Typography variant="h6" gutterBottom>
            Potential Matching Riders Found
          </Typography>
        </header>
        <Typography variant="body1">
          Choose a rider or Create a new one
        </Typography>
        <RiderElementComponent
          onSelectRider={rider => {
            const url = generateRedirectUrl(rider.id);

            if (url) {
              history.push(url);
            } else {
              history.push(`/riders/${rider.id}`);
            }
          }}
          riders={data.ridersSimilar}
        />
      </>
    );
  }

  const shouldShowSearchFields =
    (data && data.ridersSimilar && data.ridersSimilar?.length === 0) || !data;

  return (
    <MainLayout>
      <Container maxWidth="md" disableGutters={smallScreen}>
        <Backdrop className={classes.backdrop} open={false}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.actionBar}
        >
          <Button
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Box>
        <Paper className={classes.root}>
          <form noValidate onSubmit={handleSubmit(submit)}>
            <Box className={classes.avatarUpload}>
              <AvatarUpload register={register} showButton={false} />
            </Box>
            {shouldShowSearchFields ? (
              <>
                <SectionHeader title=" " />
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      error={!!errors.firstName}
                      helperText={errors.firstName && 'First Name is required'}
                      inputRef={register({
                        required: true,
                      })}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="lastName"
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      error={!!errors.lastName}
                      helperText={errors.lastName && 'Last Name is required'}
                      inputRef={register({
                        required: true,
                      })}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="dob"
                      rules={{
                        validate: {
                          validDate: value =>
                            !value ||
                            isValidDate(value, 'MM/DD/YYYY') ||
                            'Date must be valid',
                          notFutureDate: value =>
                            !value ||
                            moment(value).isSameOrBefore(moment()) ||
                            'Date must not be in the future',
                        },
                      }}
                      render={({ ref, value, onChange, ...rest }) => {
                        return (
                          <KeyboardDatePicker
                            format="MM/DD/YYYY"
                            fullWidth
                            id="dob"
                            inputRef={register()}
                            autoOk
                            label="DOB"
                            disableFuture
                            value={value ?? null}
                            error={!!errors.dob}
                            helperText={errors.dob && errors.dob.message}
                            InputProps={{
                              onBlur: () => {
                                trigger('dob');
                              },
                            }}
                            onChange={event => {
                              onChange(event);
                              handleChange();
                            }}
                            {...rest}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>

                <Button
                  key="updateButton"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Next
                </Button>
              </>
            ) : null}
            {content}
          </form>

          {data ? (
            <Button
              key="updateButton"
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                const { firstName, lastName, dob } = valuesRef.current || {};
                const params = generateQueryParams(
                  {
                    firstName,
                    lastName,
                    dob,
                  },
                  fromParams,
                );

                history.push(`/riders/new?${params}`);
              }}
            >
              Create New Rider
            </Button>
          ) : null}
        </Paper>
      </Container>
    </MainLayout>
  );
};
