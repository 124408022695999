import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

export default function PublicOnlyRoute({ children, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.isLoggedIn ? children : <Redirect to={{ pathname: '/' }} />
      }
    />
  );
}
