import { MUIDataTableColumn } from 'mui-datatables';
import { useMemo } from 'react';
import {
  FiltersInput,
  RidesCollectionScope,
  RidesPaginationQueryQuery,
} from '../generated/graphql';
import { formattedTimeInZone, fullDate } from '../lib/TimeUtils';
import moment from '@onwardcare/common/lib/moment-timezone';
import { personName } from '@onwardcare/common/lib/NameUtils';
import { centsToFormattedDollars } from '@onwardcare/common/lib/MoneyUtils';
import { useAuth } from '../contexts/auth-context';
import ENV from '../constants/Env';

const { apiUrl } = ENV;

const getStartTime = (
  ride: RidesPaginationQueryQuery['ridesCollection']['collection'][number],
) => {
  const startTime = ride.actualStartTime || ride.requestedStartTime;
  if (startTime) {
    return (
      fullDate(moment(startTime).tz(ride.tzName)) +
      formattedTimeInZone({
        time: startTime,
        tzName: ride.tzName,
      })
    );
  }

  return '';
};

export const durationString = (minutes: any) => {
  if (!minutes) return null;
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return hours > 0 ? `${hours} hr ${mins} min` : `${mins} min`;
};

export const duration = (
  ride: RidesPaginationQueryQuery['ridesCollection']['collection'][number],
) => {
  if (ride.actualStartTime && ride.actualEndTime) {
    const dur = Math.floor(
      moment
        .duration(moment(ride.actualEndTime).diff(moment(ride.actualStartTime)))
        .asMinutes(),
    );
    return dur;
  }
  return null;
};

export const useCSVData = (
  data: RidesPaginationQueryQuery | undefined,
  columns: MUIDataTableColumn[],
) => {
  return useMemo(() => {
    const headers: { label: string; key: string }[] = [];
    const result: Record<string, any>[] = [];

    if (data) {
      columns.forEach((column, ind) => {
        if (column.options?.display !== false) {
          headers.push({
            label: column.label || column.name,
            key: column.name,
          });
        }
      });

      data.ridesCollection.collection.forEach(ride => {
        let rideResult: Record<string, any> = {};
        headers.forEach(header => {
          if (header.key === 'requestedStartTime') {
            rideResult[header.key] = getStartTime(ride);
          }

          if (header.key === 'rider') {
            rideResult[header.key] = personName(ride.rider);
          }

          if (header.key === 'grouped_status') {
            const resultStatus = `Status: ${ride.status}\n${
              (ride.driver
                ? `Driver: ${ride.driver.firstName} ${ride.driver.lastName}\n`
                : '') +
              (ride.driver?.transportPartner
                ? `Transport Partner: ${ride.driver?.transportPartner.name}\n`
                : '')
            }`;
            rideResult[header.key] = resultStatus;
          }

          if (header.key === 'custodian') {
            if (ride.custodian) {
              rideResult[header.key] = personName(ride.custodian);
            }
          }

          if (header.key === 'rideType') {
            if (ride.rideType) {
              rideResult[header.key] = ride.rideType;
            }
          }

          if (header.key === 'transportType') {
            if (ride.transportType) {
              rideResult[header.key] = ride.transportType;
            }
          }

          if (header.key === 'grouped_type') {
            let typeResult = `${
              ride.rideType ? `Ride Type: ${ride.rideType}\n` : ''
            }${
              ride.transportType ? `Transport Type: ${ride.transportType}` : ''
            }`;

            if (typeResult.trim()) {
              rideResult[header.key] = typeResult;
            }
          }

          if (header.key === 'grouped_details') {
            const price = ride?.estimatedCostCents || ride?.actualCostCents;
            const distance =
              ride?.actualDistanceMiles || ride?.estimatedDistanceMiles;

            const dur = duration(ride);
            const durStr = dur ? durationString(dur) : null;

            const resultString = `Start: ${ride.startLocation.address}\nEnd: ${
              ride.endLocation?.address || ''
            }\nRide ID: ${ride.id}\nCost: ${centsToFormattedDollars(
              price,
            )}\nDistance: ${distance} miles ${
              durStr ? `Duration: ${durStr}` : ''
            }`;
            rideResult[header.key] = resultString;
          }
        });
        result.push(rideResult);
      });
    }

    return { result, headers };
  }, [data, columns]);
};

export const useDownloadCsv = (
  scope: RidesCollectionScope = RidesCollectionScope.Upcoming,
  filters?: Array<FiltersInput>,
  search?: string,
) => {
  const auth = useAuth();
  const headers = auth.getAuthRequestHeaders();

  return () =>
    fetch(`${apiUrl}/rides/export/${scope.toLowerCase()}`, {
      method: 'post',
      headers,
      body: JSON.stringify({
        filters: filters,
        search: search,
      }),
    })
      .then(r => r.text())
      .then(d => {
        const blob = new Blob([d], { type: 'text/csv' });
        const a = document.createElement('a');
        a.download = scope;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
      })
      .catch(e => console.log('eee', e));
};
