import { useChannel, useEvent } from '@harelpls/use-pusher';
import { useApolloClient } from '@apollo/client';
import { useToast } from 'components/layout/Toast';
import { loader } from 'graphql.macro';
import { personName } from '@onwardcare/common/lib/NameUtils';

const DASHBOARD_QUERY = loader('../../data/queries/DashboardQuery.graphql');

const RealtimeHandler = ({ account }) => {
  const apolloClient = useApolloClient();
  const toast = useToast();

  const channel = useChannel('account_' + account.id);
  useEvent(channel, 'rideCompleted', data => handleRideCompleted(data.id));
  useEvent(channel, 'rideNoShow', data => handleRideNoShow(data.id));
  useEvent(channel, 'rideStarted', data => handleRideStarted(data.id));

  const handleRideCompleted = rideId => {
    const data = apolloClient.readQuery({ query: DASHBOARD_QUERY });

    const ride = data.upcomingRides.find(
      r => r.id.toString() === rideId.toString(),
    );
    if (ride) {
      const rideType = ride.rideType === 'delivery' ? 'delivery' : 'ride';
      const message = `${personName(ride.rider)}'s ${rideType} just completed.`;

      const rideIndex = data.upcomingRides.findIndex(
        r => r.id.toString() === rideId.toString(),
      );
      const updatedRides = [
        ...data.upcomingRides.slice(0, rideIndex),
        ...data.upcomingRides.slice(rideIndex + 1),
      ];

      apolloClient.writeQuery({
        query: DASHBOARD_QUERY,
        data: {
          upcomingRides: updatedRides,
        },
      });
      toast.showMessage(message);
    }
  };

  const handleRideNoShow = rideId => {
    const data = apolloClient.readQuery({ query: DASHBOARD_QUERY });

    const ride = data.upcomingRides.find(
      r => r.id.toString() === rideId.toString(),
    );
    if (ride) {
      const rideType = ride.rideType === 'delivery' ? 'delivery' : 'ride';
      const message = `${personName(
        ride.rider,
      )}'s ${rideType} was marked as a no show.`;

      const rideIndex = data.upcomingRides.findIndex(
        r => r.id.toString() === rideId.toString(),
      );
      const updatedRides = [
        ...data.upcomingRides.slice(0, rideIndex),
        ...data.upcomingRides.slice(rideIndex + 1),
      ];

      apolloClient.writeQuery({
        query: DASHBOARD_QUERY,
        data: {
          upcomingRides: updatedRides,
        },
      });
      toast.showMessage(message);
    }
  };

  const handleRideStarted = rideId => {
    const data = apolloClient.readQuery({ query: DASHBOARD_QUERY });

    const rideIndex = data.upcomingRides.findIndex(
      r => r.id.toString() === rideId.toString(),
    );
    if (rideIndex >= 0) {
      const ride = data.upcomingRides[rideIndex];
      const rideType = ride.rideType === 'delivery' ? 'delivery' : 'ride';
      const message = `${personName(ride.rider)}'s ${rideType} just started.`;

      const updatedRides = data.upcomingRides.map((item, index) => {
        if (index !== rideIndex) return item;

        return { ...item, status: 'inprogress' };
      });

      apolloClient.writeQuery({
        query: DASHBOARD_QUERY,
        data: {
          upcomingRides: updatedRides,
        },
      });

      toast.showMessage(message);
    }
  };

  return null;
};

export default RealtimeHandler;
