import moment from '@onwardcare/common/lib/moment-timezone';
import { Moment } from 'moment';

export const HOUR_FORMAT_24 = 'HH:mm';
export const HOUR_FORMAT_12 = 'hh:mm A';
export const MONTH_DAY_YEAR_FORMAT = 'MM/DD/YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_FORMAT_12 = 'MMM Do hh:mm A';

export const PICKUP_TIME_ADD = 'ADD';
export const PICKUP_TIME_RMV = 'RMV';

export function formattedDate(
  time: string,
  tzName: string,
  size: 'small' | 'large' = 'large',
): string {
  const format = size === 'large' ? 'dddd, MMMM Do' : 'ddd, MMM Do';
  return moment(time).tz(tzName).format(format);
}

export function formattedTimeInZone({
  time,
  tzName,
}: {
  time: string;
  tzName: string;
}): string {
  return moment(time).tz(tzName).format('h:mm a z');
}

export function formattedDateTimeInZone({
  time,
  tzName,
}: {
  time: string;
  tzName: string;
}): string {
  return moment(time).tz(tzName).format('MMMM Do h:mm a');
}

export function formattedTimeSpanInZone({
  startTime,
  endTime,
  tzName,
}: {
  startTime: string;
  endTime?: string;
  tzName: string;
}): string {
  const startFormat = endTime ? 'h:mm a' : 'h:mm a z';
  let result = moment(startTime).tz(tzName).format(startFormat);

  if (endTime) {
    result += ` - ${moment(endTime).tz(tzName).format('h:mm a z')}`;
  }

  return result;
}

export function fullDateAndTime(time: string): string {
  return moment.utc(time).format('MMM D, YYYY h:mm a z');
}

export function fullDate(time: string): string {
  return moment(time).format('MMM D, YYYY');
}

export function fullNumericDate(time: string): string {
  return moment(time).format(MONTH_DAY_YEAR_FORMAT);
}

/**
 * Takes in a time string and returns an array with the hours and minutes as strings.
 *
 * @param time Time as a string in a 24-hour format (example: 13:30)
 * @returns Returns an array with the hours and minutes as strings.
 */
function timeParts(time: string): [string, string] {
  const parts = time.split(':');
  return [parts[0], parts[1]];
}

/**
 * Takes in a time string and returns a moment object with the time set to the provided time.
 *
 * @param time Time as a string in a 24-hour format (example: 13:30)
 * @param momentTime (Optional) The moment object to set the time on. If not provided, a new moment object will be created.
 * @returns Returns a moment object with the time set to the provided time.
 */
export function momentFromTimeString(
  time: string,
  momentTime?: Moment,
): Moment {
  const [hours, minutes] = timeParts(time);
  const _momentTime = momentTime ?? moment().tz('America/Los_Angeles');
  _momentTime.set({ hour: hours, minute: minutes });

  return _momentTime;
}

/**
 *  Formats the date of birth string to an ISO string.
 *
 * @param dob The date of birth string to format.
 * @param format The format the date of birth is in.
 * @returns Returns the date of birth string formatted as an ISO string. If the date was invalid, null will be returned.
 */
export function formatDobStringToISO(
  dob: string,
  format = MONTH_DAY_YEAR_FORMAT,
) {
  // We need to use `moment.utc` here to ensure the date is the same for all
  // timezones. Otherwise, the date will be off by a day for some timezones.
  const date = moment.utc(dob, format);

  if (!date.isValid()) {
    return null;
  }

  return date.format(ISO_DATE_FORMAT);
}

/**
 * Add minutes into a date
 * @param date Date Object that will be modified
 * @param minutes Minutes that will be added
 * */
const addMinutes = (date: Date, minutes: number) => {
  return moment(date).add(minutes, 'minutes').toDate();
};

/**
 * Remove minutes into a date
 * @param date Date Object that will be modified
 * @param minutes Minutes that will be removed
 * */
const subtractMinutes = (date: Date, minutes: number) => {
  return moment(date).subtract(minutes, 'minutes').toDate();
};

/**
 * Formats the given duration in minutes.
 * @param mins - The duration in minutes.
 * @returns The formatted duration string.
 */
export const formatDuration = (mins: number) => {
  const duration = moment.duration(mins, 'minutes');
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (hours === 0 && minutes === 0) {
    return '0 minutes';
  }

  const formattedHours =
    hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''} ` : '';
  const formattedMinutes =
    minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';

  return `${formattedHours}${formattedMinutes}`;
};

export type CalculatePickupTimeProps = {
  date: Date;
  time: Date;
  bufferMinutes: number;
  rideEstimate: number;
  operation: 'ADD' | 'RMV';
};

/**
 * Calculates the Pickup Time based on the provided parameters.
 *
 * This is used twice, once while opening the Time Helper modal, so the operation will be ADD, then once the dialog's data changes, the operation will be RMV.
 * @param {CalculatePickupTimeProps} props - The parameters for calculating the Pickup Time.
 * @param {Date} props.date - The date for calculation.
 * @param {Date} props.time - The time for calculation.
 * @param {number} props.bufferMinutes - The buffer time in minutes.
 * @param {number} props.rideEstimate - The estimated ride time in minutes.
 * @param {'ADD' | 'RMV'} props.operation - The operation to perform ('ADD' to add time, 'RMV' to remove time).
 * @returns {{ clean: Date, formatted: string }} - An object containing the clean pickup time as a Date object and the formatted pickup time as a string.
 */
export const calculatePickupTime = ({
  date,
  time,
  bufferMinutes,
  rideEstimate,
  operation,
}: CalculatePickupTimeProps) => {
  const arrDate = moment(date);
  const arrTime = moment(time);
  if (date !== null && time !== null) {
    arrDate.hour(arrTime.hour());
    arrDate.minute(arrTime.minute());
  }

  let updatedDateTime = new Date();
  if (arrDate) {
    if (operation === PICKUP_TIME_RMV) {
      updatedDateTime = subtractMinutes(
        arrDate.toDate(),
        parseInt(`${bufferMinutes}`) + parseInt(`${rideEstimate}`),
      );
    } else {
      if (operation === PICKUP_TIME_ADD) {
        updatedDateTime = addMinutes(
          arrDate.toDate(),
          parseInt(`${bufferMinutes}`) + parseInt(`${rideEstimate}`),
        );
      }
    }
  }

  return {
    clean: updatedDateTime,
    formatted: moment(updatedDateTime).format(FULL_DATE_FORMAT_12),
  };
};
