import { Avatar, Box, Button, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ENV from 'constants/Env';
import { useTracking } from 'lib/analytics/Tracker';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    width: theme.spacing(24),
    height: theme.spacing(24),
    margin: '0 auto 16px',
    border: `1px solid ${grey[500]}`,
    boxShadow: `0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]}`,
  },
  centered: {
    textAlign: 'center',
  },
}));

const AvatarUpload = ({ person = undefined, register, showButton = true }) => {
  const classes = useStyles();
  const tracker = useTracking();

  const [image, _setImage] = useState(
    person?.profilePhoto ? ENV.apiUrl + person.profilePhoto : null,
  );

  const setImage = newImage => {
    tracker.track('Photo Uploaded');

    _setImage(newImage);
  };

  const handleOnChange = event => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      setImage(URL.createObjectURL(newImage));
    }
  };

  const imageSrc = image?.length ? image : '/defaultProfile.svg';

  return (
    <Box className={classes.centered}>
      <Avatar className={classes.avatar} alt="Avatar" src={imageSrc} />
      <input
        ref={register}
        name="profilePhoto"
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={handleOnChange}
      />
      {showButton ? (
        <label htmlFor="avatar-image-upload">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            mb={2}
            startIcon={<UploadIcon mr={2} />}
          >
            Upload Image
          </Button>
        </label>
      ) : null}
    </Box>
  );
};

export default AvatarUpload;
